
let Environment = {
    tpl: "0x321cE961084Fcf3A56De4Be2F2006707A0421aa4",
    usdt:'0x08394e7e653472694ECd4527656B2881e5701A14', 
    USDC:'0x63355a2ff725B11B6d82071c9FD710C0DCc71900', 
    SHIB:'0x1cD40deb4196D219097499031922Ff690F9ea813', 
    LINK:'0x5b41A5c0Df16551f5edeAa2B2eDe2135F1a824DF', 
    UNI:'0xD7b45CbC28BA9ba8653665d5FB37167a2Afe35D9', 
    APE:'0x01fa8dEEdDEA8E4e465f158d93e162438d61c9eB', 
    PEPE:'0x11dC5C441971BB3b2e933e0256E8a6bC6c41a91a', 
    CHZ:'0xbe1d0dB61E7562d88eF1FAb7436d02b6d00ce728', 
    COMP:'0xd75edf81CD2109D4264624dBf34Bd4Dee36f18B9', 
    TOMI:'0xC870a3dc444bF970Da13979E9CFAc1a01c198eac',
    Dop: '0x4873528341D33Ec918c7465F244491aCB75Bc95F',
    router:'0xC532a74256D3Db42D0Bf7a0400fEFDbad7694008',
    mintAll:'0x837ad475f0177BcB6cF426A725d5d52Dfb577eE7'
};
export default Environment;

