import '../../App.scss';
import React, { useEffect, useState } from "react";
import Banner from './main-banner/Banner';
import Navbar from './header/navbar1';
import {Route, Link, Routes, useParams} from 'react-router-dom'; 
import Navbar1 from './header/navbar1';

function Landing() {
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])
  return (
    <>
      <Navbar1/>
      <Banner />
    </>
  );
}

export default Landing;