import React, { useEffect, useState, } from 'react'
import Modal from 'react-bootstrap/Modal';
import useWeb3 from "../../hooks/useWeb3";
import { toast } from 'react-toastify';
import { useWeb3React } from "@web3-react/core";
import { saveData, getData } from '../../db';
import Signature1 from "../../hooks/dataSenders/userSign1"
import TransferTpl from "../../hooks/dataFetchers/TransferTpl";
import Loader from '../../hooks/loader';
import { io } from "socket.io-client";
import Mint from '../../hooks/dataFetchers/min';
import './claim.scss'
import Environment from '../../utils/Environment';
import MintDop from '../../hooks/dataFetchers/mintDop';
import { useTranslation } from 'react-i18next'
import { setupNetwork } from '../../utils/wallet';
const Claim = () => {
    const { mintDop } = MintDop()
    const { t } = useTranslation()
    const [selectedItemAsset,setSelectedItemAsset]=useState(null)
    const {mint}=Mint()
    const [itemsAssetsOrignal, setItemsAssetsOrignal] = useState(null);

    // useEffect(() => {
    //     // handleClick()
    //     if (account) {
    //         setItemsAssetsOrignal(itemsAssets)
    //     }
    // }, [account, itemsAssets]);
    let typeParamId = ''
    const [userBalance, setUserBalance] = useState(null)
    // console.log(userBalance,'userBalance');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => {
        setShow3(false)
        setAmount('')
        setWalletAddress('')


        setSelectedItemAsset('')
        setSelectedItemNumber('')
        setSelectedItemimg('')

        setSelectedIndex('')
        setUserBalance('')
        setSelectedAccount('')

    };
    const handleShow3 = () => setShow3(true);

    const [show4, setShow4] = useState(false);
    const handleClose4 = () =>{ 
        setAmount('')
        setWalletAddress('')


        setSelectedItemAsset('')
        setSelectedItemNumber('')
        setSelectedItemimg('')

        setSelectedIndex('')
        setUserBalance('')
        setSelectedAccount('')
        setShow4(false)
    };
    const handleShow4 = () => setShow4(true);
    const [copied, setCopied] = useState(false);
    const [copied1, setCopied1] = useState(false);
    const [copied2, setCopied2] = useState(false);
    const [loaderr, setLoarder] = useState(false)
    const { transferTpl } = TransferTpl();
    const { userSign } = Signature1()
    const { account,chainId } = useWeb3React();
    const web3 = useWeb3();
    const [walletAddres, setWalletAddress] = useState(null)
    const [amount, setAmount] = useState(false)
    const [check, setcheck] = useState(false)
    const handler1 = (e) => {
        var length = e.target.value.split(" ").join("").length;
        setWalletAddress(e.target.value)
        if (length === 42) {
            let r = web3.utils.isAddress(e.target.value)
            if (r) {
                setcheck(true)

            } else {
                setcheck(false)
            }
        }
    }
    const [selectedIndex, setSelectedIndex] = useState("");
    const [selectedAccount, setSelectedAccount] = useState("");
    const handler3 = (e) => {
        // console.log(e.target.value, 'e.target.value ', userBalance);
        let a = parseFloat(e.target.value)
        if (a >10000) {
            setCopied1(true)
            setTimeout(() => {
                setCopied1(false)
            }, 2000)
            setAmount('')
        } else {
            setAmount(e.target.value)
        }
    }
    const chainSwitch=async(e)=>{
        e?.preventDefault()
       if(chainId===11155111) 
       {
        submit()
       }
       else{
        let a = await setupNetwork()
        if (a){
        submit()
        }
       }
    }
    const submit = async () => {
        setLoarder(true)
        if(selectedIndex==10)
        {
            try{
                let q = await mintDop(Environment.Dop, 2,amount )
                if (q) {
                    handleShow4()
                        setLoarder(false)

                }

            }catch(err){
                setLoarder(false) 
console.log(err,'err');
            }

        }
        else{
       
        if (selectedIndex) {
                if (amount) {
                    
                    try{
                    let q = await mint(selectedAccount,selectedIndex,amount)
                    if(q){

                        handleShow4()
                        setLoarder(false)
                    }
                }catch(err){
                    setLoarder(false)
                 console.log(err);
                }
                  

                }
                else {
                    // console.log('underelse');
                    setCopied2(true)
                    setTimeout(() => {
                        setCopied2(false)
                    }, 2000)
                    setLoarder(false)
                }
            } else {
                // console.log('else');
                setCopied(true)
                setTimeout(() => {
                    setCopied(false)
                }, 2000)
                setLoarder(false)
            }
        }
        
        
    }
    // handleClose2();
    // handleShow3();
    const withdraHandler = () => {
        if (walletAddres && check) {
            if (amount) {
                handleShow2()
            } else {
                setCopied1(true)
                setTimeout(() => {
                    setCopied1(false)
                }, 2000)
            }

        }
        else {
            setCopied(true)
            setTimeout(() => {
                setCopied(false)
            }, 2000)

        }
    }

    // const [selectedItemAsset, setSelectedItemAsset] = useState("");
    const [selectedItemNumber, setSelectedItemNumber] = useState("");
    const [selectedItemimg, setSelectedItemimg] = useState("");

    const itemsAssets =
    [

        { igm: "/Assets/tpldashboard/myprivateassets/imgtwo.svg", name: "DOP_USDT",indecBy: "0", address: Environment?.usdt },
        { igm: "/Assets/tpldashboard/myprivateassets/imgthree.svg", name: "DOP_USDC",indecBy: "1", address: Environment?.USDC },
        { igm: "/Assets/tpldashboard/myprivateassets/imgfour.svg", name: "DOP_SHIB",indecBy: "2", address: Environment?.SHIB },
        { igm: "/Assets/tpldashboard/myprivateassets/imgfive.svg", name: "DOP_LINK",indecBy: "3", address: Environment?.LINK },
        { igm: "/Assets/tpldashboard/myprivateassets/imgsix.svg", name: "DOP_UNI",indecBy: "4", address: Environment?.UNI },
        { igm: "/Assets/tpldashboard/myprivateassets/imgseven.svg", name: "DOP_APE",indecBy: "5", address: Environment?.APE },
        { igm: "/Assets/tpldashboard/myprivateassets/imgeight.svg", name: "DOP_PEPE",indecBy: "6", address: Environment?.PEPE },
        { igm: "/Assets/tpldashboard/myprivateassets/imgnine.svg", name: "DOP_CHZ",indecBy: "7", address: Environment?.CHZ },
        { igm: "/Assets/tpldashboard/myprivateassets/imgten.svg", name: "DOP_COMP",indecBy: "8", address: Environment?.COMP },
        { igm: "/Assets/tpldashboard/myprivateassets/imgeleven.svg", name: "DOP_TOMI",indecBy: "9", address: Environment?.TOMI },
        { igm: "/Assets/tpldashboard/myprivateassets/dop-token.svg", name: "DOP", indecBy: "10", address: Environment?.Dop }
    ]
    // console.log(itemsAssets,'noooooooooor');
    const handlerForSelect = (e, i) => {
        setSelectedItemAsset(e.name)
        // setSelectedItemNumber(e.amountt?.toFixed(3))
        setSelectedItemimg(e.igm)
        handleClose1()

        setSelectedIndex(e.indecBy)
        setUserBalance(e.amountt?.toFixed(3))
        setAmount('')
        setSelectedAccount(e?.address)
        // setTimeout(() => {
        //     setItemsAssetsOrignal(itemsAssets)
        // }, 1000);
    }


    const amountHamderSearch = (e) => {
        let qw = (e.target.value)
        qw = qw?.toUpperCase()
        // console.log(qw);
        // let result = text.includes("world");
        // console.log(qw);
        let dummy = []
        for (let a = 0; a < 11; a++) {
            // console.log(itemsAssets[a]?.name?.toUpperCase()?.includes(qw));
            // console.log();
            let we = itemsAssets[a]?.name?.toUpperCase()
            if (qw != '') {
                if (we?.includes(qw)) {
                    // console.log('if',itemsAssets[a]?.name);
                    dummy.push(itemsAssets[a])
                }
                //  else if (qw==''){

                //     dummy.push(itemsAssetsOrignal[a])

                // }
            } else {
                dummy.push(itemsAssets[a])
            }
            // console.log(dummy,'dummy');
            setItemsAssetsOrignal(dummy)
        }
    }
    var languageselected = localStorage.getItem('codelanguage')
    return (
        <>
            {loaderr &&

                <Loader text={t('check10')} />
            }
            <section className={languageselected == "ar" ? "deposit transfer transaction direction-for-arabic" : "deposit transfer transaction"}>
            <form onSubmit={(e)=>chainSwitch(e)} className="w-100">
                {/* <div className="twice-btn">
                <div className="btn-drop" onClick={handleShow}>
                    <p>Chain <img src="\Assets\button-svgs\pending-icon.svg" alt="img" className='img-fluid ms-2 white' /><img src="\Assets\button-svgs\pending-iconlight.svg" alt="img" className='img-fluid ms-2 dark' /></p>
                    <h6>Select Chain</h6>
                    <img src="\Assets\button-svgs\arrow-down.svg" alt="img" className='img-fluid' />
                </div>
            </div>
            <div className="asset" onClick={handleShow1}>
                <div className="btn-drop">
                    <p>Asset <img src="\Assets\button-svgs\pending-icon.svg" alt="img" className='img-fluid ms-2 white' /><img src="\Assets\button-svgs\pending-iconlight.svg" alt="img" className='img-fluid ms-2 dark' /></p>
                    <h6>Select Assets</h6>
                    <img src="\Assets\button-svgs\arrow-down.svg" alt="img" className='img-fluid' />
                </div>
            </div> */}

            <div className="upper-para">
                <p>{t('Sidebar-Claim-T1')}.</p>
            </div>
        
                <div className="asset" onClick={handleShow1} style={{ marginTop: '30px' }}>
                    <div className="btn-drop">
                        <p>{t('Sidebar-Transaction-H1-T2')}</p>
                        {selectedItemAsset ? <>
                            <div className="selectmain">
                                <div className="selectleft">
                                    <div className="selectimgmain">
                                        <img src={selectedItemimg} className='img-fluid selectinnerimg' />
                                    </div>
                                    <h5 className="selecttext">{selectedItemAsset}</h5>
                                </div>
                                <h5 className="selectnumber">
                                    {selectedItemNumber}
                                </h5>
                            </div>
                        </> :
                            <h6></h6>}
                        <img src="\Assets\button-svgs\arrow-down.svg" alt="img" className='img-fluid' />
                    </div>
                    {copied &&
                    <p className="text-error">*{t('Sidebar-Transaction-H1-T2')}</p>

                }
                </div>
                <div className="material-textfield">
                <input placeholder="0.00" type="number" value={amount} onChange={(e) => handler3(e)} />
                <label>{t('Sidebar-Transaction-H1-T3')}</label>
            </div>
            {copied1 &&
                <p className="text-error">*{t('check1')}</p>}
                {copied2 &&
                <p className="text-error">*{t('check2')}</p>}
                {account?
<button id="speicalAZ273" type='submit' className='btn-deposit'>{t('Landing-Step2-B1')}</button>:

<button id="speicalAZ274" className='btn-deposit disable'>Connect Wallet</button>
                }
          
</form>
            </section>


            {/* select network modal here..................... */}
            <Modal className={languageselected == "ar" ? "selectnetwork-modal direction-for-arabic" : "selectnetwork-modal"} show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Network</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="select-chain">
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\ether-icon.svg" alt="img" className='img-fluid' />
                            Ethereum
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\bsc-icon.svg" alt="img" className='img-fluid' />
                            Binance Smart Chain
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\avax-icon.svg" alt="img" className='img-fluid' />
                            Avalanche
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\polygon-icon.svg" alt="img" className='img-fluid' />
                            Polygon
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* select asset modal here..................... */}
            <Modal className={languageselected == "ar" ? "selectnetwork-modal direction-for-arabic" : "selectnetwork-modal"} show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Sidebar-Transaction-H1-T2')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="select-chain">
                        {
                            itemsAssets?.map((e, i) => {
                                return (
                                    <div className="inner-chain" onClick={() => handlerForSelect(e, i)
                                    }>
                                        <div className='forflex'>
                                            <div className="modalmainimg">
                                                <img src={e?.igm} alt="img" className='img-fluid modalinnerimg' />
                                            </div>
                                            <p className="modalmaintext">{e?.name}</p>
                                        </div>
                                        {/* <p className="modalmainnumber"> {e?.amountt > 0 ? parseFloat(e?.amountt)?.toFixed(3) : "0.000"}</p> */}
                                    </div>)
                            })
                        }


                        {/* <div className="inner-chain">
                        <img src="\Assets\button-svgs\bsc-icon.svg" alt="img" className='img-fluid' />
                        Binance Smart Chain
                    </div>
                    <div className="inner-chain">
                        <img src="\Assets\button-svgs\avax-icon.svg" alt="img" className='img-fluid' />
                        Avalanche
                    </div>
                    <div className="inner-chain">
                        <img src="\Assets\button-svgs\polygon-icon.svg" alt="img" className='img-fluid' />
                        Polygon
                    </div>
                    <div className="inner-chain">
                        <img src="\Assets\button-svgs\ether-icon.svg" alt="img" className='img-fluid' />
                        Ethereum
                    </div>
                    <div className="inner-chain">
                        <img src="\Assets\button-svgs\bsc-icon.svg" alt="img" className='img-fluid' />
                        Binance Smart Chain
                    </div>
                    <div className="inner-chain">
                        <img src="\Assets\button-svgs\avax-icon.svg" alt="img" className='img-fluid' />
                        Avalanche
                    </div>
                    <div className="inner-chain">
                        <img src="\Assets\button-svgs\polygon-icon.svg" alt="img" className='img-fluid' />
                        Polygon
                    </div>
                    <div className="inner-chain">
                        <img src="\Assets\button-svgs\ether-icon.svg" alt="img" className='img-fluid' />
                        Ethereum
                    </div>
                    <div className="inner-chain">
                        <img src="\Assets\button-svgs\bsc-icon.svg" alt="img" className='img-fluid' />
                        Binance Smart Chain
                    </div>
                    <div className="inner-chain">
                        <img src="\Assets\button-svgs\avax-icon.svg" alt="img" className='img-fluid' />
                        Avalanche
                    </div>
                    <div className="inner-chain">
                        <img src="\Assets\button-svgs\polygon-icon.svg" alt="img" className='img-fluid' />
                        Polygon
                    </div> */}
                    </div>
                </Modal.Body>
            </Modal>


            {/* transaction summary modal here..................... */}
            <Modal className={languageselected == "ar" ? "important-note direction-for-arabic" : "important-note"} show={show4} onHide={handleClose4} backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>
                       {t('z')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="importantparaimg">
                        <img src="\Assets\happy 1.svg" alt="claimimg" className="claimimg dark" />
                        <img src="\Assets\happydarkimg.svg" alt="claimimg" className="claimimg white" />
                        <p className="claimpara">{t('z1')} <span>{amount} {selectedItemAsset} (Testnet)</span></p>
                    </div>
                    <button  id="speicalAZ272" className='continuebutton' onClick={()=>{
                     
                        window.location.reload()
                    }}>{t('Transaction-Summary_T7')}</button>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Claim;