import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { usdtContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const BalacefAccount = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.tpl;
  const tokenAddress1 = Environment.busd;
// console.log(web3,'web32323');


  const balacefAccount = useCallback(
    async (addresss) => {
      const contract = usdtContract(addresss, web3);
        // console.log(tokenAddress,account,'noor');
      try {
        const buy = await contract.methods.balanceOf(account).call({from:account});
        // console.log('sdsdss' ,  buy)
        return buy;
      } catch (error) {
        throw error;
      }
    },
    [web3,account]
  );
  return { balacefAccount:balacefAccount };
};
export default BalacefAccount;