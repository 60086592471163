import React, { useEffect, useState } from "react";
import "./banner.scss";
import { Link } from "react-router-dom";
import { saveData, getData } from '../../../db';
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';
const Banner = () => {
  const localEmail=localStorage.getItem('email')
  const { t } = useTranslation()
  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [inputFeild6, setInputFeild6] = useState(null);
  const [checkedNew1, setCheckedNew1] = useState(false)
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const inputField1Handler5 = (e) => {
    let value = e.target.value;
    if (!isValidEmail(value)) {
      setInputFeild6(value);
      setCheckedNew1(false)
    } else {
      setInputFeild6(value);
      setCheckedNew1(true)
    }

  };
  // const { executeRecaptcha } = useReCaptcha();

  // const handleReCaptchaVerify = (async () => {
  //   if (!executeRecaptcha) {
  //     console.log('Execute recaptcha not yet available');
  //     return;

  //   }
  //     const token = await executeRecaptcha('yourAction');
  //     // Do whatever you want with the token
  //   }, [executeRecaptcha]);
  const params = useParams();
  console.log();
  // console.log(params.id,'params');
  const history = useHistory()
  const [password, setPasword] = useState(null)
  // const [data, setData] = useState('');
  // const handleClick = async () => {
  //   await saveData('key', 'value');
  //   const result = await getData('key');
  //   setData(result);
  // };
  useEffect(() => {

    //to close

    // if (params.id) {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('id');
      // console.log('iidddddddd',id,params.id);
    if(id) {
      localStorage.setItem('routeId',id)
    } else if (params.id) {
      localStorage.setItem('routeId',params.id)
    }
    // }

    //to open
     handleClick()

  }, []);
  const handleClick = async () => {
    // await saveData('tpl_password',pasword);
    const result = await getData('tpl_password');
    const result1 = await getData('selected_privateKey');
    // const mail = await localStorage.getItem('mail')
    // console.log(mail,'mail');
    // if (!mail) {
      // history.push('/login')
    // }
    if (result && result1) {
      history.push('/unlock')
    }
     else if(!localEmail){
        history.push('/welcome')

      }
    
    // setData(result);
  };

  const [showdisclaimer, setShowdisclaimer] = useState(false);
  const handleClosedisclaimer = () => setShowdisclaimer(false);
  const handleShowdisclaimer = () => setShowdisclaimer(true);

  const [showdisclaimer1, setShowdisclaimer1] = useState(false);
  const handleClosedisclaimer1 = () => setShowdisclaimer1(false);
  const handleShowdisclaimer1 = () => setShowdisclaimer1(true);

  const [changecolor, setchangecolor] = useState(false);
  let localll = localStorage.getItem('modal')

  //to close
  // useEffect(() => {
  //   if (!localll) {
  //     handleShowdisclaimer();
  //     handleShowdisclaimer1();
  //   }
  // }, []);
  const modallhandler = async (e) => {
    e?.preventDefault()
    // const token = await executeRecaptcha('your_action_name'); // Replace with your specific action name
    // console.log('reCAPTCHA token:', token);
    if (cheked1&&checkedNew1) {
      localStorage.setItem('email', inputFeild6)
      localStorage.setItem('modal', true)
      handleClosedisclaimer1()
    }
  }

  const [cheked1, setCheked1] = useState(false)
  const [cheked2, setCheked2] = useState(false)
  const inputField1Handler2 = (e) => {
    if (e.target.checked == true) {
      setCheked1(true)
    } else {
      setCheked1(false)
    }
  }


  var languageselected = localStorage.getItem('codelanguage')

  return (
    <>
      <section className={languageselected == "ar" ? "main-banner direction-for-arabic" : "main-banner"}>
        <div className="theme-container">
          <div className="bannerheadings">
            <h4 className="bannerhead">{t('route-landing-MainHeading')}</h4>
            <p className="bannerpara">{t('route-landing-MainT1')}.</p>
          </div>
          <div className="bannerwallet">
            <div className="mainwallet">
              <img src="\Assets\banner-assets\plusdark.svg" alt="img" className="img-fluid dark" />
              <img src="\Assets\banner-assets\pluswhite.svg" alt="img" className="img-fluid white" />
              <h5 className="mainhead">{t('route-landing-Box1-T1')}!</h5>
              <p className="mainpara">{t('route-landing-Box1-T2')}.</p>
              <Link  id="speicalAZ215"  to="/create" className="w-100">

                <button className="common-btnone">{t('route-landing-Box1-B1')}</button>
              </Link>
            </div>
            <div className="mainwallet">
              <img src="\Assets\banner-assets\pluslight.svg" alt="img" className="img-fluid dark" />
              <img src="\Assets\banner-assets\arrowwhite.svg" alt="img" className="img-fluid white" />
              <h5 className="mainhead">{t('route-landing-Box2-T1')}</h5>
              <p className="mainpara">{t('route-landing-Box2-T2')}.</p>
              <Link  id="speicalAZ216"  to="/import" className="w-100">
                <button className="secondary-btnone">{t('route-landing-Box2-B1')}</button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* <Modal className="modal-showstart" show={showdisclaimer} onHide={handleClosedisclaimer} centered  backdrop="static"
        keyboard={false}>
        <Modal.Header>
          <Modal.Title>
            <img src="\Assets\disclaimer.svg" alt="disclaimerimg" className="disclaimerimg" />
            Important Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mainmodalstartpara">
            <p className="mainmodalinnerpara">
              We want to take a moment to inform you that the software you're about to use is currently in its <span>Alpha Phase</span>. This means that it is still in the early stages of development.
            </p>
            <p className="mainmodalinnerpara">
              While our dedicated team is working tirelessly to improve its features and performance, some functionalities may not work as intended or may be under continuous modification. There might be occasional bugs or instability which we are actively seeking to rectify.
            </p>
            <p className="mainmodalinnerpara">
              By continuing, you acknowledge and accept that any consequences resulting from the use of this software will be solely your responsibility. We encourage you to report any issues you encounter during usage to aid our development process.
            </p>
            <p className="mainmodalinnerpara">
              Thank you for your understanding and patience. Your feedback is valuable to us and greatly appreciated.
            </p>
          </div>
          <div class="form-check radiocheck-btnn">
            <input onClick={()=>{
              setchangecolor(!changecolor);
            }} class="form-check-input" type="checkbox" id="flexCheckDefault" />
            <label className="form-check-label importhead" for="flexCheckDefault">
            I understand that the use is my responsibility
            </label>
          </div>
          <button onClick={modallhandler} className={changecolor ? 'continuebutton' : 'disable-btn disabled' }>
            continue
          </button>
        </Modal.Body>
      </Modal> */}

      {/* <Modal className="modal-showstart" show={showdisclaimer1} onHide={handleClosedisclaimer1} centered backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="welcomeheader">
            <img src="\dark-logo.svg" alt="" className="dark" />
            <img src="\logo.svg" alt="" className="white" />
            <img src="\Assets\close-circle.svg" alt="crossimg" className="crossimg" />
          </div>
          <div className="welcomtext">
            <h6 className="welcomehead">Welcome to the DOP Testnet</h6>
            <p className="welcomepara">Experience the DOP dapp on testnet</p>

            <div className="checkboxmain">
              <div class="content">
                <label class="checkBox">
                  <input type="checkbox" id="ch1" onChange={inputField1Handler2} />
                  <div class="transition"></div>
                </label>
              </div>
              <p className="checkboxpara">I have read and agreed to the <a className="welcome-text" style={{ color: "#fff" }} href="\termofuse.pdf" target="_blank">terms of use</a>.</p>
            </div>
          </div>
          {
            // cheked2&&
            cheked1 &&


            <button onClick={modallhandler} className='continuebutton'>
                 <GoogleReCaptchaProvider
    reCaptchaKey="6LfD_xopAAAAAJBjNkvwnL2nKh5S0wfnyP0EByCU"
    language="[optional_language]"
    useRecaptchaNet="[optional_boolean_value]"
    useEnterprise="[optional_boolean_value]"
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: 'head', // optional, default to "head", can be "head" or "body",
      nonce: undefined // optional, default undefined
    }}
 
  >
         explore dop
  </GoogleReCaptchaProvider>
         
            </button>
            <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>
          }

        </Modal.Body>
      </Modal> */}



      <Modal className={languageselected == "ar" ? "modal-showstart newdesclaimermodal termsmodal direction-for-arabic" : "modal-showstart newdesclaimermodal termsmodal"} show={showdisclaimer1} onHide={handleClosedisclaimer1} centered backdrop="static"
        keyboard={false}>
        <Modal.Header>
          <Modal.Title>
          {t('route-landing-TermCheckbox')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => modallhandler(e)} className="w-100" >
            {/* <div className="mainmodalstartpara forscroll">
            <h6 className="termshead">Terms & Conditions</h6>
            <p className="mainmodalinnerpara">
              THE CORE TEAM, INCLUSIVE OF ITS AFFILIATES AND REPRESENTATIVES, HEREBY PROVIDES NOTICE THAT ANY FUNDS, CONSIDERATIONS, CONTRIBUTIONS, INCOME, PAYMENT, OR OTHER FINANCIAL BENEFITS DERIVED FROM THE SALE OF DOP TOKENS, WHETHER FROM A PRIVATE SALE, PUBLIC SALE, OR ANY OTHER MEANS ("RECEIVED FUNDS"), MAY BE UTILIZED AT THE ABSOLUTE DISCRETION OF THE CORE TEAM WITHOUT ANY RESTRICTION
            </p>
            <p className="mainmodalinnerpara">
              FOR CLARITY, THIS INCLUDES, BUT IS NOT LIMITED TO, THE USE OF RECEIVED FUNDS FOR NONBUSINESS-RELATED ENDEAVORS. NO REPRESENTATION, WARRANTY, OR ASSURANCE IS MADE BY THE CORE TEAM REGARDING THE SPECIFIC ALLOCATION OR UTILIZATION OF THE RECEIVED FUNDS FOR ANY PARTICULAR PURPOSE, INCLUDING ANY BUSINESS-RELATED OBJECTIVES. ANY PARTY PURCHASING DOP TOKENS ACKNOWLEDGES AND AGREES THAT THE CORE TEAM RESERVES FULL DISCRETION OVER THE USAGE OF THE RECEIVED FUNDS. SUCH PURCHASERS EXPRESSLY WAIVE AND RELINQUISH ANY RIGHT TO RAISE CLAIMS AGAINST THE CORE TEAM, ITS REPRESENTATIVES, SHAREHOLDERS, DIRECTORS, EMPLOYEES, SERVICE PROVIDERS, AFFILIATES, AND ANY RELATED PARTIES CONCERNING THE ALLOCATION OR UTILIZATION OF THE RECEIVED FUNDS.
            </p>
          </div> */}
            <div className="checkboxmain">
              <div class="content">
                <label class="checkBox">
                  <input type="checkbox" id="ch1" onChange={inputField1Handler2} />
                  <div class="transition"></div>
                </label>
              </div>
              <p className="checkboxpara">{t('route-landing-TermCheckbox-T1')} <a className="textdecor" target="_blank" href="\termofuse.pdf">{t('route-landing-TermCheckbox-T11')}.</a></p>
            </div>
            <div className="material-textfield" style={{ margin: '30px 0px' }}>
              <input placeholder="Enter your email address" onChange={inputField1Handler5}
                value={inputFeild6}
                type="text"
              />
              <label>{t('route-landing-TermCheckbox-T2')}</label>
              {/* <img src="\Assets\import-assets\clipboard-text-dark.svg" alt="clip" className='clip dark' />
                <img src="\Assets\import-assets\clipboard-text-white.svg" alt="clip" className='clip white' /> */}
            </div>

            {cheked1 && checkedNew1 ?

              <button type="submit" className='continuebutton'>
              {t('route-landing-TermCheckbox-B1')}
              </button>
              :
              
              <button type="submit" className='blackbtn'>
              {t('route-landing-TermCheckbox-B1')}
              </button>

            }
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Banner;
