import React, { useState, useEffect } from 'react'
import "./account.scss"
import Modal from 'react-bootstrap/Modal';
import { Accordion } from 'react-bootstrap'
import { saveData, getData } from '../../../db';
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../../../hooks/useWeb3";
import { CopyToClipboard } from "react-copy-to-clipboard";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { map } from 'lodash';
import ReactDOM from 'react-dom';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next'
const PrivateAssets = ({ userBalance, setInternalAount, itemsAssets }) => {
    // console.log(itemsAssets,'userBalance');
    const { t } = useTranslation()
    const web3 = useWeb3();
    const { account } = useWeb3React();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [routess, setRoutes] = useState(0);
    const [accountt, setyAmountt] = useState(null)
    const [accountt1, setyAmountt1] = useState(null)
    const [copied, setCopied] = useState(false);
    const [mainArry, setMainArry] = useState([]);
    const handleClick = async () => {
        // await saveData('tpl_password',pasword);
        const result = await getData('selected_privateKey');
        const result1 = await getData('tpl_data');
        let newwallet = web3.eth.accounts.privateKeyToAccount(result);
        newwallet = newwallet?.address.toLowerCase()
        // console.log(newwallet, 'newwalletx');
        setyAmountt1(result)
        setyAmountt(newwallet)
        setMainArry(result1)
        // setData(result);
    };
    useEffect(() => {
        handleClick()

    }, [account]);

    const copiedHandle = () => {
        setCopied(true);
        setTimeout(
            function () {
                setCopied(false);
            }.bind(this),
            1500
        );
    };
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {copied ? t('copied') : t('copy')}
        </Tooltip>
    );
    const clockmeee = async (i) => {
        // console.log(i, 'i.private_keyxs');
        //  if(accountt1==i.private_key){
        setyAmountt(i.account_address)
        setInternalAount(i.account_address)
        await saveData('selected_privateKey', i.private_key);
        //  }


    }


    var languageselected = localStorage.getItem('codelanguage')

    return (
        <>
            {/* {
                routess == 0 ?
                    <section className="private-assets">
                        <div className="upper-heading">
                            <div class="dropdown">
                              
                                <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div>
                                        <h6>Account</h6>
                                    
                                    </div>
                                </button>
                                <ul class="dropdown-menu">
                                    <div className="main-head">
                                        <h6>My Accounts</h6>
                                    </div>
                                    <div className="bottom-links">
                                        {
                                            mainArry.map((i, x) => {
                                                return (
                                                    <a onClick={() => clockmeee(i)}>
                                                        <h6>Account {x + 1}</h6>
                                                        <p>{i.account_address}</p>
                                                    </a>
                                                )
                                            })
                                        }
                                    </div>
                                </ul>
                            </div>
                            <div className='imgeCopy'>
                            <p>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip}
                                                className=""
                                            >
                                                <CopyToClipboard
                                                    text={accountt}
                                                    onCopy={() => copiedHandle()}
                                                >
                                                    <p className="copy_data click___One d-flex align-items-center ">
                                                        <span className="gsvsvcvst">{accountt}</span> <img src="\Assets\button-svgs\Accountcopypicwhite.svg" alt="" className="logoimggpccro ml-3" />
                                                    </p>
                                                </CopyToClipboard>
                                            </OverlayTrigger>
                                        </p>
                            </div>
                        </div>
                        <div className='importheader'>
                            <h6 className='myasset-heading' >      My Assets </h6>

                        </div>

                        <div className="bottom-table">
                            <div onClick={() => setRoutes(1)} className="inner-item">
                                <div className="left-side">
                                    <img src="\Assets\button-svgs\tomi.svg" alt="img" className='img-fluid' />
                                    <h6>tpUSD</h6>
                                </div>
                                <div className="right-side">
                                   
                                    <a href="#" className='pending-btn'>0 Pending  <img src="\Assets\button-svgs\pending-icon.svg" alt="img" className='img-fluid ms-2' /></a>
                                </div>
                            </div>
                        </div>
                    </section> :
                    routess == 1 ? */}
            <section className={languageselected == "ar" ? "private-assets1 direction-for-arabic" : "private-assets1"}>
                <div className="upper-headingold">
                    <div class="dropdown">

                        <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <div>
                                <h6>{t('Sidebar-Account')}</h6>

                            </div>
                        </button>
                        <ul class="dropdown-menu">
                            <div className="main-head">
                                <h6>{t('myAccount')}</h6>
                            </div>
                            <div className="bottom-links">
                                {
                                    mainArry.map((i, x) => {
                                        return (
                                            <a onClick={() => clockmeee(i)}>
                                                <h6>{t('Sidebar-Account')} {x + 1}</h6>
                                                <p>{i.account_address}</p>
                                            </a>
                                        )
                                    })
                                }
                            </div>
                        </ul>
                    </div>
                    <div className="twice-btns">
                        <button onClick={handleShow} className='btn-scanned'>
                            <img src="\Assets\button-svgs\accountscanned.svg" alt="img" className={languageselected == "ar" ? "img-fluid white ms-2 direction-for-arabic" : "img-fluid  white me-2"} />
                            <img src="\Assets\button-svgs\accountscanneddark.svg" alt="img" className={languageselected == "ar" ? "img-fluid dark ms-2 direction-for-arabic" : "img-fluid dark me-2"} />
                            {t('QR')}</button>
                        {/* <button className='btn-more'>More <img src="\Assets\button-svgs\more-btn.svg" alt="img" className='img-fluid ms-2' /></button> */}
                    </div>
                    <div className='imgeCopy'>
                        <p>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                                className=""
                            >
                                <CopyToClipboard
                                    text={accountt}
                                    onCopy={() => copiedHandle()}
                                >
                                    <p className="copy_data click___One d-flex align-items-center ">
                                        <span className="gsvsvcvst">{accountt}</span> <img src="\Assets\button-svgs\Accountcopypicwhite.svg" alt="" className="logoimggpccro ml-3" />
                                    </p>
                                </CopyToClipboard>
                            </OverlayTrigger>
                        </p>
                    </div>
                </div>
                {/* <div className="upper-heading">
                    <img onClick={() => setRoutes(0)} src="\Assets\button-svgs\arrow-left.svg" alt="img" className='img-fluid arrow-bck' />
                    <div className="eth-elem ms-0">
                        <img src="\Assets\import-assets\tpUSDT.png" alt="img" className='img-fluid' style={{ width: "30px", height: "30px" }} />
                        <h6>tpUSD</h6>
                    </div>
                    <div className="twice-text">
                        <h6><span></span>tpUSD</h6>
                        <h6><span></span>TPL Private Asset(s)</h6>
                    </div>
                </div> */}
                <h3 className="maintablehead">{t('Sidebar-Account-H1-T1')}</h3>
                <div className="historytable">
                    {
                        itemsAssets.map((e) => {
                            return (
                                <div className="maintablee">
                                    <div className="lefttable">
                                        <div className="leftimage">
                                            <img src={e?.igm} alt="img" className="leftinnerimg" />
                                            <div className="leftcheckimg">
                                                <img src="\Assets\tpldashboard\history\rightcheckimg.svg" alt="img" className="leftcheckinnerimg dark" />
                                                <img src="\Assets\tpldashboard\history\rightcheckimgdark.svg" alt="img" className="leftcheckinnerimg white " />
                                            </div>
                                        </div>
                                        <p className="lefttext">{e?.name}</p>
                                    </div>
                                    <h4 className="righttext">{e?.amountt ? e?.amountt?.toFixed(3) : '0.000'}</h4>
                                </div>
                            )
                        })
                    }


                    {/* <table>
                        <thead>
                            <th>
                                <div className="tblheader">
                                    <p className="tblhead">id</p>

                                </div>
                            </th>
                            <th>
                                <div className="tblheader">
                                    <p className="tblhead">Source Chain</p>

                                </div>
                            </th>
                            <th>
                                <div className="tblheader">
                                    <p className="tblhead">Asset</p>

                                </div>
                            </th>
                            <th>
                                <div className="tblheader">
                                    <p className="tblhead">Amount</p>

                                </div>
                            </th>
                            {userBalance > 0
                                ?
                                <th>
                                    <div className="tblheader">
                                        <p className="tblhead">Status</p>

                                    </div>
                                </th>
                                :
                                ""

                            }


                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="tbltd">
                                        <p className="serial">1</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbltd">
                                        <p className="darktext">Ethereum</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbltd">
                                        <p className="darktext">tpUSD</p>
                                    </div>
                                </td>

                                <td>
                                    <div className="tbltd">
                                        <p className="darktext amount-style-value"><img style={{ width: "35px", height: "35px" }} src="\Assets\import-assets\tpUSDT.png" alt="img" className='img-fluid me-2' />{userBalance ? parseFloat(userBalance).toFixed(4) : '0.00'}</p>
                                    </div>
                                </td>
                                {userBalance > 0 ?
                                    <td>
                                        <div className="tbltd">
                                            <button className="statusbtn green">
                                                <img src="\Assets\tpldashboard\history\tick-circle.svg" alt="tick" />
                                                Converted
                                            </button>
                                        </div>
                                    </td>
                                    :
                                    ""

                                }


                            </tr>
                            <tr>
                                <td>
                                    <div className="tbltd">
                                        <p className="serial">2</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbltd">
                                        <p className="darktext">Ethereum</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbltd">
                                        <p className="darktext">Ethereum</p>
                                    </div>
                                </td>

                                <td>
                                    <div className="tbltd">
                                        <p className="darktext">1</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbltd">
                                        <button className="statusbtn green">
                                            <img src="\Assets\tpldashboard\history\tick-circle.svg" alt="tick" />
                                            Imported
                                        </button>
                                    </div>
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <div className="tbltd">
                                        <p className="serial">1</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbltd">
                                        <p className="darktext">Ethereum</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbltd">
                                        <p className="darktext">Ethereum</p>
                                    </div>
                                </td>

                                <td>
                                    <div className="tbltd">
                                        <p className="darktext">1</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="tbltd">
                                        <button className="statusbtn green">
                                            <img src="\Assets\tpldashboard\history\tick-circle.svg" alt="tick" />
                                            Imported
                                        </button>
                                    </div>
                                </td>

                            </tr>

                        </tbody>
                    </table> */}
                </div>
                {/* mobileview */}
                <div className="accordionmobile d-none">
                    <h6 className="acchead">Source Chain</h6>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>tpUSD</Accordion.Header>
                            <Accordion.Body>
                                <div className="accinner">
                                    <p className="accinnerpara">id</p>
                                    <h6 className="accinnerhead">1</h6>
                                </div>
                                <div className="accinner">
                                    <p className="accinnerpara">Source Chain</p>
                                    <h6 className="accinnerhead">Sepolia</h6>
                                </div>
                                <div className="accinner">
                                    <p className="accinnerpara">Amount</p>
                                    <h6 className="accinnerhead amount-style-value-mbl"><img style={{ width: "25px", height: "25px" }} src="\Assets\import-assets\tpUSDT.png" alt="img" className='img-fluid me-2' />{parseFloat(userBalance).toFixed(4)}</h6>
                                </div>
                                {userBalance > 0 ?
                                    <div className="accinner">
                                        <p className="accinnerpara">Status</p>
                                        <button className="statusbtn green">
                                            <img src="\Assets\tpldashboard\history\tick-circle.svg" alt="tick" />
                                            Converted
                                        </button>
                                    </div>
                                    :
                                    ""

                                }



                            </Accordion.Body>
                        </Accordion.Item>
                        {/* <Accordion.Item eventKey="1">
                                        <Accordion.Header>Ethereum</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="accinner">
                                                <p className="accinnerpara">id</p>
                                                <h6 className="accinnerhead">2</h6>
                                            </div>
                                            <div className="accinner">
                                                <p className="accinnerpara">Destination Chain</p>
                                                <h6 className="accinnerhead">Ethereum</h6>
                                            </div>
                                            <div className="accinner">
                                                <p className="accinnerpara">Amount</p>
                                                <h6 className="accinnerhead">$10,538.54</h6>
                                            </div>

                                            <div className="accinner">
                                                <p className="accinnerpara">Status</p>
                                                <button className="statusbtn green">
                                                    <img src="\Assets\tpldashboard\history\tick-circle.svg" alt="tick" />
                                                    Imported
                                                </button>
                                            </div>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Ethereum</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="accinner">
                                                <p className="accinnerpara">id</p>
                                                <h6 className="accinnerhead">2</h6>
                                            </div>
                                            <div className="accinner">
                                                <p className="accinnerpara">Destination Chain</p>
                                                <h6 className="accinnerhead">Ethereum</h6>
                                            </div>
                                            <div className="accinner">
                                                <p className="accinnerpara">Amount</p>
                                                <h6 className="accinnerhead">$10,538.54</h6>
                                            </div>

                                            <div className="accinner">
                                                <p className="accinnerpara">Status</p>
                                                <button className="statusbtn green">
                                                    <img src="\Assets\tpldashboard\history\tick-circle.svg" alt="tick" />
                                                    Imported
                                                </button>
                                            </div>

                                        </Accordion.Body>
                                    </Accordion.Item> */}
                    </Accordion>
                </div>
            </section>
            {/* : ""

            } */}





            {/* Tpl Address & Secret Key modal here........................ */}
            <Modal className={languageselected == "ar" ? "address-secret-modal direction-for-arabic" : "address-secret-modal"} show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('QR')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>{t('Sidebar-Account')}</h6>
                    <div className="qr-code">
                        <QRCodeSVG value={accountt} />
                    </div>
                    <div className="copy-address">
                        {/* <p>{accountt}</p> */}
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                            className=""
                        >
                            <CopyToClipboard
                                text={accountt}
                                onCopy={() => copiedHandle()}
                            >
                                <p className="copy_data click___One d-flex align-items-center ">
                                    <span className="gsvsvcvst">{accountt}</span> <img src="\Assets\button-svgs\Accountcopypicwhite.svg" alt="" className={languageselected == "ar" ? "dyuvyydvvyddcdcd logoimggpccroa ml-3" : "logoimggpccroa ml-3"} style={{
                                        'position': 'absolute',
                                        'left': '87%'
                                    }} />
                                </p>
                            </CopyToClipboard>
                        </OverlayTrigger>
                    </div>
                    {/* <button className='btn-expert'>Export secret key</button> */}
                </Modal.Body>
            </Modal>



        </>
    )
}

export default PrivateAssets
