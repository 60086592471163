let initState = {
  // GetUserAll: [], s
  allowance:0,
  send:"",
  withDraw:"",
  deposite:""
};

export const UserReducer = (state = initState, action) => {

  const { type, payload } = action; //object destr
  // console.log(payload,'payload');
  switch (type) {
    case "GETUSER":
      return {
        ...state,
        GetUserAll: payload,
      };
    case "USER_REWARD":
      
      return {
        ...state,
        reward: payload,
      };
      case "ALOLOWANCE_1":
      
        return {
          ...state,
          allowance: payload,
        };
        case "SEND_1":
      
          return {
            ...state,
            send: payload,
          };
          case "WITHDRAW_1":
      
            return {
              ...state,
              withDraw: payload,
            };
            case "DEPOSITE_1":
      
              return {
                ...state,
                deposite: payload,
              };

    default:
      return state;
  }
};