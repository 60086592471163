
import React,{useState,useEffect} from 'react'
import './history.scss'
import { Accordion } from 'react-bootstrap'
import { saveData, getData } from '../../../db'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
const Transferhistory = ({userBalance,itemsAssets}) => {
    const [deposite ,setDeposite]=useState([])
    const { t } = useTranslation()
    const handleClick = async () => {
        // await saveData('tpl_password',pasword);
        let result = await getData('selected_privateKey');
        let dataHistory = await getData('tpl_data');
     
        let dumObj=dataHistory.find((i)=>i.private_key==result)
        // console.log(dumObj.transfer,'dumObj');
        setDeposite(dumObj.transfer)
        // const result1 = await getData('selected_privateKey');
        // if(!result&& !result1){
        //   history.push('/')
        // }
        // setData(result);
      };
      useEffect(() => {
        handleClick()
      },[itemsAssets]);
      var languageselected = localStorage.getItem('codelanguage')
    return (
        <>
            <section className={languageselected == "ar" ? "history direction-for-arabic" : "history"}>
                <div className="historyheader">
                    <h4 className="historyhead">{t('Sidebar-History-H2')}</h4>
                    {/* <button className="refreshbtn">
                        <img src="\Assets\tpldashboard\history\refresh.svg" alt="refreshimg" className="refreshimg dark" />
                        <img src="\Assets\tpldashboard\history\refreshlight.svg" alt="refreshimg" className="refreshimg white" />
                        <span>Refresh</span>
                    </button> */}
                </div>
                <div className="historytable">
                    <table>
                        <thead>
                            <th>
                                <div className="tblheader">
                                    <p className="tblhead">{t('Sidebar-History-TableH1')}</p>
                                    <div className="arrows">
                                        <img src="\Assets\tpldashboard\history\upperarrow.svg" alt="innerarrow" className="innerarrow dark" />
                                        <img src="\Assets\tpldashboard\history\lowerarrow.svg" alt="innerarrow" className="innerarrow dark" />
                                        <img src="\Assets\tpldashboard\history\upperarrowwhite.svg" alt="innerarrow" className="innerarrow white" />
                                        <img src="\Assets\tpldashboard\history\lowerarrowwhite.svg" alt="innerarrow" className="innerarrow white" />


                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="tblheader">
                                    <p className="tblhead">{t('Sidebar-History-TableH2')}</p>
                                    <div className="arrows">
                                        <img src="\Assets\tpldashboard\history\upperarrow.svg" alt="innerarrow" className="innerarrow dark" />
                                        <img src="\Assets\tpldashboard\history\lowerarrow.svg" alt="innerarrow" className="innerarrow dark" />
                                        <img src="\Assets\tpldashboard\history\upperarrowwhite.svg" alt="innerarrow" className="innerarrow white" />
                                        <img src="\Assets\tpldashboard\history\lowerarrowwhite.svg" alt="innerarrow" className="innerarrow white" />
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="tblheader">
                                    <p className="tblhead">{t('Sidebar-History-TableH3')}</p>
                                    <div className="arrows">
                                        <img src="\Assets\tpldashboard\history\upperarrow.svg" alt="innerarrow" className="innerarrow dark" />
                                        <img src="\Assets\tpldashboard\history\lowerarrow.svg" alt="innerarrow" className="innerarrow dark" />
                                        <img src="\Assets\tpldashboard\history\upperarrowwhite.svg" alt="innerarrow" className="innerarrow white" />
                                        <img src="\Assets\tpldashboard\history\lowerarrowwhite.svg" alt="innerarrow" className="innerarrow white" />
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="tblheader">
                                    <p className="tblhead">{t('Sidebar-History-TableH4')}</p>
                                    <div className="arrows">
                                        <img src="\Assets\tpldashboard\history\upperarrow.svg" alt="innerarrow" className="innerarrow dark" />
                                        <img src="\Assets\tpldashboard\history\lowerarrow.svg" alt="innerarrow" className="innerarrow dark" />
                                        <img src="\Assets\tpldashboard\history\upperarrowwhite.svg" alt="innerarrow" className="innerarrow white" />
                                        <img src="\Assets\tpldashboard\history\lowerarrowwhite.svg" alt="innerarrow" className="innerarrow white" />
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="tblheader">
                                    <p className="tblhead">{t('Sidebar-History-TableH5')}</p>
                                    <div className="arrows">
                                        <img src="\Assets\tpldashboard\history\upperarrow.svg" alt="innerarrow" className="innerarrow dark" />
                                        <img src="\Assets\tpldashboard\history\lowerarrow.svg" alt="innerarrow" className="innerarrow dark" />
                                        <img src="\Assets\tpldashboard\history\upperarrowwhite.svg" alt="innerarrow" className="innerarrow white" />
                                        <img src="\Assets\tpldashboard\history\lowerarrowwhite.svg" alt="innerarrow" className="innerarrow white" />
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="tblheader">
                                    <p className="tblhead">{t('Sidebar-History-TableH6')}</p>
                                    <div className="arrows">
                                        <img src="\Assets\tpldashboard\history\upperarrow.svg" alt="innerarrow" className="innerarrow dark" />
                                        <img src="\Assets\tpldashboard\history\lowerarrow.svg" alt="innerarrow" className="innerarrow dark" />
                                        <img src="\Assets\tpldashboard\history\upperarrowwhite.svg" alt="innerarrow" className="innerarrow white" />
                                        <img src="\Assets\tpldashboard\history\lowerarrowwhite.svg" alt="innerarrow" className="innerarrow white" />
                                    </div>
                                </div>
                            </th>
                            {/* <th>
                                <div className="tblheader">
                                    <p className="tblhead">Operation</p>
                                </div>
                            </th> */}
                        </thead>
                        <tbody>
                            {
                                deposite?.map((i,x)=>{
                                    let a= new Date(i?.date)
                                    const StackIntialDate = moment(a).format("DD-MM-YYYY H:mm ");
                                    return(
                                        <tr>
                                        <td>
                                            <div className="tbltd">
                                                <p className="serial">{x+1}</p>
                                            </div>
                                        </td>
                                    
                                        <td>
                                            <div className="tbltd">
                                                <p className="darktext">{i?.asets}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="tbltd">
                                                      <p className="darktext">{StackIntialDate}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="tbltd">
                                                <p className="darktext">{parseFloat(i.amount).toFixed(4)}</p>
                                            </div>
                                        </td>
                                        <td>
                                        <div className="tbltd">
                                                    <p className="darktext"> {i?.hash.slice(0, 6)}...
                                                        {i?.hash.slice(
                                                            i?.hash.length - 5,
                                                            i?.hash.length
                                                        )}{" "}
                                                        <a  target="_blank" href={`https://sepolia.etherscan.io/tx/${i.hash}`}> <svg className='set-color-ontheme' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M10.8333 9.79167C10.675 9.79167 10.5167 9.73334 10.3917 9.60834C10.15 9.36667 10.15 8.96667 10.3917 8.725L17.225 1.89167C17.4667 1.65 17.8667 1.65 18.1083 1.89167C18.35 2.13334 18.35 2.53334 18.1083 2.775L11.275 9.60834C11.15 9.73334 10.9917 9.79167 10.8333 9.79167Z" fill="white" />
                                                        <path d="M18.3333 6.29167C17.9917 6.29167 17.7083 6.00834 17.7083 5.66667V2.29167H14.3333C13.9917 2.29167 13.7083 2.00834 13.7083 1.66667C13.7083 1.32501 13.9917 1.04167 14.3333 1.04167H18.3333C18.675 1.04167 18.9583 1.32501 18.9583 1.66667V5.66667C18.9583 6.00834 18.675 6.29167 18.3333 6.29167Z" fill="white" />
                                                        <path d="M12.5 18.9583H7.49999C2.97499 18.9583 1.04166 17.025 1.04166 12.5V7.5C1.04166 2.97501 2.97499 1.04167 7.49999 1.04167H9.16666C9.50832 1.04167 9.79166 1.32501 9.79166 1.66667C9.79166 2.00834 9.50832 2.29167 9.16666 2.29167H7.49999C3.65832 2.29167 2.29166 3.65834 2.29166 7.5V12.5C2.29166 16.3417 3.65832 17.7083 7.49999 17.7083H12.5C16.3417 17.7083 17.7083 16.3417 17.7083 12.5V10.8333C17.7083 10.4917 17.9917 10.2083 18.3333 10.2083C18.675 10.2083 18.9583 10.4917 18.9583 10.8333V12.5C18.9583 17.025 17.025 18.9583 12.5 18.9583Z" fill="white" />
                                                    </svg> 
                                                    </a></p>
                                                </div>
                                            </td>
                                        <td>
                                            <div className="tbltd">
                                                <button className="statusbtn green">
                                                    <img src="\Assets\tpldashboard\history\tick-circle.svg" alt="tick" />
                                                 {t('success')}
                                                </button>
                                            </div>
                                        </td>
                                        {/* <td>
                                            <div className="tbltd">
                                                <p className="bluetext">Details</p>
                                                <img src="\Assets\tpldashboard\history\arrow-right.svg" alt="bluearrow" className="bluearrow" />
                                            </div>
                                        </td> */}
                                    </tr>
                                    )
                                })
                            }
                        
                        
                        </tbody>
                    </table>
                </div>
                {/* mobileview */}
                <div className="accordionmobile d-none">
                    <h6 className="acchead">Chain</h6>
                    <Accordion defaultActiveKey="0">
                        {/* <Accordion.Item eventKey="0"> */}
                            {/* <Accordion.Header>Ethereum</Accordion.Header>
                            <Accordion.Body>
                                <div className="accinner">
                                    <p className="accinnerpara">id</p>
                                    <h6 className="accinnerhead">2</h6>
                                </div>
                                <div className="accinner">
                                    <p className="accinnerpara">Des</p>
                                    <h6 className="accinnerhead">Ethereum</h6>
                                </div>
                                <div className="accinner">
                                    <p className="accinnerpara">Asset</p>
                                    <h6 className="accinnerhead">$10,538.54</h6>
                                </div>
                                <div className="accinner">
                                    <p className="accinnerpara">Amount</p>
                                    <h6 className="accinnerhead">$10,538.54</h6>
                                </div>
                                {/* <div className="accinner">
                                    <p className="accinnerpara">Bridge</p>
                                    <h6 className="accinnerhead">Celer Network</h6>
                                </div> */}
                                {/* <div className="accinner">
                                    <p className="accinnerpara">Status</p>
                                    <button className="statusbtn yellow">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">  <img src="\Assets\tpldashboard\history\loading.svg" alt="loading" /></span>
                                        </div>
                                        Processing
                                    </button>
                                </div> */}
                            {/* </Accordion.Body> */} 
                        {/* </Accordion.Item> */}
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Ethereum</Accordion.Header>
                            <Accordion.Body>
                            {
                                   deposite?.map((i,x)=>{
                                    // console.log(i.date,'iiiiiii');
                                    let a= new Date(i?.date)
                                    const StackIntialDate = moment(a).format("DD-MM-YYYY H:mm ");
                                    return(
                                        <>
                                <div className="accinner">
                                    <p className="accinnerpara">id</p>
                                    <h6 className="accinnerhead">{x+1}</h6>
                                </div>
                                <div className="accinner">
                                    <p className="accinnerpara">Assets</p>
                                    <h6 className="accinnerhead">{i?.asets}</h6>
                                </div>
                                {/* <div className="accinner">
                                    <p className="accinnerpara"></p>
                                    <h6 className="accinnerhead">Ethereum</h6>
                                </div> */}
                                <div className="accinner">
                                    <p className="accinnerpara">Date</p>
                                    <h6 className="accinnerhead">{StackIntialDate}</h6>
                                </div>
                                <div className="accinner">
                                    <p className="accinnerpara">Amount</p>
                                    <h6 className="accinnerhead">{parseFloat(i.amount).toFixed(4)} </h6>
                                </div>
                                {/* <div className="accinner">
                                    <p className="accinnerpara">Bridge</p>
                                    <h6 className="accinnerhead">Celer Network</h6>
                                </div> */}
                                <div className="accinner">
                                    <p className="accinnerpara">Status</p>
                                    <div className="tbltd">
                                            <button className="statusbtn green">
                                                <img src="\Assets\tpldashboard\history\tick-circle.svg" alt="tick" />
                                                {t('success')}</button>
                                        </div>
                                </div>
                                </>
                                      )
                                    }) 
                                 }
                            </Accordion.Body>
                        </Accordion.Item>
                        {/* <Accordion.Item eventKey="2">
                            <Accordion.Header>Ethereum</Accordion.Header>
                            <Accordion.Body>
                                <div className="accinner">
                                    <p className="accinnerpara">id</p>
                                    <h6 className="accinnerhead">2</h6>
                                </div>
                                <div className="accinner">
                                    <p className="accinnerpara">Des</p>
                                    <h6 className="accinnerhead">Ethereum</h6>
                                </div>
                                <div className="accinner">
                                    <p className="accinnerpara">Asset</p>
                                    <h6 className="accinnerhead">$10,538.54</h6>
                                </div>
                                <div className="accinner">
                                    <p className="accinnerpara">Amount</p>
                                    <h6 className="accinnerhead">$10,538.54</h6>
                                </div>
                                <div className="accinner">
                                    <p className="accinnerpara">Bridge</p>
                                    <h6 className="accinnerhead">Celer Network</h6>
                                </div>
                                <div className="accinner">
                                    <p className="accinnerpara">Status</p>
                                    <button className="statusbtn yellow">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">  <img src="\Assets\tpldashboard\history\loading.svg" alt="loading" /></span>
                                        </div>
                                        Processing
                                    </button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item> */}
                    </Accordion>
                </div>
            </section>
        </>
    )
}

export default Transferhistory
