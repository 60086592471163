import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { mintContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";
import { minBy } from "lodash";

const Mint = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.tpl;
  const tokenAddress1 = Environment.busd;



  const mint = useCallback(
    async (addresss,indux,amount) => {
      let gasPrice = await web3.eth.getGasPrice()
      gasPrice = parseInt(gasPrice) + 4000000000
        let nd=''
        let gas=''
        let details=''
      if (indux==0||indux==1){
      nd = web3.utils.toWei(amount.toString(), "mwei");
      }else{
        nd = web3.utils.toWei(amount.toString(), "ether");
      }
      const contract = mintContract(addresss, web3);
        // console.log(tokenAddress,account,'noor');
        // console.log(addresss,indux,nd,'addresss,indux,amount');
      try {
        gas = await contract.methods.mint(account,nd).estimateGas({
            from:account
          });
         details = await contract.methods.mint(account,nd).send({
          from: account,
          gas,
          gasPrice
        });
        // console.log('sdsdss' ,  buy)
        return details;
      } catch (error) {
        throw error;
      }
    },
    [ account,web3]
  );
  return { mint:mint };
};
export default Mint;