import React, { useEffect, useState, } from 'react'
import Modal from 'react-bootstrap/Modal';
import useWeb3 from "../../../hooks/useWeb3";
import { toast } from 'react-toastify';
import { useWeb3React } from "@web3-react/core";
import { saveData, getData } from '../../../db';
import Signature1 from "../../../hooks/dataSenders/userSign1"
import TransferTpl from "../../../hooks/dataFetchers/TransferTpl";
import Loader from '../../../hooks/loader';
import { io } from "socket.io-client";
import axios from 'axios';
import { API_URL } from "../../../utils/ApiUrl";
import AllowanceTpl from '../../../hooks/dataFetchers/AllowanceTpl';
import ApproveDop from '../../../hooks/dataFetchers/appriveDop.js';
import RouterPrice from '../../../hooks/dataFetchers/Rourterprice';
import Environment from "../../../utils/Environment";
import { GetUsers } from '../../../redux/action';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { setupNetwork } from '../../../utils/wallet';
import BalacefAccountDop from '../../../hooks/balanceOfDop';
const Transfer = ({ internalAount, itemsAssets, setSelectedItemAsset, selectedItemAsset, setStateusCheck, setDefaultt, defalttt }) => {
    const { t } = useTranslation()
    const web3 = useWeb3();
    const { account,chainId } = useWeb3React();
    const dispatch = useDispatch();
    let v = useSelector((state) => state?.UserReducer?.allowance)
    let sendStatus = useSelector((state) => state?.UserReducer?.send)
    // console.log(v, 'send');
    let sign121 = localStorage.getItem('sign');
    const { routerPrice } = RouterPrice()
    const [newAmount, setNewAmount] = useState(null)
    const { approveDop } = ApproveDop();
    const { allowanceTpl } = AllowanceTpl();
    const [itemsAssetsOrignal, setItemsAssetsOrignal] = useState(null);
    const [dopassetCount, setDopassetCount] = useState('0x130c318bef3a60f05541955003b2baa1d691335f');
    const [dopBlance,setDopBalance]=useState(null)
    const [off, setOff] = useState();
    // const localData1 = localStorage.getItem('send');
    const localData2 = localStorage.getItem('idd');
    const {balacefAccountDop}=BalacefAccountDop()
    // console.log(allowance,'send');

    useEffect(() => {
        // handleClick()
        // if (account) {
            setItemsAssetsOrignal(itemsAssets)
        // }
    }, [itemsAssets]);

    useEffect(() => {
        if(account&&web3)
        {
        balaceOfDoppp()
        }
        // account
    }, [web3]);
    let typeParamId = ''
    const [userBalance, setUserBalance] = useState(null)
    // const [allowance, setAlowance] = useState(null)
    // console.log(userBalance,'userBalance');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => {
        setShow3(false)
        setAmount('')
        setWalletAddress('')


        setSelectedItemAsset('')
        setSelectedItemNumber('')
        setSelectedItemimg('')

        setSelectedIndex('')
        setUserBalance('')
        setSelectedAccount('')

    };
    const handleShow3 = () => setShow3(true);

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);
    const [copied, setCopied] = useState(false);
    const [copied1, setCopied1] = useState(false);
    const [loaderr, setLoarder] = useState(false)
    const { transferTpl } = TransferTpl();
    const { userSign } = Signature1()

    const [walletAddres, setWalletAddress] = useState(null)
    const [amount, setAmount] = useState(false)
    const [check, setcheck] = useState(false)
    const [mainHsh, setHashh] = useState(null)
    const handler1 = (e) => {
        if (off) {
            setOff(!off)
        }
        var length = e.target.value.split(" ").join("").length;
        setWalletAddress(e.target.value)
        setcheck(false)
        if (length === 42) {
            let r = web3.utils.isAddress(e.target.value)
            if (r) {
                setcheck(true)

            } else {
                setcheck(false)
            }
        }
    }
    const [selectedIndex, setSelectedIndex] = useState("");
    const [selectedAccount, setSelectedAccount] = useState("");

    const [showgift, setShowgift] = useState(false);
    const handleClosegift = () => setShowgift(false);
    const handler3 = (e) => {
        // console.log(e.target.value, 'e.target.value ', userBalance);
        let a = parseFloat(e.target.value)
        if (a > userBalance) {
            setCopied1(true)
            setTimeout(() => {
                setCopied1(false)
            }, 2000)
            setAmount('')
        } else {
            setAmount(e.target.value)
            setNewAmount(a * 0.01)
        }
    }
    // console.log(newAmount,'amount');

    const confirm = async () => {
        const result = await getData('selected_privateKey');
        let dataHistory = await getData('tpl_data');

        let dumObj = dataHistory.find((i) => i.private_key == result)
        let newwallet = web3.eth.accounts.privateKeyToAccount(result);
        let newwallet1 = newwallet?.address
        let now = Date.now();
        let num = Math.floor((Math.random() * 1000000) + 1);
        // console.log(result,num,now,account,amount,'result,num,now,account,amount');
        const res1 = await userSign(result, num, now, amount, selectedIndex);
        if (res1) {
            // console.log(res1,'res1');
            let res12 = res1.substring(2)
            // console.log(res12,'res12');
            let r = '0x' + res12.slice(0, 64);
            let s = '0x' + res12.slice(64, 128);
            let v = '0x' + res12.slice(128, 130);
            // console.log(res1, 'res1', selectedIndex);
            try {
                const res = await transferTpl(amount, newwallet1, now, num, v, r, s, walletAddres, selectedIndex);
                if (res) {
                    handleClose2();
                    handleShow3();
                    setHashh(res?.transactionHash)
                    // const socket = io("wss://socket.tdns.network", {
                    //     reconnectionDelayMax: 1000,
                    // });
                    // socket.on('connect', function () {
                    // });
                    // typeParamId = 'emited'
                    // socket.emit("bid", typeParamId);

                    const now = Date.now();
                    let historyObj = {
                        amount: amount,
                        date: now,
                        asets: selectedItemAsset,
                        hash: res?.transactionHash,

                    }
                    dumObj.transfer.push(historyObj);
                    let index = dataHistory.indexOf(dumObj.private_key);
                    if (index > -1) {
                        historyObj = historyObj.splice(index, 1, dumObj)
                    }
                    await saveData('tpl_data', dataHistory);
                    let a= await api()
                    if (a){
                    apiCall(a)
                    alowence()
                    }
                }

            } catch (e) {
                // toast.error(e.message);
                setLoarder(false)
                alowence()
                // toast.error('The Sepolia netwrok appears to be busy. Try again later')
                // console.log(e, "e");
                // setMainLoader(false);
            }
        }

    }
    const balaceOfDoppp=async()=>{
        let v = await balacefAccountDop()
        setDopBalance(v)
        console.log(v,'vvvvvvvv');
    }

    const switchChain=async()=>{
        // if ()
        if(chainId===11155111) 
        {
          submit()
        }
        else{
         let a = await setupNetwork()
         if (a){
          submit()
         }
        } 
    }


    const submit = async () => {
        setLoarder(true)
        if (account) {
            if (walletAddres && check) {
                if (amount) {
                    let a = 3
                    // console.log(a,'aaaa',newAmount);
                    try {

                        let q = await routerPrice(a)
                        if (q) {
                            let newVal = q[1] / 10 ** 18
                            console.log(v,newVal,'qqqqq',dopBlance);
                            if(dopBlance>=newVal){
                            if (newVal >= v) {
                                try {
                                   
                                    const w = await approveDop(10000000)
                                    if (w) {
                                        confirm()
                                    }
                                } catch (err) {
                                    // toast.error('The Sepolia netwrok appears to be busy. Try again later')
                                    console.log(err);
                                    setLoarder(false)

                                }
                            }

                            else {
                                confirm()
                            }
                        }
                        else{
                            toast.error(t('new_eror'))
                            setLoarder(false)
                        }
                    }
                
                    } catch (err) {
                        console.log(err, 'qqqqq');
                        setLoarder(false)
                        alowence()
                    }
                    // if (newAmount>26?){

                    // }

                } else {
                    // console.log(copied1, 'copied1');\

                    setCopied1(true)
                    setTimeout(() => {
                        setCopied1(false)
                    }, 2000)
                    setLoarder(false)
                }
            } else {
                setCopied(true)
                setTimeout(() => {
                    setCopied(false)
                }, 2000)
                setLoarder(false)
            }
        } else {
            // toast.error('Please connect metamask')
            setLoarder(false)
        }
    }
    // handleClose2();
    // handleShow3();
    const withdraHandler = (e) => {
        e?.preventDefault()
        if (walletAddres && check) {
            if (amount) {
                handleShow2()
            } else {
                setCopied1(true)
                setTimeout(() => {
                    setCopied1(false)
                }, 2000)
            }

        }
        else {
            setCopied(true)
            setTimeout(() => {
                setCopied(false)
            }, 2000)

        }
    }

    // const [selectedItemAsset, setSelectedItemAsset] = useState("");
    const [selectedItemNumber, setSelectedItemNumber] = useState("");
    const [selectedItemimg, setSelectedItemimg] = useState("");


    const handlerForSelect = (e, i) => {
        setSelectedItemAsset(e.name)
        setSelectedItemNumber(e.amountt?.toFixed(3))
        setSelectedItemimg(e.igm)
        handleClose1()

        setSelectedIndex(e.indecBy)
        setUserBalance(e.amountt?.toFixed(3))
        setAmount('')
        setSelectedAccount(e?.address)
        setTimeout(() => {
            setItemsAssetsOrignal(itemsAssets)
        }, 1000);
    }


    const amountHamderSearch = (e) => {
        let qw = (e.target.value)
        qw = qw?.toUpperCase()
        // console.log(qw);
        // let result = text.includes("world");
        // console.log(qw);
        let dummy = []
        for (let a = 0; a < 11; a++) {
            // console.log(itemsAssets[a]?.name?.toUpperCase()?.includes(qw));
            // console.log();
            let we = itemsAssets[a]?.name?.toUpperCase()
            if (qw != '') {
                if (we?.includes(qw)) {
                    // console.log('if',itemsAssets[a]?.name);
                    dummy.push(itemsAssets[a])
                }
                //  else if (qw==''){

                //     dummy.push(itemsAssetsOrignal[a])

                // }
            } else {
                dummy.push(itemsAssets[a])
            }
            // console.log(dummy,'dummy');
            setItemsAssetsOrignal(dummy)
        }
    }






    const apiCall = async (e) => {
        const axios = require('axios');
        let data = JSON.stringify({
            "send_Assets" :{
                "isCompleted":true
              },
              "sign":sign121,
              "internalWalletAddress":internalAount,
              "externalWalletAddress":account
        });

        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${API_URL}rewards/${e}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                //   console.log(JSON.stringify(response.data));
                setLoarder(false)
                localStorage.setItem('send', true)
                setStateusCheck('sdasfsdf')
            })
            .catch((error) => {
                setLoarder(false)
                console.log(error);
                // toast.error(error.response.data.message);
            });
    }

    const alowence = async (e) => {
        try {
            // console.log(e);
            let r = await allowanceTpl(Environment.Dop)
            // console.log(r, 'noooor');
            if (r) {
                dispatch(GetUsers(r / 10 ** 18))
            }
        }
        catch (err) {
            console.log(err.message, 'noor');
        }
    }

    useEffect(() => {
        handleClose3()
    }, [defalttt])


    const [clickedButton, setClickedButton] = useState(localStorage.getItem('clickedButton') || null);
    useEffect(() => {
        if (clickedButton === 'btnsix') {
            const element = document.querySelector('.mainseven');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [clickedButton]);
    const Btnclick = (buttonName) => {
        setClickedButton(buttonName);
        localStorage.setItem('clickedButton', buttonName); // Save the clicked button to local storage
        window.location.reload();
    };

    const api = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${API_URL}rewards/walletaddress?internalWalletAddress=${internalAount}&&externalWalletAddress=${account}&&sign=${sign121}`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

       let response =await axios.request(config)
       try{
            if(response) {
                if (response?.data?.data?.updatedReward) {
                   return response?.data?.data?.updatedReward?._id
                }
            }
        }
        catch(err){
            setLoarder(false)
     console.log(err);
        }
            

    }
    var languageselected = localStorage.getItem('codelanguage')
    return (

        <>
            {loaderr &&

                <Loader text={t('check9')} />
            }
            <section className={languageselected == "ar" ? "deposit transfer direction-for-arabic" : "deposit transfer"}>
                <form onSubmit={(e) => withdraHandler(e)} className="w-100">
                    {/* <div className="twice-btn">
                    <div className="btn-drop" onClick={handleShow}>
                        <p>Chain <img src="\Assets\button-svgs\pending-icon.svg" alt="img" className='img-fluid ms-2 white' /><img src="\Assets\button-svgs\pending-iconlight.svg" alt="img" className='img-fluid ms-2 dark' /></p>
                        <h6>Select Chain</h6>
                        <img src="\Assets\button-svgs\arrow-down.svg" alt="img" className='img-fluid' />
                    </div>
                </div>
                <div className="asset" onClick={handleShow1}>
                    <div className="btn-drop">
                        <p>Asset <img src="\Assets\button-svgs\pending-icon.svg" alt="img" className='img-fluid ms-2 white' /><img src="\Assets\button-svgs\pending-iconlight.svg" alt="img" className='img-fluid ms-2 dark' /></p>
                        <h6>Select Assets</h6>
                        <img src="\Assets\button-svgs\arrow-down.svg" alt="img" className='img-fluid' />
                    </div>
                </div> */}
                    <div className="material-textfield set-customdrop-inner">
                        <input placeholder={t('pleasewrite')} value={walletAddres} type="text" onChange={(e) => handler1(e)} />
                        <label>{t('Sidebar-Transaction-H2-T1')}</label>
                        <div class="dropdown">
                            <button onClick={() => { setOff(!off) }} class="btn btn-secondary dropdown-toggle" type="button" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path d="M10.4534 4.77161H6.81926H3.54676C2.98676 4.77161 2.70676 5.44827 3.10343 5.84494L6.1251 8.86661C6.60926 9.35077 7.39676 9.35077 7.88093 8.86661L9.0301 7.71744L10.9026 5.84494C11.2934 5.44827 11.0134 4.77161 10.4534 4.77161Z" fill="#696969" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    {
                        off && <ul class="opennew-div">
                            <li>
                                {/* <a className="dropdown-item drop" onClick={() => {
                                    setOff(!off)
                                    setWalletAddress(internalAount)
                                    setcheck(true)
                                }}>
                                    <p>My  DOP Account 1</p>
                                    <h6>
                                        {internalAount.slice(0, 10)}...
                                        {internalAount.slice(
                                            internalAount.length - 5,
                                            internalAount.length
                                        )}{" "}
                                    </h6>
                                </a> */}
                                <a className="dropdown-item drop"
                                    onClick={() => {
                                        setOff(!off)
                                        setWalletAddress(dopassetCount)
                                        setcheck(true)
                                    }}
                                >
                                    <p>{t('Test-DOP-Account')}</p>
                                    <h6>
                                        {dopassetCount.slice(0, 10)}...
                                        {dopassetCount.slice(
                                            dopassetCount.length - 5,
                                            dopassetCount.length
                                        )}{" "}
                                    </h6>
                                </a>
                            </li>
                        </ul>
                    }
                    {copied &&
                        <p className="text-error">*{t('Invalid-Address')}</p>

                    }
                    <div className="asset" onClick={handleShow1} style={{ marginTop: '30px' }}>
                        <div className="btn-drop">
                            <p>{t('Select-encrypted-token')} </p>
                            {selectedItemAsset ? <>
                                <div className="selectmain">
                                    <div className="selectleft">
                                        <div className="selectimgmain">
                                            <img src={selectedItemimg} className='img-fluid selectinnerimg' />
                                            <div className="tpmainimg">
                                                <img src="\Assets\tpldashboard\history\rightcheckimg.svg" alt="img" className="tpinnerimg dark" />
                                                <img src="\Assets\tpldashboard\history\rightcheckimgdark.svg" alt="img" className="tpinnerimg white" />
                                            </div>
                                        </div>
                                        <h5 className="selecttext">{selectedItemAsset}</h5>
                                    </div>
                                    <h5 className="selectnumber">
                                        {selectedItemNumber}
                                    </h5>
                                </div>
                            </> :
                                <h6>{t('Select-encrypted-token-T1')}</h6>}
                            <img src="\Assets\button-svgs\arrow-down.svg" alt="img" className='img-fluid' />
                        </div>
                    </div>
                    <div className="material-textfield">
                        <input placeholder="0.00" type="number" value={amount} onChange={(e) => handler3(e)} />
                        <label>{t('Sidebar-Transaction-H1-T3')} </label>
                    </div>
                    {copied1}
                    {copied1 &&
                        <p className="text-error">*{t('Insufficient-Funds')}</p>}
                    {/* <div className="balance-div">
                    <p>tpUSD Balance</p>
                    <h6>{parseFloat(userBalance).toFixed(4)}</h6>
                </div> */}
                    <div className="yellowmain">
                        <p className="yellowtext">{t('Sidebar-Transaction-H2-T3')}.</p>
                    </div>
                    <button id="speicalAZ254" type='submit' className='btn-deposit'>{t('Sidebar-Transaction-H2')}</button>
                </form>


            </section>


            {/* select network modal here..................... */}
            <Modal className={languageselected == "ar" ? "selectnetwork-modal direction-for-arabic" : "selectnetwork-modal"} show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Network</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="select-chain">
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\ether-icon.svg" alt="img" className='img-fluid' />
                            Ethereum
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\bsc-icon.svg" alt="img" className='img-fluid' />
                            Binance Smart Chain
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\avax-icon.svg" alt="img" className='img-fluid' />
                            Avalanche
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\polygon-icon.svg" alt="img" className='img-fluid' />
                            Polygon
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* select asset modal here..................... */}
            <Modal className={languageselected == "ar" ? "selectnetwork-modal direction-for-arabic" : "selectnetwork-modal"} show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Select-Asset')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="option-field">
                        <img src="\Assets\button-svgs\search-normal.svg" alt="img" className='img-fluid search-icon' />
                        <input type="search" placeholder={t('search-assets')} onChange={(e) => amountHamderSearch(e)} />
                    </div>
                    <div className="select-chain">
                        {
                            itemsAssetsOrignal?.map((e, i) => {
                                return (
                                    <div className="inner-chain" onClick={() => handlerForSelect(e, i)
                                    }>
                                        <div className='forflex'>
                                            <div className="modalmainimg">
                                                <img src={e?.igm} alt="img" className='img-fluid modalinnerimg' />
                                                <div className="tpmainimg">
                                                    <img src="\Assets\tpldashboard\history\rightcheckimg.svg" alt="img" className="tpinnerimg dark" />
                                                    <img src="\Assets\tpldashboard\history\rightcheckimgdark.svg" alt="img" className="tpinnerimg white" />
                                                </div>
                                            </div>
                                            <p className="modalmaintext">{e?.name}</p>
                                        </div>
                                        <p className="modalmainnumber"> {e?.amountt > 0 ? parseFloat(e?.amountt)?.toFixed(3) : "0.000"}</p>
                                    </div>)
                            })
                        }


                        {/* <div className="inner-chain">
                            <img src="\Assets\button-svgs\bsc-icon.svg" alt="img" className='img-fluid' />
                            Binance Smart Chain
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\avax-icon.svg" alt="img" className='img-fluid' />
                            Avalanche
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\polygon-icon.svg" alt="img" className='img-fluid' />
                            Polygon
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\ether-icon.svg" alt="img" className='img-fluid' />
                            Ethereum
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\bsc-icon.svg" alt="img" className='img-fluid' />
                            Binance Smart Chain
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\avax-icon.svg" alt="img" className='img-fluid' />
                            Avalanche
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\polygon-icon.svg" alt="img" className='img-fluid' />
                            Polygon
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\ether-icon.svg" alt="img" className='img-fluid' />
                            Ethereum
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\bsc-icon.svg" alt="img" className='img-fluid' />
                            Binance Smart Chain
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\avax-icon.svg" alt="img" className='img-fluid' />
                            Avalanche
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\polygon-icon.svg" alt="img" className='img-fluid' />
                            Polygon
                        </div> */}
                    </div>
                </Modal.Body>
            </Modal>

            {/* transaction summary modal here..................... */}
            <Modal className={languageselected == "ar" ? "summary-modal direction-for-arabic" : "summary-modal"} show={show2} onHide={handleClose2} centered>
                <Modal.Header >
                    <Modal.Title>
                        <h6>{t('Transaction-Summary')}</h6>
                        <p>{t('Transaction-Summary_T1')}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bottom-content">
                        {/* <div className="inner-text">
                            <p>Source Chain</p>
                            <h6>Ethereum</h6>
                        </div> */}
                        {/* <div className="inner-text">
                            <p>Destination Chain</p>
                            <h6>Ethereum</h6>
                        </div> */}
                        {/* <div className="inner-text">
                            <p>Bridge</p>
                            <h6>No Bridge</h6>
                        </div> */}
                        <div className="inner-text">
                            <p>{t('S1')}</p>
                            <h6>{amount ? parseFloat(amount).toFixed(4) : ''} {selectedItemAsset}</h6>
                        </div>
                        {copied1 &&
                            <p className="text-error">*{t('Invalid-Amount')}</p>
                        }
                        <div className="inner-text">
                            <p>{t('S2')}</p>
                            <h6>{walletAddres}</h6>
                        </div>
                        {copied &&
                            <p className="text-error">*{t('Invalid-Address')}</p>

                        }

                        <div className="inner-text">
                            <p>{t('S3')}</p>
                            <h6>$ 3.000</h6>
                        </div>
                    </div>
                    <div className="last-text">
                        <h6>{t('D7')}</h6>
                        <h5>{amount ? parseFloat(amount).toFixed(4) : ''} {selectedItemAsset}</h5>
                    </div>
                    {/* <p className="text-error">*Invalid Address</p> */}
                    <div className="twice-btn">
                        <button id="speicalAZ255" onClick={handleClose2} className='btn-cancel'>{t('Cancel')}</button>
                        <button id="speicalAZ256"  onClick={switchChain} className='btn-confirm'>{t('Confirm')}</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* transaction progress modal here..................... */}
            {/* <Modal className='summary-modal' show={show3} onHide={handleClose3} centered>
                <Modal.Header >
                    <Modal.Title>
                        <h6>Transaction in Progress</h6>
                        <p>Please wait few minutes for your transaction to be confirmed. <img src="\Assets\button-svgs\pending-icon.svg" alt="img" className='img-fluid ms-2 white' /><img src="\Assets\button-svgs\pending-iconlight.svg" alt="img" className='img-fluid ms-2 dark' /></p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="progress-content">
                        <h6><img src="\Assets\button-svgs\tick-circle.svg" alt="img" className='img-fluid me-3' />Submit asset approving transaction</h6>
                        <h6><img src="\Assets\button-svgs\tick-circle.svg" alt="img" className='img-fluid me-3' />Confirm assets approving transaction</h6>
                        <h6><img src="\Assets\button-svgs\tick-circle.svg" alt="img" className='img-fluid me-3' />Submit deposit source chain</h6>
                        <h6><img src="\Assets\button-svgs\tick-circle.svg" alt="img" className='img-fluid me-3' />Confirm deposit on source chain.</h6>
                    </div>
                    <div className="bottom-text">
                        <div className="inner-side">
                            <p>Deposit Transaction</p>
                            <h6>{walletAddres}</h6>
                        </div>
                        <div className="inner-side">
                            <p>Asset Approve Transaction</p>
                            <h6>{parseFloat(amount).toFixed(4)}</h6>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}

            {/* transaction summary modal here..................... */}
            <Modal className={languageselected == "ar" ? "success-modal direction-for-arabic" : "success-modal"} show={show4} onHide={handleClose4} centered>
                <Modal.Body>
                    <div className="success-div">
                        <img src="\Assets\checkmark.svg" alt="img" className='img-fluid dark' />
                        <img src="\Assets\checkmarklight.svg" alt="img" className='img-fluid white' />
                        <h6>Deposit Sent successfully</h6>
                        {/* <p>Check transaction history for more information.</p> */}
                    </div>
                    <div className="twice-btn">
                        <button id="speicalAZ257" onClick={handleClose4} className='btn-cancel'>Close</button>
                        <button id="speicalAZ258" className='btn-confirm'>transaction History</button>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal className={languageselected == "ar" ? "successsssss-modal direction-for-arabic" : "successsssss-modal"} show={show3} onHide={handleClose3} centered>
                <Modal.Body>
                    <div className="inner-content">
                        <img src="\Assets\checkmark.svg" alt="img" className='img-fluid dark' />
                        <img src="\Assets\checkmarklight.svg" alt="img" className='img-fluid white' />
                        <h6 className='main-head'>{t('Successfully-Transferred')}</h6>
                        <div className="inline-text">
                            <div className="tpmodalmainimg">
                                <img src={selectedItemimg} alt="img" className='img-fluid tpmodalinnerimg' />
                                <div className="tpmainedimg">
                                    <img src="\Assets\import-assets\tpUSDT.png" alt="innermainedimg" className="innermainedimg" />
                                </div>
                            </div>
                            <h4>{amount ? parseFloat(amount).toFixed(4) : "0.0000"} <h6>{selectedItemAsset}</h6></h4>
                        </div>
                        <p className='para'>{t('Transaction-Summary_T3')}.</p>
                    </div>
                    <div className="twice-btns" style={{ gap: '20px' }}>
                        <button id="speicalAZ259" onClick={() => {
                            if (sendStatus == true) {
                                // handleClose3()
                                
                                // Btnclick('btnsix');
                                window.location.reload()
                            }
                            else {
                                handleClose3()
                                setShowgift(true)
                            }
                        }} className='btn-closee'>{t('Transaction-Summary_T4')}</button>
                        <button id="speicalAZ260" className='btn-explorer'> <a className='linksd' target="_blank" href={`https://sepolia.etherscan.io/tx/${mainHsh}`}>{t('Transaction-Summary_T5')} </a></button>

                    </div>
                </Modal.Body>

            </Modal>





            <Modal className={languageselected == "ar" ? "important-note giftmodal-scss direction-for-arabic" : "important-note giftmodal-scss"} show={showgift} onHide={handleClosegift} centered backdrop="static" >
                <Modal.Body>
                    <div className="inner-set">
                        <div className="left">
                            <h6>{t('success')}</h6>
                        </div>
                    </div>

                    <div className="bottom-text">
                        <img src="\Assets\gift.svg" alt="img" className='img-fluid dark' />
                        <img src="\Assets\darkgift.svg" alt="img" className='img-fluid white' />
                        <h6>{t('Transaction-Summary_T6')}</h6>
                        <button id="speicalAZ261" className='btn-confirm' onClick={() => {
                            // window.location.reload()
                            // handleClosegift()
                            // setDefaultt('earn')
                            Btnclick('btnsix');
                            // window.location.reload()
                        }}> {t('Transaction-Summary_T7')} </button>
                    </div>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default Transfer
