import React, { useEffect, useState } from 'react'
import Navbar from '../landing/header/navbar1'
import { Link } from 'react-router-dom'
import { ethers } from 'ethers'
import { map } from 'lodash'
import { saveData, getData } from '../../db';
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Navbar1 from '../landing/header/navbar1'
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next'
const SecretRecovery = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const [secertPhas, setSecertPhas] = useState([])
    const [secertPhas1, setSecertPhas1] = useState([])
    const { ethers } = require("ethers");
    const [copied, setCopied] = useState(false);
    const generateMnemonic = () => {
        const wallet = ethers.Wallet.createRandom();
        const mnemonic = wallet.mnemonic.phrase;
        const mnemonicss = mnemonic
        let a = mnemonic.split(' ');
        let b = mnemonicss.split(',');
        //    console.log(a,'a');
        // console.log("mnemonic=====>>>>",wallet);
        setSecertPhas(a)
        setSecertPhas1(b)
        // localStorage.setItem('arry',mnemonic); 
        handleClick(a)
    }
    useEffect(() => {
        generateMnemonic()
        // console.log('sdfsd','data');
        // handleClick()
    }, []);
    const handleClick = async (a) => {
        await saveData('tpl_phars', a);
        localStorage.setItem('phraseStatus', true)
        // const result = await getData('tpl_password');
        // console.log(result,'result'); d
        // setPasword1(result)
        // setData(result);
    };
    const handlerrr = () => {
        // console.log('sdfdfssdfds');
        window?.clipboardData?.setData(secertPhas?.toString())
        navigator?.clipboard?.writeText(secertPhas?.toString())
        toast.success(t('check11'))
    }
    const copiedHandle = () => {
        setCopied(true);
        setTimeout(
            function () {
                setCopied(false);
            }.bind(this),
            1500
        );
    };
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {copied ? t('copied') :  t('copy')}
        </Tooltip>
    );

    const handleClick12 = async () => {
        // await saveData('tpl_password',pasword);
        // const result = await getData('tpl_password');
        // const result1 = await getData('selected_privateKey');
        // const mail = await localStorage.getItem('mail')
        // console.log(mail,'mail');
        // if(!mail){
        //   history.push('/login')
        // }
        // if(result && result1){
        //   history.push('/unlock')
        // }
        // setData(result);
      // };
        // setData(result);
      };
    //   useEffect(() => {
    //     handleClick12()
    //   },[]);
    var languageselected = localStorage.getItem('codelanguage')
    return (
        <>
            <Navbar1 />
            <section className={languageselected == "ar" ? "secret-recovery direction-for-arabic" : "secret-recovery"}>
                <div className="theme-container">
                    <div className="secret-recover-div">
                        <div className="upper-heading">
                            {/* <Link to="/create">  <img src="\Assets\import-assets\arrow-left.svg" alt="img" className='img-fluid' /></Link> */}
                            <h6>{t('Sidebar-Account-H2-T1')}</h6>
                        </div>
                        <div className="bottom-content">
                            <p className="para">
                            {t('Sidebar-Account-H2-T2')}. <br /> <br />
                            {t('Sidebar-Account-H2-T3')}.
                            </p>
                            <div className="phrase-content">
                                {secertPhas.map((e, i) => {
                                    return (
                                        <div className="single-phrase">
                                            <span>{i + 1 + "."}</span>
                                            <p>{e}</p>
                                        </div>
                                    )
                                })

                                }

                            </div>
                            <div className="bottom-para">
                                <p> {t('Sidebar-Account-H2-T4')}!</p>
                            </div>
                            <div className="twice-btn">
                                <button id="speicalAZ221" className='btn-skip'>

                                    {/* <p> */}
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                        className=""
                                    >
                                        <CopyToClipboard
                                            text={secertPhas1}
                                            onCopy={() => copiedHandle()}
                                        >
                                            <p className="copy_data click___One justify-content-center;">
                                              {t('Sidebar-Account-H2-B1')}
                                                {/* <span className="gsvsvcvst"></span> <img src="\Assets\button-svgs\Accountcopypicwhite.svg" alt="" className="logoimggpccro ml-3" /> */}
                                            </p>
                                        </CopyToClipboard>
                                    </OverlayTrigger>
                                    {/* </p>  */}
                                </button>
                                <Link className='w-100' to="/verifyphrases"><button className='btn-verify w-100'>{t('Sidebar-Account-H2-B2')}</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SecretRecovery
