import web3NoAccount from "./web3";
import TplAbi from "./tplAbi.json";
import BusdAbi from "./busdAbi.json";
import MintContract from "./mintAbi.json";
import DopAbi from "./dopAbi.json";
import Router from "./routerabi.json";
import AllMint from "./allMint.json";
import MintDop from "./mintDop.json";
const getContract = (abi, address, web3) => {
  const _web3 = web3 ?? web3NoAccount;
  // console.log('_web3',_web3);s
  return new _web3.eth.Contract(abi, address);
};

export const ptlContract = (address, web3) => {
  return getContract(TplAbi, address, web3);
};

export const usdtContract = (address, web3) => {
  return getContract(BusdAbi, address, web3);
};
export const mintContract = (address, web3) => {
  return getContract(MintContract, address, web3);
};
export const dopContract = (address, web3) => {
  return getContract(DopAbi, address, web3);
};
export const routerContract = (address, web3) => {
  return getContract(Router, address, web3);
};
export const mintAllContract = (address, web3) => {
  return getContract(AllMint, address, web3);
};
export const mintDopAbi= (address, web3) => {
  return getContract(MintDop, address, web3);
};