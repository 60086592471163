import React, { useState ,useEffect} from 'react'
import Navbar from '../landing/header/Navbar'
import { Link } from 'react-router-dom';
import './unlock.scss'
import { saveData, getData } from '../../db';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar1 from '../landing/header/navbar1';
import { useTranslation } from 'react-i18next'
const CreateWallet = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const [mod1,setMod1]=useState(false)
  const [pasword,setPasword]=useState('')
  const [pasword1,setPasword1]=useState('')
  const [copied1, setCopied1] = useState(false);
   // const result = await getData('key');
   useEffect(() => {
    handleClick()
}, []);
// const mail =  localStorage.getItem('mail')


// const [showdisclaimer, setShowdisclaimer] = useState(false);
// const handleClosedisclaimer = () => setShowdisclaimer(false);
// const handleShowdisclaimer = () => setShowdisclaimer(true);
const [changecolor, setchangecolor] = useState(false);

// useEffect(() => {
//   handleShowdisclaimer(true);
// }, []);
const handleClick = async () => {
  // await saveData('tpl_password',pasword);
  const result = await getData('tpl_password');
  
  const result1 = await getData('selected_privateKey');
  // console.log(result1,'result',result);
  if (result&&result1){

    setPasword1(result)
  }else{
    history.push('/')
  }
  // setData(result);
};
const hide1=()=>{
  // console.log();
  if(mod1){
    setMod1(false)
  }
  else{
    setMod1(true)
  }
}
const handler=(e)=>{
  // console.log(pasword,pasword1,typeof pasword,typeof pasword1);
  setPasword(e.target.value)
}

const submit=(e)=>{
  e?.preventDefault()
  if(pasword===pasword1){
    // toast.success("Password is varified Successfully")
    history.push('/dashboard')
  }else
  {
    setCopied1(true)
    setTimeout(() => {
        setCopied1(false)
    }, 2000)
  }
}
var languageselected = localStorage.getItem('codelanguage')
  return (
    <>
      <Navbar1 />
      <section className={languageselected == "ar" ? "unlockwallet direction-for-arabic" : "unlockwallet"}>
        <div className="theme-container">
          <div className="innerimport">
            <div className="importheader">
              <p className="importhead">{t('route-unlock-H1')} </p>
            </div>
            <form onSubmit={(e)=>submit(e)} className="w-100">
            <div className="importbody">
              <p className="para">{t('route-unlock-T1')}.</p>
              <div className="material-textfield">
                <input value={pasword} onChange={(e)=>handler(e)} placeholder="********"type={mod1?"text":"password"} />
                <label>{t('route-unlock-T2')}</label>
                <img onClick={hide1} src="\Assets\import-assets\eye-dark.svg" alt="eye" className='eye dark' />
                <img onClick={hide1} src="\Assets\import-assets\eye-white.svg" alt="eye" className='eye white' />
              </div>{copied1&&
  <p className="text-error">*{t('Invalid-Password')}</p>}
              <Link to='/reset'>
                <p className='common-clr forgot-text'>{t('route-unlock-T3')}?</p>
              </Link>
              {/* <Link to="/dashboard"> */}
              <button id="speicalAZ225" type='submit' className="common-btntwo">{t('route-unlock-B1')}</button>
              {/* </Link> */}
            </div>
            </form>
          </div>
        </div>
      </section>


      {/* <Modal className="modal-showstart" show={showdisclaimer} onHide={handleClosedisclaimer} centered  backdrop="static"
        keyboard={false}>
        <Modal.Header>
          <Modal.Title>
            <img src="\Assets\disclaimer.svg" alt="disclaimerimg" className="disclaimerimg" />
            Important Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mainmodalstartpara">
            <p className="mainmodalinnerpara">
              We want to take a moment to inform you that the software you're about to use is currently in its <span>Alpha Phase</span>. This means that it is still in the early stages of development.
            </p>
            <p className="mainmodalinnerpara">
              While our dedicated team is working tirelessly to improve its features and performance, some functionalities may not work as intended or may be under continuous modification. There might be occasional bugs or instability which we are actively seeking to rectify.
            </p>
            <p className="mainmodalinnerpara">
              By continuing, you acknowledge and accept that any consequences resulting from the use of this software will be solely your responsibility. We encourage you to report any issues you encounter during usage to aid our development process.
            </p>
            <p className="mainmodalinnerpara">
              Thank you for your understanding and patience. Your feedback is valuable to us and greatly appreciated.
            </p>
          </div>
          <div class="form-check radiocheck-btnn">
            <input onClick={()=>{
              setchangecolor(!changecolor);
            }} class="form-check-input" type="checkbox" id="flexCheckDefault" />
            <label className="form-check-label importhead" for="flexCheckDefault">
            I understand that the use is my responsibility
            </label>
          </div>
          <button onClick={changecolor ? handleClosedisclaimer : null} className={changecolor ? 'continuebutton' : 'disable-btn disabled' }>
            continue
          </button>
        </Modal.Body>
      </Modal> */}
    </>

  )
}

export default CreateWallet
