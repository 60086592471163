import React from 'react'
import "./account.scss"

const ImportAccount = () => {
  var languageselected = localStorage.getItem('codelanguage')
  return (
    <>
      <section className={languageselected == "ar" ? "createaccount direction-for-arabic" : "createaccount"}>
            <div className="upper-heading">
                <h6>Import Account</h6>
            </div>
            <div className="bottom-content">
                <div className="secret-key">
                    <p>Secret Key</p>
                    <textarea placeholder='Please paste secret key'></textarea>
                </div>
            <div className="material-textfield">
                <input placeholder="Please input an account name" type="text" />
                <label>Account name</label>
              </div>
              <button className='btn-submit'>Submit</button>
            </div>
      </section>
    </>
  )
}

export default ImportAccount
