import React from 'react'
import "./account.scss"

const CreateAccount = () => {
  var languageselected = localStorage.getItem('codelanguage')
  return (
    <>
      <section className={languageselected == "ar" ? "createaccount direction-for-arabic" : "createaccount"}>
            <div className="upper-heading">
                <h6>Create Account</h6>
            </div>
            <div className="bottom-content">
            <div className="material-textfield">
                <input placeholder="Please input an account name" type="text" />
                <label>Account name</label>
              </div>
              <button className='btn-submit'>Submit</button>
            </div>
      </section>
    </>
  )
}

export default CreateAccount
