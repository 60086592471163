import React, { useEffect, useState } from 'react'
import './importwallet.scss'
import Navbar from '../landing/header/navbar1'
import { Link } from 'react-router-dom';
import { saveData, getData } from '../../db';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useWeb3 from "../../hooks/useWeb3";
import Navbar1 from '../landing/header/navbar1';
import { useWeb3React } from "@web3-react/core";
import { useTranslation } from 'react-i18next'
const { ethers } = require("ethers");

const Importwallet = () => {
  const { account } = useWeb3React();
  const { t } = useTranslation()
  const web3 = useWeb3();
  const [secretPhase,setSecretPhase]=useState(null)
  const history = useHistory()
  const [pasword,setPasword]=useState('')
  const [pasword1,setPasword1]=useState('')
  const [mod,setMod]=useState(false)
  const [mod1,setMod1]=useState(false)
  const [chekked,setChekked]=useState(false)
  const [chekked1,setChekked1]=useState(false)
  const [hideData,setHideData]=useState(null)
  const [mod11,setMod11]=useState(false)
  const [copied, setCopied] = useState(false);
  const [copied1, setCopied1] = useState(false);
  const [copied2, setCopied2] = useState(false);
  const hide=()=>{
    // console.log('dsfds',mod);
    if(mod){
      setMod(false)
    }
    else{
      setMod(true)
    }
  }
  const hide1=()=>{
    // console.log();
    if(mod1){
      setMod1(false)
    }
    else{
      setMod1(true)
    }
  }
  const handler=(e)=>{
    // console.log('pass',e.target.value);
    setPasword(e.target.value)
  }
  const handler1=(e)=>{
    setPasword1(e.target.value) 
    // console.log('confirm',e.target.value);
  }
  const handler2=()=>{
    if(chekked){
      setChekked(false)
    }
    else{
      setChekked(true)
    }
  }
  const handler3=(e)=>{
    var length = e.target.value.length;
    // console.log('agaya', e.target.value,length );
    // console.log('pass',length);
    setSecretPhase(e.target.value)
    setChekked1(false)
if(length<=66){
  try{
    const newwallet = web3.eth.accounts.privateKeyToAccount(e.target.value);
    // console.log(newwallet,'newwallet');
if(newwallet){
  handleClick1(newwallet)
    setChekked1(true)
}else{
  setChekked1(false)
  // handleClick1(newwallet)
}
    
    // history.push('/unlock')
  }
  catch(error){
    setChekked1(false)
    // toast.error("Private key is incorrect")
  }
}else if (length>66){
 
  try{
    const newwallet = ethers.Wallet.fromMnemonic((e.target.value).toString())
    // console.log(newwallet,'newwallet');
if(newwallet){
  handleClick1(newwallet)
    setChekked1(true)
}else{
  setChekked1(false)
  // handleClick1(newwallet)
}
    
    // history.push('/unlock')
  }
  catch(error){
    console.log(error);
    setChekked1(false)
    // toast.error("Private key is incorrect")
  }

}
  }



  const submit=(e)=>{
    e?.preventDefault()
    // console.log('click me ');
    if(!hideData){
    if(pasword!=''&&pasword1!=''){
      if (pasword===pasword1){
      // toast.success("Password is Created Successfully")
      // handleClick()
      // history.push('/secretrecoveryphrase')
    if(chekked1){
      handleClick()
      history.push('/unlock')
      // toast.success("Account is import")

    }else{
    
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 2000)
    }
  }else{
    setCopied2(true)
    setTimeout(() => {
        setCopied2(false)
    }, 2000)
  }

    }else
    {
      
        setCopied1(true)
        setTimeout(() => {
            setCopied1(false)
        }, 2000)
    
    }
  }else{
    if(chekked1){
      // handleClick()
      history.push('/unlock')
      // toast.success("Account is import")

    }else{
     
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 2000)
    }
    
  }
  }
  const handleClick = async () => {
    await saveData('tpl_password',pasword);
    // setData(result);
  };
  const handleClick1 = async (a) => {
    const result1 = await getData('tpl_data');
    const result12 = await getData('selected_privateKey');
    if(result1&&result1){
      const newwallet = web3.eth.accounts.privateKeyToAccount(result12);
      let dumObj=result1.find((i)=>i.account_address==a.address)
      // console.log(dumObj,'dumObj',result12,result1);
      
  if(!dumObj){
    setMod11(true)
     if (!result1){
      await saveData('tpl_data',[{
        private_key:a.privateKey,
        account_address:a.address,
        depoite:[],
        transfer:[],
        withDraw:[],
        }]);
    }else
    {
      result1.push(
        {
          private_key:a.privateKey,
        account_address:a.address,
          depoite:[],
          transfer:[],
          withDraw:[],
          }
      )
      await saveData('tpl_data',result1);
    }
      handleClick2(a)
  }else{
    setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 2000)
    setChekked1(false)
  }
    }else{
      setMod11(true)
      await saveData('tpl_data',[{
        private_key:a.privateKey,
        account_address:a.address,
        depoite:[],
        transfer:[],
        withDraw:[],
        }]);
    }
    handleClick2(a)
   
    // setData(result);
  };
  const handleClick2 = async (a) => {
    await saveData('selected_privateKey',a.privateKey);
    // setData(result);
  };
  useEffect(()=>{
    passwordHandler()
  },[])
const passwordHandler=async()=>{
  const result1 = await getData('tpl_password');
  if(result1){
    setHideData(result1)
  }
}
const handleClick12 = async () => {
  // await saveData('tpl_password',pasword);
  // const result = await getData('tpl_password');
  // const result1 = await getData('selected_privateKey');
  // const mail = await localStorage.getItem('mail')
  // console.log(mail,'mail');
  // if(!mail){
    // history.push('/login')
  // }
  // if(result && result1){
  //   history.push('/unlock')
  // }
  // setData(result);
// };
  // setData(result);
};
// useEffect(() => {
//   handleClick12()
// },[account]);
var languageselected = localStorage.getItem('codelanguage')
  return (
    <>
      <Navbar1 />
      <section className={languageselected == "ar" ? "importwallet direction-for-arabic" : "importwallet"}>
        <div className="theme-container">
          <div className="innerimport">
            <div className="importheader">
              <Link to="/">
                <img src="\Assets\import-assets\arrow-left.svg" alt="backarrow" className="backarrow" />
              </Link>
              <p className="importhead">{t('Sidebar-Account-H3-T1')}</p>
            </div>
            <form onSubmit={(e)=>submit(e)}className="w-100" >
            <div className="importbody">
              <p className="importpara">{t('Sidebar-Account-H3-T2')}. </p>
              <div className="material-textfield">
                <input value={secretPhase} onChange={(e)=>handler3(e)} placeholder={t('z3')} type={chekked?"text":"password"} />
                <label>{t('Sidebar-Account-H3-T3')}</label>
                {/* <img src="\Assets\import-assets\clipboard-text-dark.svg" alt="clip" className='clip dark' />
                <img src="\Assets\import-assets\clipboard-text-white.svg" alt="clip" className='clip white' /> */}
              </div>
              {copied&&
              <p className="text-error">*{t('Invalid-Key')}</p>
}
      
              <div class="form-check radiocheck-btnn">
                <input value={chekked} onChange={(e)=>handler2(e)}  class="form-check-input" type="checkbox" id="flexCheckDefault" />
                <label className="form-check-label importhead" for="flexCheckDefault">
                {t('Sidebar-Account-H3-T4')}
                </label>
              </div>
 
{!hideData&&
<>
  <div className="material-textfield">
  <input placeholder="Enter new password"value={pasword} onChange={(e)=>handler(e)} type={mod?"text":"password"} />
  <label>{t('route-import-T2')}</label>
  <img onClick={hide} src="\Assets\import-assets\eye-dark.svg" alt="eye" className='eye dark' />
  <img onClick={hide} src="\Assets\import-assets\eye-white.svg" alt="eye" className='eye white' />


</div>
<div className="material-textfield">
  <input placeholder="Confirm new password"value={pasword1} onChange={(e)=>handler1(e)}  type={mod1?"text":"password"}/>
  <label>{t('route-reset-T3')}</label>
  <img onClick={hide1}  src="\Assets\import-assets\eye-dark.svg" alt="eye" className='eye dark' />
  <img onClick={hide1}  src="\Assets\import-assets\eye-white.svg" alt="eye" className='eye white' />
</div>
{copied1&&
  <p className="text-error">*{t('Invalid-Password')}</p>}
    {copied2&&
  <p className="text-error">*{t('password-match')}</p>}
</>
}
            
              <button id="speicalAZ218" type='submit' className="common-btntwo">{t('Sidebar-Account-H3-B1')}</button>
            </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default Importwallet
