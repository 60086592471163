import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../../../hooks/useWeb3";
import { toast } from 'react-toastify';
import DepositeTpl from "../../../hooks/dataFetchers/DepositeTpl";
import Signature1 from "../../../hooks/dataSenders/userSign1"
import { saveData, getData } from '../../../db';
import AllowanceTpl from "../../../hooks/dataFetchers/AllowanceTpl";
import ApproveTpl from "../../../hooks/dataFetchers/ApproveTpl";
import BalacefAccount from "../../../hooks/dataFetchers/BalaceOfAccount";
import { io } from "socket.io-client";
import Loader from '../../../hooks/loader';
import { push } from '../../../state/toasts';
import axios from 'axios';
import { API_URL } from "../../../utils/ApiUrl";
import Environment from '../../../utils/Environment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { setupNetwork } from '../../../utils/wallet';
const Deposit = ({ internalAount, userBalance, setSelectedItemAsset, selectedItemAsset, stateusCheck, defalttt }) => {
    const { t } = useTranslation()
    const localData = localStorage.getItem('connectorId');
    // const localData1 = localStorage.getItem('deposit');
    let deposite = useSelector((state) => state?.UserReducer?.deposite)
    // console.log(deposite,'deposite');
    const localData2 = localStorage.getItem('idd');
    let sign121 = localStorage.getItem('sign');
    // console.log(internalAount, 'localData');
    // const result=''
    const [history, setHistory] = useState([])
    const { approveTpl } = ApproveTpl();
    const { allowanceTpl } = AllowanceTpl();
    const { balacefAccount } = BalacefAccount()
    const { userSign } = Signature1()
    const { depositeTpl } = DepositeTpl();
    const proofComponent = 'proof';
    const resultComponent = 'result';
    const bGenProof = "bGenProof";
    const local121 = localStorage.getItem('sign');
    const [copied1, setCopied1] = useState(false);
    const [mainHsh, setHashh] = useState(null)
    const calculateProof = async () => {


        const { proof, publicSignals } =
            proofComponent.innerHTML = JSON.stringify(proof, null, 1);
        const vkey = await fetch("verification_key.json").then(function (res) {
            return res.json();
        });
    }
    // useEffect(())
    let typeParamId = ''
    const { account,chainId } = useWeb3React();
    const web3 = useWeb3();
    const [allowance, setAllowance] = useState(null)
    const items = [internalAount]
    // console.log(allowance, 'allowance');
    const [selectedItem, setSelectedItem] = useState(items);
    const [loaderr, setLoarder] = useState(false)

    // const [selectedItemAsset, setSelectedItemAsset] = useState("");
    const [selectedItemNumber, setSelectedItemNumber] = useState("");
    const [selectedItemimg, setSelectedItemimg] = useState("");
    const [selectedItemFrom, setSelectedItemFrom] = useState("");
    const [selectedItemto, setSelectedItemto] = useState("");
    const [selectedIndex, setSelectedIndex] = useState("");
    const [selectedAccount, setSelectedAccount] = useState("");
    const [itemsAssetsOrignal, setItemsAssetsOrignal] = useState("");
    const [itemsAssets, setItemsAssets] = useState(
        [
            // { igm: "/Assets/tpldashboard/myprivateassets/imgone.svg", name: "Sepolia ETH",indecBy: "0", address: Environment?.usdt },
            { igm: "/Assets/tpldashboard/myprivateassets/imgtwo.svg", name: "DOP_USDT", indecBy: "21", address: Environment?.usdt },
            { igm: "/Assets/tpldashboard/myprivateassets/imgthree.svg", name: "DOP_USDC", indecBy: "22", address: Environment?.USDC },
            { igm: "/Assets/tpldashboard/myprivateassets/imgfour.svg", name: "DOP_SHIB", indecBy: "23", address: Environment?.SHIB },
            { igm: "/Assets/tpldashboard/myprivateassets/imgfive.svg", name: "DOP_LINK", indecBy: "24", address: Environment?.LINK },
            { igm: "/Assets/tpldashboard/myprivateassets/imgsix.svg", name: "DOP_UNI", indecBy: "25", address: Environment?.UNI },
            { igm: "/Assets/tpldashboard/myprivateassets/imgseven.svg", name: "DOP_APE", indecBy: "26", address: Environment?.APE },
            { igm: "/Assets/tpldashboard/myprivateassets/imgeight.svg", name: "DOP_PEPE", indecBy: "27", address: Environment?.PEPE },
            { igm: "/Assets/tpldashboard/myprivateassets/imgnine.svg", name: "DOP_CHZ", indecBy: "28", address: Environment?.CHZ },
            { igm: "/Assets/tpldashboard/myprivateassets/imgten.svg", name: "DOP_COMP", indecBy: "29", address: Environment?.COMP },
            { igm: "/Assets/tpldashboard/myprivateassets/imgeleven.svg", name: "DOP_TOMI", indecBy: "30", address: Environment?.TOMI }
        ])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [balancee, setBalancee] = useState(null)
    const [show0, setShow0] = useState(false);
    const handleClose0 = () => setShow0(false);
    const handleShow0 = () => setShow0(true);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show33, setShow33] = useState(false);
    const handleClose33 = () => setShow33(false);
    const handleShow33 = () => setShow33(true);

    const [showgift, setShowgift] = useState(false);
    const handleClosegift = () => setShowgift(false);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => {
        // setSelectedItem('')
        setShow3(false)
        setAmount('')
        setSelectedIndex('')
        setSelectedItemAsset('')
        setSelectedItemNumber('')
        setSelectedItemimg('')
        setBalancee('')
    };
    const handleShow3 = () => setShow3(true);

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);
    const [amount, setAmount] = useState(null)
    const balaceOf = async () => {
        // var balance = await web3.eth.getBalance(account);
        let balace = await balacefAccount(account)
        // console.log(balace,'balace');
        if (balace) {

            // setBalancee(balace)
        }
    }
    useEffect(() => {
        // handleClick()
        if (account && web3) {
            balaceOf()
            balaceOfAlData()
            //   setSelectedItem(items)
            // account, userBalance, stateusCheck
        }
    }, [web3]);
    useEffect(() => {
        // handleClick()
        // if (account){
        //     balaceOf()
        setSelectedItem(internalAount)
        // }
    }, [internalAount]);
    // console.log(balancee,'account');
    const amountHamder = (e) => {
        // console.log(e.target.value);
        let a = parseFloat(e.target.value)
        // console.log( a,'as');
        if (a > balancee) {
            // console.log('sddsf');
            setCopied1(true)
            setTimeout(() => {
                setCopied1(false)
            }, 2000)
            setAmount('')
        }
        else {
            setAmount(a)
        }

    }
    const confirm = async () => {
        setLoarder(true)
        // console.log(local121,'local121');
        if (account) {
            if (amount) {
                if (selectedItem) {
                    let result = await getData('selected_privateKey');
                    let dataHistory = await getData('tpl_data');

                    let dumObj = dataHistory.find((i) => i.private_key == result)
                    let newwallet = web3.eth.accounts.privateKeyToAccount(result);
                    let newwallet1 = newwallet?.address
                    let now = Date.now();
                    let num = Math.floor((Math.random() * 1000000) + 1);
                    // console.log(result,num,now,account,amount,'result,num,now,account,amount');
                    const res1 = await userSign(result, num, now, amount, selectedIndex);
                    if (res1) {
                        // console.log(res1,'res1');
                        let res12 = res1.substring(2)
                        // console.log(res12,'res12');
                        let r = '0x' + res12.slice(0, 64);
                        let s = '0x' + res12.slice(64, 128);
                        let v = '0x' + res12.slice(128, 130);
                        // console.log(res1,'res1');
                        try {
                            // console.log(amount, newwallet1, now, num, v, r, s,selectedIndex,);
                            const res = await depositeTpl(amount, newwallet1, now, num, v, r, s, selectedIndex);
                            if (res) {
                                handleClose2();
                                handleShow3();
                                setHashh(res?.transactionHash)
                                const now = Date.now();
                                let historyObj = {
                                    amount: amount,
                                    date: now,
                                    asets: selectedItemAsset,
                                    hash: res?.transactionHash,

                                }
                                dumObj.depoite.push(historyObj);
                                let index = dataHistory.indexOf(dumObj.private_key);
                                if (index > -1) {
                                    historyObj = historyObj.splice(index, 1, dumObj)
                                }
                                await saveData('tpl_data', dataHistory);
                                // const socket = io("wss://socket.tdns.network", {
                                //     reconnectionDelayMax: 1000,
                                // });
                                // socket.on('connect', function () {
                                // });
                                // typeParamId = 'emited'
                                // socket.emit("bid", typeParamId);
                                // if(!localData1){
                                 let a= await api()
                                 if(a){
                                apiCall(a)
                                 }

                                // }
                            }

                        } catch (e) {
                            setLoarder(false)
                            console.log(e);
                            alowence(selectedAccount, selectedIndex)
                            // toast.error('The Sepolia netwrok appears to be busy. Try again later')
                            // toast.error(e.message);
                            // console.log(e, "e");
                            // setMainLoader(false);
                        }
                    }

                } else {
                    // toast.error('please select internal account')
                    setLoarder(false)

                }

            } else {
                setCopied1(true)
                setTimeout(() => {
                    setCopied1(false)
                }, 2000)
                setAmount('')
                setLoarder(false)
            }

        } else {
            // toast.error('please connect metaMask')
            setLoarder(false)
        }

    }
    const alowence = async (e, c) => {
        // console.log(e,c,'e,c');
        try {
            // console.log(e);
            let r = await allowanceTpl(e)
            // console.log(e,c,'selectedIndex');
            if (c == 21 || c == 22) {

                setAllowance(r / 10 ** 6)
                // console.log(r/10**6,'noor if');
            } else {
                setAllowance(r / 10 ** 18)
                // console.log(r/10**18,'noor else');
            }

        }
        catch (err) {
            console.log(err, 'noor');
        }
    }
    const switchChain=async()=>{
        if(chainId===11155111) 
        {
          approveHandler()
        }
        else{
         let a = await setupNetwork()
         if (a){
          approveHandler()
         }
        } 
    }
    const approveHandler = async () => {
        setLoarder(true)
        // console.log('approveHandler');
        if (account) {
            if (amount) {
                if (selectedItem) {
                    if (allowance === 0) {
                        // console.log('if');
                        setLoarder(true)
                        try {
                            // console.log(10000000, account, selectedAccount, selectedIndex);
                            const res111 = await approveTpl(10000000, account, selectedAccount, selectedIndex);

                            //   }else{
                            //   }
                            // console.log(res111, 'noor');
                            if (res111) {
                                setLoarder(false)
                                // handleClose2();
                                // handleShow3();
                                alowence(selectedAccount, selectedIndex)
                                confirm()
                                // setTimeout(() => {
                                //    window.location.reload()
                                //   }, "3000");
                            }
                        }
                        catch (err) {
                            // console.log(err, 'noor');
                            // toast.error(err.message)
                            // toast.error('The Sepolia netwrok appears to be busy. Try again later')
                            alowence(selectedAccount, selectedIndex)
                            setLoarder(false)
                        }
                    }
                    else {
                        if (amount >= allowance) {
                            try {
                                let res = ''
                                if (selectedIndex == 21) {
                                    res = await approveTpl(0, account, selectedAccount, selectedIndex);
                                    let res11 = null
                                    if (res) {
                                        res11 = await approveTpl(10000000, account, selectedAccount, selectedIndex);
                                    }
                                    //   }else{
                                    //   }
                                    // console.log(res,'noor');
                                    if (res11) {
                                        //  setLoarder(false)
                                        // handleClose2();
                                        // handleShow3();
                                        alowence(selectedAccount, selectedIndex)
                                        confirm()
                                        // setTimeout(() => {
                                        //    window.location.reload()
                                        //   }, "3000");
                                    }
                                }
                                else {
                                    let res111 = await approveTpl(10000000, account, selectedAccount, selectedIndex);


                                    //   }else{
                                    //   }
                                    // console.log(res,'noor');
                                    if (res111) {
                                        // setLoarder(false)
                                        // handleClose2();
                                        // handleShow3();
                                        alowence(selectedAccount, selectedIndex)
                                        confirm()
                                        // setTimeout(() => {
                                        //    window.location.reload()
                                        //   }, "3000");
                                    }
                                }

                            } catch (err) {
                                alowence(selectedAccount, selectedIndex)
                                console.log(err);
                                setLoarder(false)
                                // toast.error('The Sepolia netwrok appears to be busy. Try again later')

                            }

                        }
                        else {
                            // console.log('else ');
                            confirm()
                            // setLoarder(false)

                        }

                    }
                } else {
                    // toast.error('please select internal account')
                    setLoarder(false)
                }
            } else {
                setCopied1(true)
                setTimeout(() => {
                    setCopied1(false)
                }, 2000)
                setAmount('')
                setLoarder(false)
            }
        } else {
            // toast.error('please connect metaMask')
            setLoarder(false)
        }
    }
    // useEffect(() => {
    //     alowence()

    // }, [account]);

    const handleClick1 = async (a) => {

        // handleClick2(a)
        // setData(result);
    };
    const withdraHandler = (e) => {
        e?.preventDefault()
        if (amount) {
            handleShow2()
        } else {
            setCopied1(true)
            setTimeout(() => {
                setCopied1(false)
            }, 2000)
        }




    }
    const balaceOfAlData = async () => {

        // let tempLockedLending2 = { ...tempLLend, amountt: a1 };
        let dummy = []
        for (let a = 0; a < itemsAssets?.length; a++) {
            // console.log(a,'else');
            let tempLLend = itemsAssets[a];
            let tempLockedLending2 = ''
            // console.log(tempLLend,tempLockedLending2,'else');
            if (itemsAssets[a]?.indecBy == 0) {
                var balance = await web3.eth.getBalance(account);
                let b = (balance / 10 ** 18)
                // console.log(b,'eth balance');
                tempLockedLending2 = { ...tempLLend, amountt: b };
                dummy.push(tempLockedLending2)
            } else {
                // console.log('else');
                try {

                    let baa = await balacefAccount(itemsAssets[a].address)
                    // console.log(itemsAssets[a]?.indecBy,baa,'nooor');
                    if (itemsAssets[a]?.indecBy == 21 || itemsAssets[a]?.indecBy == 22) {
                        baa = baa / 10 ** 6
                    } else {
                        baa = baa / 10 ** 18

                    }
                    // console.log(baa,'>>>>>>>>',itemsAssets[a].address);
                    tempLockedLending2 = { ...tempLLend, amountt: baa };
                    // console.log('other balance',tempLockedLending2);
                    dummy.push(tempLockedLending2)
                } catch (err) {
                    console.log(err, 'errnooor');
                    setLoarder(false)
                    tempLockedLending2 = { ...tempLLend, amountt: '' };
                    dummy.push(tempLockedLending2)
                }
            }

            // console.log(dummy,'nooooor');
            dummy.sort((a, b) => {
                // console.log(a,b,'sorting');
                return b?.amountt - a?.amountt
            })
            setItemsAssets(dummy)
            setItemsAssetsOrignal(dummy)

            // console.log(dummy,'dummy');
        }
        // console.log(allowance,'allowance');
    }
    // const balaceOfAlData1 = async () => {

    //     // let tempLockedLending2 = { ...tempLLend, amountt: a1 };
    //     let dummy = []
    //     for (let a = 0; a < 11; a++) {
    //         // console.log(a,'else');
    //         let tempLLend = orign[a];
    //         let tempLockedLending2 = ''
    //         // console.log(tempLLend,tempLockedLending2,'else');
    //         if (a == 0) {
    //             var balance = await web3.eth.getBalance(account);
    //             let b = (balance / 10 ** 18)
    //             // console.log(b,'eth balance');
    //             tempLockedLending2 = { ...tempLLend, amountt: b };
    //             dummy.push(tempLockedLending2)
    //         } else {
    //             // console.log('else');
    //             try {

    //                 let baa = await balacefAccount(itemsAssets[a].address)

    //                 if (a == 1 || a == 2) {
    //                     baa = baa / 10 ** 6
    //                 } else {
    //                     baa = baa / 10 ** 18

    //                 }
    //                 // console.log(baa,'>>>>>>>>',itemsAssets[a].address);
    //                 tempLockedLending2 = { ...tempLLend, amountt: baa };
    //                 // console.log('other balance',tempLockedLending2);
    //                 dummy.push(tempLockedLending2)



    //             } catch (err) {
    //                 console.log(err, 'err');
    //                 setLoarder(false)
    //                 tempLockedLending2 = { ...tempLLend, amountt: '' };
    //                 dummy.push(tempLockedLending2)
    //             }
    //         }
    //         // console.log(dummy,'nooooor');
    //         dummy.sort((a,b)=>{
    //             // console.log(a,b,'sorting');
    //              return b?.amountt-a?.amountt
    //               })
    //         setItemsAssets(dummy)
    //         setItemsAssetsOrignal(dummy)

    //     }
    //     // console.log(allowance,'allowance');
    // }
    const handlerForSelect = (e, i) => {
        // console.log(i,e);
        setSelectedIndex(e.indecBy)
        setSelectedItemAsset(e.name)
        setSelectedItemNumber(e.amountt?.toFixed(3))
        setSelectedItemimg(e.igm)
        setBalancee(e.amountt?.toFixed(3))
        handleClose1()
        setAmount('')
        setSelectedAccount(e?.address)
        setTimeout(() => {
            setItemsAssets(itemsAssetsOrignal)
        }, 1000);

        if (e.indecBy == 0) {
            setAllowance(null)
        } else {
            alowence(e?.address, e.indecBy)
        }
    }




    const switchChain1=async()=>{
        if(chainId===11155111) 
        {
          approveHandler1()
        }
        else{
         let a = await setupNetwork()
         if (a){
          approveHandler1()
         }
        } 
    }


    const approveHandler1 = async () => {
        // console.log('agaya');
        if (account) {
            if (amount) {
                if (selectedItem) {
                    confirm()
                } else {
                    // toast.error('please select internal account')
                    setLoarder(false)
                }
            } else {
                setCopied1(true)
                setTimeout(() => {
                    setCopied1(false)
                }, 2000)
                setAmount('')
                setLoarder(false)
            }
        } else {
            // toast.error('please connect metaMask')
            setLoarder(false)
        }



    }
    const amountHamderSearch = (e) => {
        let qw = (e.target.value)
        qw = qw?.toUpperCase()
        // console.log(qw);
        // let result = text.includes("world");
        // console.log(qw);
        let dummy = []
        for (let a = 0; a < 11; a++) {
            // console.log(itemsAssetsOrignal[a]?.name?.includes(qw));
            if (qw != '') {
                if (itemsAssetsOrignal[a]?.name?.includes(qw)) {
                    // console.log('if',itemsAssets[a]?.name);
                    dummy.push(itemsAssetsOrignal[a])
                }
                //  else if (qw==''){

                //     dummy.push(itemsAssetsOrignal[a])

                // }
            } else {
                dummy.push(itemsAssetsOrignal[a])
            }
            // console.log(dummy,'dummy');
            setItemsAssets(dummy)
        }
    }







    const apiCall = async (e) => {
        const axios = require('axios');
        let data = JSON.stringify({
            "encrypt_Assets":{
                "isCompleted":true
              }
              ,
            "sign":sign121,
            "internalWalletAddress":internalAount,
            "externalWalletAddress":account
        });

        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${API_URL}rewards/${e}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                setLoarder(false)
                //   console.log(JSON.stringify(response.data));
                localStorage.setItem('deposit', true)
            })
            .catch((error) => {
                setLoarder(false)
                console.log(error);
                // toast.error(error.response.data.message);
            });

    }
    useEffect(() => {
        handleClose3()
    }, [defalttt])

    const [clickedButton, setClickedButton] = useState(localStorage.getItem('clickedButton') || null);
    useEffect(() => {
        if (clickedButton === 'btnfive') {
            const element = document.querySelector('.mainsix');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [clickedButton]);
    const Btnclick = (buttonName) => {
        setClickedButton(buttonName);
        localStorage.setItem('clickedButton', buttonName); // Save the clicked button to local storage
        window.location.reload();
    };



    const api = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${API_URL}rewards/walletaddress?internalWalletAddress=${internalAount}&&externalWalletAddress=${account}&&sign=${sign121}`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

       let response =await axios.request(config)
       try{
            if(response) {
                if (response?.data?.data?.updatedReward) {
                   return response?.data?.data?.updatedReward?._id
                }
            }
        }
        catch(err){
            setLoarder(false)
     console.log(err);
        }
            

    }
    var languageselected = localStorage.getItem('codelanguage')
    return (
        <>
            {loaderr &&

                <Loader text={t('check8')} />
            }


            <section className={languageselected == "ar" ? "deposit direction-for-arabic" : "deposit"}>
                <form onSubmit={(e) => withdraHandler(e)} className="w-100">
                    {/* <div className="twice-btn">
                    <div className="btn-drop" onClick={handleShow}>
                        <p>Source Network <img src="\Assets\button-svgs\pending-iconlight.svg" alt="img" className='img-fluid ms-2 dark' /><img src="\Assets\button-svgs\pending-icon.svg" alt="img" className='img-fluid ms-2 white' /></p>
                        {selectedItemFrom? <><h6 style={{ color: "black" }}>{selectedItemFrom}</h6></>:
                        <h6>To</h6>}
                        <img src="\Assets\button-svgs\arrow-down.svg" alt="img" className='img-fluid' />

                    </div>
                    <div className='arrow-swap-horizontal'>
                        <img src="\Assets\button-svgs\arrow-swap-horizontal.svg" alt="img" className='img-fluid  horizontalsss' />
                    </div>

                    <div className="btn-drop" onClick={handleShow0}>
                        <p>Destination Network <img src="\Assets\button-svgs\pending-iconlight.svg" alt="img" className='img-fluid ms-2 dark' /><img src="\Assets\button-svgs\pending-icon.svg" alt="img" className='img-fluid ms-2 white' /></p>
                        {selectedItemto? <><h6 style={{ color: "black" }}>{selectedItemto}</h6></>:
                        <h6>To</h6>}
                        <img src="\Assets\button-svgs\arrow-down.svg" alt="img" className='img-fluid' />
                    </div>
                </div> */}

                    <div className="tpl-address">
                        <div class="dropdown">
                            <p>{t('Sidebar-Transaction-H1-T1')} </p>
                            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {
                                    selectedItem ? <><h6>{selectedItem}</h6></> : "Please select DOP Address"}


                            </button>
                            <ul class="dropdown-menu">

                                <li>
                                    {items.map((item) => (
                                        <a className="dropdown-item drop" onClick={() => setSelectedItem(item)}>
                                            {item}
                                        </a>

                                    ))}
                                </li>
                                {/* <li><a class="dropdown-item" href="#">Account 01 | mqcmjqqo42DTQvn853rGxHfyvrEsc4xnth</a></li> */}
                            </ul>
                        </div>
                        <div className="asset" onClick={handleShow1} style={{ marginTop: '30px' }}>
                            <div className="btn-drop">
                                <p>{t('Sidebar-Transaction-H1-T2')} </p>
                                {selectedItemAsset ? <>
                                    <div className="selectmain">
                                        <div className="selectleft">
                                            <div className="selectimgmain">
                                                <img src={selectedItemimg} className='img-fluid selectinnerimg' />
                                            </div>
                                            <h5 className="selecttext">{selectedItemAsset}</h5>
                                        </div>
                                        <h5 className="selectnumber">
                                            {selectedItemNumber}
                                        </h5>
                                    </div>
                                </> :
                                    <h6>{t('Sidebar-Transaction-H1-T2')} </h6>}
                                <img src="\Assets\button-svgs\arrow-down.svg" alt="img" className='img-fluid' />
                            </div>
                        </div>
                    </div>

                    <div className="material-textfield">
                        <input placeholder="0.00" type="number" value={amount} onChange={(e) => amountHamder(e)} />
                        <label>{t('Sidebar-Transaction-H1-T3')} </label>
                    </div>
                    {copied1 &&
                        <p className="text-error">*{t('Insufficient-Funds')}</p>}
                    {/* <div className="balance-div">
                    <p>USDT</p>
                    <h6>{balancee ? parseFloat(balancee).toFixed(4) : '0.000'}</h6>
                </div> */}
                    <button id="speicalAZ244" type='submit' className='btn-deposit'>{t('Sidebar-Transaction-H1')}</button>
                </form>
            </section>



            {/* select network modal here..................... */}
            <Modal className={languageselected == "ar" ? "selectnetwork-modal direction-for-arabic" : "selectnetwork-modal"} show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Network</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="select-chain">
                        {
                            itemsAssets?.map((e) => {
                                return (
                                    <div className="inner-chain" onClick={() => {
                                        setSelectedItemFrom(e?.name)
                                        handleClose()
                                    }}>
                                        <div>
                                            <img src={e?.igm} alt="img" className='img-fluid' />

                                            {e?.name}
                                        </div>
                                        {e?.Number}


                                    </div>)
                            })
                        }
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className={languageselected == "ar" ? "selectnetwork-modal direction-for-arabic" : "selectnetwork-modal"} show={show0} onHide={handleClose0} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Network</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="select-chain">
                        {
                            itemsAssets?.map((e) => {
                                return (
                                    <div className="inner-chain" onClick={() => {
                                        setSelectedItemto("e.name" + "e.Number")
                                        handleClose0()
                                    }}>
                                        <img src={e?.igm} alt="img" className='img-fluid' />
                                        {e?.name}
                                    </div>)
                            })
                        }
                    </div>
                </Modal.Body>
            </Modal>

            {/* select asset modal here..................... */}
            <Modal className={languageselected == "ar" ? "selectnetwork-modal direction-for-arabic" : "selectnetwork-modal"} show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Sidebar-Transaction-H1-T2')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="option-field">
                        <img src="\Assets\button-svgs\search-normal.svg" alt="img" className='img-fluid search-icon' />
                        <input type="search" placeholder={t('search-assets')} onChange={(e) => amountHamderSearch(e)} />
                    </div>
                    <div className="select-chain">
                        {
                            itemsAssets?.map((e, i) => {
                                return (
                                    <div className="inner-chain" onClick={() => handlerForSelect(e, i)}>
                                        <div className='forflex'>
                                            <div className="modalmainimg">
                                                <img src={e?.igm} alt="img" className='img-fluid modalinnerimg' />
                                            </div>
                                            <p className="modalmaintext">{e?.name}</p>
                                        </div>
                                        <p className="modalmainnumber"> {e?.amountt > 0 ? e.amountt?.toFixed(3) : "0.000"}</p>
                                    </div>)
                            })
                        }


                        {/* <div className="inner-chain">
                            <img src="\Assets\button-svgs\bsc-icon.svg" alt="img" className='img-fluid' />
                            Binance Smart Chain
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\avax-icon.svg" alt="img" className='img-fluid' />
                            Avalanche
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\polygon-icon.svg" alt="img" className='img-fluid' />
                            Polygon
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\ether-icon.svg" alt="img" className='img-fluid' />
                            Ethereum
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\bsc-icon.svg" alt="img" className='img-fluid' />
                            Binance Smart Chain
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\avax-icon.svg" alt="img" className='img-fluid' />
                            Avalanche
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\polygon-icon.svg" alt="img" className='img-fluid' />
                            Polygon
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\ether-icon.svg" alt="img" className='img-fluid' />
                            Ethereum
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\bsc-icon.svg" alt="img" className='img-fluid' />
                            Binance Smart Chain
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\avax-icon.svg" alt="img" className='img-fluid' />
                            Avalanche
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\polygon-icon.svg" alt="img" className='img-fluid' />
                            Polygon
                        </div> */}
                    </div>
                </Modal.Body>
            </Modal>

            {/* transaction summary modal here..................... */}
            <Modal className={languageselected == "ar" ? "summary-modal direction-for-arabic" : "summary-modal"} show={show2} onHide={handleClose2} centered>
                <Modal.Header >
                    <Modal.Title>
                        <h6>{t('Transaction-Summary')}</h6>
                        <p>{t('Transaction-Summary_T1')}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bottom-content">
                        <div className="inner-text">
                            <p>{t('D1')}</p>
                            <h6>Sepolia</h6>
                        </div>
                        <div className="inner-text">
                            <p>{t('D2')}</p>
                            <h6>Sepolia</h6>
                        </div>
                        <div className="inner-text">
                            <p>{t('D3')}</p>
                            <h6>No Bridge</h6>
                        </div>
                        <div className="inner-text">
                            <p>{t('D4')}</p>
                            <h6>{selectedItemAsset}</h6>
                        </div>
                        <div className="inner-text">
                            <p>{t('D5')}</p>
                            <h6>{amount ? parseFloat(amount).toFixed(4) : ""} {selectedItemAsset}</h6>
                        </div>
                        {copied1 &&
                            <p className="text-error">*{t('D8')}</p>}
                        <div className="inner-text">
                            <p>{t('D6')}</p>
                            <h6>{selectedItem}</h6>
                        </div>
                        {/* <div className="inner-text">
                            <p>Rollup Fee</p>
                            <h6>0.01 ETH</h6>
                        </div> */}
                    </div>
                    <div className="last-text">
                        <h6>{t('D7')}</h6>
                        <h5>{amount ? parseFloat(amount).toFixed(4) : ""} {selectedItemAsset}</h5>
                    </div>
                    <div className="twice-btn">
                        <button id="speicalAZ245" onClick={handleClose2} className='btn-cancel'>{t('Cancel')}</button>

                        {/* {allowance>=amount? */}
                        {allowance == null ?
                            <button id="speicalAZ246" onClick={switchChain1} className='btn-confirm'>{t('Confirm')} </button> :

                            <button id="speicalAZ247" onClick={switchChain} className='btn-confirm'>{t('Confirm')} </button>
                        }
                        {/* : */}
                        {/* // <button onClick={approveHandler} className='btn-deposit'>Approve</button>
                // } */}
                    </div>
                </Modal.Body>
            </Modal>

            {/* transaction progress modal here..................... */}
            <Modal className={languageselected == "ar" ? "summary-modal direction-for-arabic" : "summary-modal"} show={show33} onHide={handleClose33} centered>
                <Modal.Header >
                    <Modal.Title>
                        <h6>Transaction in Progress</h6>
                        <p>Please wait few minutes for your transaction to be confirmed.
                        </p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="progress-content">
                        <h6><img src="\Assets\button-svgs\tick-circle.svg" alt="img" className='img-fluid me-3' />Submit asset approving transaction</h6>
                        <h6><img src="\Assets\button-svgs\tick-circle.svg" alt="img" className='img-fluid me-3' />Confirm assets approving transaction</h6>
                        <h6><img src="\Assets\button-svgs\tick-circle.svg" alt="img" className='img-fluid me-3' />Submit deposit source chain</h6>
                        <h6><img src="\Assets\button-svgs\tick-circle.svg" alt="img" className='img-fluid me-3' />Confirm deposit on source chain.</h6>
                    </div>
                    <div className="bottom-text">
                        <div className="inner-side">
                            <p>Deposit Transaction</p>
                            <h6>{selectedItem}</h6>
                        </div>
                        <div className="inner-side">
                            <p>Asset Approve Transaction</p>
                            <h6>{parseFloat(amount).toFixed(4)}</h6>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* transaction summary modal here..................... */}
            <Modal className={languageselected == "ar" ? "success-modal direction-for-arabic" : "success-modal"} show={show4} onHide={handleClose4} centered>
                <Modal.Body>
                    <div className="success-div">
                        <img src="\Assets\checkmark.svg" alt="img" className='img-fluid dark' />
                        <img src="\Assets\checkmarklight.svg" alt="img" className='img-fluid white' />
                        <h6>Deposit Sent successfully</h6>
                        <p>Check transaction history for more information.</p>
                    </div>
                    <div className="twice-btn">
                        <button id="speicalAZ248" onClick={handleClose4} className='btn-cancel'>Close</button>
                        <button id="speicalAZ49" className='btn-confirm'>transaction History</button>
                    </div>
                </Modal.Body>
            </Modal>



            <Modal className={languageselected == "ar" ? "successsssss-modal direction-for-arabic" : "successsssss-modal"} show={show3} onHide={handleClose3} centered backdrop="static"
                keyboard={false}>
                <Modal.Body>
                    <div className="inner-content">
                        <img src="\Assets\checkmark.svg" alt="img" className='img-fluid dark' />
                        <img src="\Assets\checkmarklight.svg" alt="img" className='img-fluid white' />
                        <h6 className='main-head'>{t('Transaction-Summary_T2')}</h6>
                        <div className="inline-text">
                            <img src={selectedItemimg} style={{ width: "48px", height: "48px" }} alt="img" className='img-fluid' />
                            <h4>{amount ? parseFloat(amount).toFixed(4) : "0.0000"} <h6>{selectedItemAsset}</h6></h4>
                        </div>
                        <p className='para'>{t('Transaction-Summary_T3')}.</p>
                    </div>
                    <div className="twice-btns" style={{ gap: '20px' }}>
                        <button id="speicalAZ250" onClick={() => {
                            if (deposite === true) {

                                // Btnclick('btnfive');
                                window.location.reload()
                            } else {
                                setShowgift(true)
                            }
                        }} className='btn-closee'>{t('Transaction-Summary_T4')}</button>
                        <button id="speicalAZ251" className='btn-explorer'> <a className='linksd' target="_blank" href={`https://sepolia.etherscan.io/tx/${mainHsh}`}>{t('Transaction-Summary_T5')} </a></button>
                        {/* <button className='btn-confirm'>transaction History</button> */}
                    </div>
                </Modal.Body>

            </Modal>


            <Modal className={languageselected == "ar" ? "important-note giftmodal-scss direction-for-arabic" : "important-note giftmodal-scss"} show={showgift} onHide={handleClosegift} centered backdrop="static" >
                <Modal.Body>
                    <div className="inner-set">
                        <div className="left">
                            <h6>Success</h6>
                        </div>
                    </div>

                    <div className="bottom-text">
                        <img src="\Assets\gift.svg" alt="img" className='img-fluid dark' />
                        <img src="\Assets\darkgift.svg" alt="img" className='img-fluid white' />
                        <h6>You have successfully completed your task</h6>
                        <button id="speicalAZ252" className='btn-confirm'> OKAY </button>
                    </div>


                </Modal.Body>
            </Modal>


            <Modal className={languageselected == "ar" ? "important-note giftmodal-scss direction-for-arabic" : "important-note giftmodal-scss"} show={showgift} onHide={handleClosegift} centered backdrop="static" >
                <Modal.Body>
                    <div className="inner-set">
                        <div className="left">
                            <h6>{t('success')}</h6>
                        </div>
                    </div>

                    <div className="bottom-text">
                        <img src="\Assets\gift.svg" alt="img" className='img-fluid dark' />
                        <img src="\Assets\darkgift.svg" alt="img" className='img-fluid white' />
                        <h6>{t('Transaction-Summary_T6')}</h6>
                        <button id="speicalAZ253" className='btn-confirm' onClick={() => {
                            // window.location.reload()
                            Btnclick('btnfive');
                            // window.location.reload()
                        }}> {t('Transaction-Summary_T7')} </button>
                    </div>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default Deposit
