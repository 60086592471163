import axios from 'axios'
import { API_URL } from '../../utils/ApiUrl';


export const GetUsers = (value) => async (dispatch) => {
  // console.log(value,'value');
  dispatch({
    type: "ALOLOWANCE_1",
    payload: value,
  });

 
};
export const SendStatus = (value) => async (dispatch) => {
  // console.log(value,'value');
  dispatch({
    type: "SEND_1",
    payload: value,
  });

 
};
export const WithdrawStatus = (value) => async (dispatch) => {
  // console.log(value,'value');
  dispatch({
    type: "WITHDRAW_1",
    payload: value,
  });

 
};


export const DepositeStatus = (value) => async (dispatch) => {
  // console.log(value,'value');
  dispatch({
    type: "DEPOSITE_1",
    payload: value,
  });

 
};