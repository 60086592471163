import './App.scss';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Landing from './components/landing/Landing';
import Footer from './components/landing/footer/Footer';
import Importwallet from './components/Importwallet/Importwallet';
import CreateWallet from './components/Createwallet/CreateWallet';
import CreateWallet1 from './components/Createwallet/CreateWallet1';
import UnlockWallet from './components/unlock-wallet/UnlockWallet';
import SecretRecovery from './components/Createwallet/SecretRecovery';
import VerifyPhrases from './components/Createwallet/VerifyPhrases';
import Congrats from './components/Createwallet/Congrats';
import Tpldashboard from './components/Tpldashboard/Tpldashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useEagerConnect from "./hooks/useEagerConnect";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { useEffect } from 'react';
import { useState } from 'react';
import Login from './components/Login/Login';
import { useTranslation } from 'react-i18next'
import WelcomeDop from './components/landing/main-banner/WelcomeDop';
function App() {
  const { t } = useTranslation()
  const [showservice, setShowService] = useState(false);
  const handleCloseService = () => setShowService(false);
  const handleShowService = () => setShowService(true);
  const get_ApiKeys = async () => {
    try {
      const response = await axios({
        method: "get",
        url: "https://ip.nf/me.json",
      });
      //  console.log(response?.data,'response',response?.data.ip.country?.toLowerCase());
      localStorage.setItem('ip', response?.data.ip.ip)
      localStorage.setItem('country', response?.data.ip.country?.toLowerCase())
       if(response?.data.ip.country?.toLowerCase()==='iran'||response?.data.ip.country?.toLowerCase()==='north korea'||response?.data.ip.country?.toLowerCase()==='iraq'||response?.data.ip.country?.toLowerCase()==='yemen'||response?.data.ip.country?.toLowerCase()==='syria'||response?.data.ip.country?.toLowerCase()==='lebanon'||response?.data.ip.country?.toLowerCase()==='northkorea'||response?.data.ip.country?.toLowerCase()==='central african republic'||response?.data.ip.country?.toLowerCase()==='belarus'||response?.data.ip.country?.toLowerCase()==='burundi'||response?.data.ip.country?.toLowerCase()==='centralafricanrepublic'||response?.data.ip.country?.toLowerCase()==='congo'||response?.data.ip.country?.toLowerCase()==='Guinea'||response?.data.ip.country?.toLowerCase()==='guinea-bissau'||response?.data.ip.country?.toLowerCase()==='guineabissau'||response?.data.ip.country?.toLowerCase()==='libya'||response?.data.ip.country?.toLowerCase()==='mali'||response?.data.ip.country?.toLowerCase()==='myanmar'||response?.data.ip.country?.toLowerCase()==='burma'||response?.data.ip.country?.toLowerCase()==='republicofsouthsudan'||response?.data.ip.country?.toLowerCase()==='republic of south sudan'||response?.data.ip.country?.toLowerCase()==='russia'||response?.data.ip.country?.toLowerCase()==='somalia'||response?.data.ip.country?.toLowerCase()==='sudan'||response?.data.ip.country?.toLowerCase()==='ukraine'||response?.data.ip.country?.toLowerCase()==='venezuela'||response?.data.ip.country?.toLowerCase()==='zimbabwe'){
      setShowService(true)}
      else{
      setShowService(false)
      }

    } catch (error) {
      setShowService(false)
      console.log(error, 'error');
      // localStorage.setItem('ip',response?.data.ip)
      // localStorage.setItem('country',response?.data.country)
      // throw error; 
    }
  };
  useEffect(() => {
    get_ApiKeys()
  }, [])

  useEagerConnect();

  const email = localStorage.getItem('email');
  const modal = localStorage.getItem('modal');
  var languageselected = localStorage.getItem('codelanguage')

  return (
    <>
      <ToastContainer autoClose={5000} style={{ fontSize: 12, fontWeight: 300 }} theme="light" position="top-center" />
      <Router>
        <Switch>
          {/* {modal === 'true' && email ? ( */}
            <>
              <Route exact path='/' component={Landing} />
              <Route exact path='/id=:id' component={Landing} />
              {/* <Route  exact path='/:userId'  >
            <Redirect to="/" />
          </Route> */}
           <Route exact path='/welcome' component={WelcomeDop} />
              <Route exact path='/import' component={Importwallet} />
              <Route exact path='/create' component={CreateWallet} />
              <Route exact path='/reset' component={CreateWallet1} />
              <Route exact path='/secretrecoveryphrase' component={SecretRecovery} />
              <Route exact path='/verifyphrases' component={VerifyPhrases} />
              <Route exact path='/congrats' component={Congrats} />
              <Route exact path='/unlock' component={UnlockWallet} />
              <Route exact path='/dashboard' component={Tpldashboard} />
              {/* <Route exact path='/login' component={Login} /> */}
            </>
          {/* ) : (
            <Route exact path='/' component={WelcomeDop} />
          )} */}


        </Switch>
        <Footer />
      </Router>

      <Modal className={languageselected == "ar" ? "service-modal direction-for-arabic" : "service-modal"} backdrop="static"
        keyboard={false} show={showservice} onHide={handleCloseService} centered>
        <Modal.Body>
          <div className="upper-div">
            <img src="\ic_round-warning.svg" alt="img" className="img-fluid" />
            <h6>{t('service')}</h6>
          </div>
          <div className="bottom-div">
            <h6>{t('service-T1')}</h6>
            <p>{t('service-T2')}.</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default App;
