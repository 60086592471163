import React from 'react'
import Navbar from '../landing/header/navbar1'
import { Link } from 'react-router-dom';
import './create.scss'
import { useState,useEffect } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveData, getData } from '../../db';
import { useHistory } from "react-router-dom";
import Navbar1 from '../landing/header/navbar1';
import { useWeb3React } from "@web3-react/core";
import { useTranslation } from 'react-i18next'
const CreateWallet = () => {
  const { t } = useTranslation()
  // const [show, setShow] = useState(false);
  const { account } = useWeb3React();
  const history = useHistory()
  const [pasword,setPasword]=useState('')
  const [pasword1,setPasword1]=useState('')
  const [mod,setMod]=useState(false)
  const [mod1,setMod1]=useState(false)
  const [copied, setCopied] = useState(false);
  const [copied1, setCopied1] = useState(false);
const handler=(e)=>{
  // console.log('pass',e.target.value);
  setPasword(e.target.value)
}
const handler1=(e)=>{
  setPasword1(e.target.value) 
  // console.log('confirm',e.target.value);
}
const submit=(e)=>{
  e?.preventDefault()
  // console.log('click me ');
  if(pasword!=''&&pasword1!=''){
    if(pasword===pasword1){
    // toast.success("Password is Created Successfully")
    handleClick()
    history.push('/secretrecoveryphrase')
    }else{
      setCopied1(true)
      setTimeout(() => {
          setCopied1(false)
      }, 2000)
    }
  }else
  {
    setCopied(true)
    setTimeout(() => {
        setCopied(false)
    }, 2000)
  }
}
const hide=()=>{
  // console.log();
  if(mod){
    setMod(false)
  }
  else{
    setMod(true)
  }
}
const hide1=()=>{
  // console.log();
  if(mod1){
    setMod1(false)
  }
  else{
    setMod1(true)
  }
}
const handleClick = async () => {
  await saveData('tpl_password',pasword);
  // const result = await getData('key');
  // setData(result);
};
const handleClick12 = async () => {
  // await saveData('tpl_password',pasword);
  // const result = await getData('tpl_password');
  // const result1 = await getData('selected_privateKey');
  // const mail = await localStorage.getItem('mail')
  // console.log(mail,'mail');
  // if(!mail){
    // history.push('/login')
  // }
  // if(result && result1){
  //   history.push('/unlock')
  // }
  // setData(result);
// };
  // setData(result);
};
// useEffect(() => {
//   handleClick12()
// },[account]);
var languageselected = localStorage.getItem('codelanguage')
  return (
    <>
      <Navbar1
       />
      <section className={languageselected == "ar" ? "createwallet direction-for-arabic" : "createwallet"}>
        <div className="theme-container">
        <h6 className="main-upper-new-heading">{t('route-landing-Box1-B1')}</h6>
          <div className="innerimport">
            <div className="importheader">
              <Link to="/">
                <img src="\Assets\import-assets\arrow-left.svg" alt="backarrow" className="backarrow" />
              </Link>
              <p className="importhead">{t('route-create-H1')}</p>
            </div>
            <form onSubmit={(e)=>submit(e)} className="w-100">
            <div className="importbody">
              <div className="material-textfield">
                <input value={pasword} onChange={(e)=>handler(e)} placeholder="Enter new password" type={mod?"text":"password"} />
                <label>{t('route-import-T2')}</label>
                <img onClick={hide}  src="\Assets\import-assets\eye-dark.svg" alt="eye" className='eye dark' />
                <img onClick={hide}  src="\Assets\import-assets\eye-white.svg" alt="eye" className='eye white' />
              </div>
              <div className="material-textfield">
                <input value={pasword1} onChange={(e)=>handler1(e)}  placeholder="Confirm new password" type={mod1?"text":"password"} />
                <label>{t('route-reset-T3')}</label>
                <img onClick={hide1}  src="\Assets\import-assets\eye-dark.svg" alt="eye" className='eye dark' />
                <img onClick={hide1}  src="\Assets\import-assets\eye-white.svg" alt="eye" className='eye white' />
              </div>
              {copied&&
  <p className="text-error">*{t('Invalid-Password')}</p>}
   {copied1&&
  <p className="text-error">*{t('password-match')}</p>}
              {/* <Link to="/secretrecoveryphrase"> */}
              <button id="speicalAZ219" type='submit' className="common-btntwo">{t('Sidebar-Account-H3-B1')}</button>
              {/* </Link> */}
            </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default CreateWallet
