import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect } from "react";
// import Web3 from "web3";
import { getLibraryForSign } from "../../utils/web3React";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useWeb3 from "../../hooks/useWeb3";
import Web3 from 'web3';
import { connectorsByName } from "../../utils/web3React";
import { soliditySha3 } from "web3-utils";

import { ethers, providers } from "ethers";
export const Signature1 = (data) => {
  // const { account } = useWeb3React()
  const { account } = useWeb3React()
  // console.log(account,'account11');
  const web3 = useWeb3()
  var library = null;
  // useEffect(() => {
  // if (account && web3 && connectorsByName) {
  // library = getLibraryForSign(web3.givenProvider);
  const connectorId = window.localStorage.getItem("connectorId")

  if (connectorId === 'injected' && account) {
    library = getLibraryForSign(web3?.givenProvider);
  } else {
    // return console.log("connectorsByName", connectorsByName['walletconnect'])
    if (connectorsByName.walletconnect.provider) {
      library = getLibraryForSign(connectorsByName.walletconnect.provider);
    }
  }

  //   } else {
  //     // toast.error("you dont have metamask in your browser please change your browser or add metamask to proceed with the site ")
  //   }
  // }, [account, web3, connectorsByName])

  const sign = useCallback(async (iterna_account, random_num, current_timeime, amount,selectedIndex) => {
// console.log(iterna_account, random_num, current_timeime, amount,'iterna_account, random_num, current_timeime, amount');



    if ((library && account)) {
      try {
        if (account) {
          // const connectorId = window.localStorage.getItem("connectorId")
          // console.log("sdfdfsdfdsf", connectorId);
          // if (connectorId === 'injected') {
          //   library = getLibraryForSign(web3?.givenProvider);
          // } else {
          //   library = getLibraryForSign(connectorsByName['walletconnect'].walletConnectProvider);
          // }
          // ethers.ut
          // console.log(ethers,'ethers.utils');
          // console.log(iterna_account, random_num, current_timeime, 'iterna_account,random_num,current_timeime');
          // let newwallet = web3.eth.accounts.privateKeyToAccount(iterna_account);
          // iterna_account = newwallet.address
          // let soliditySha3Expected = soliditySha3(
          //   account?.toLowerCase(),
          //   iterna_account?.toLowerCase(),
          //   random_num?.toString(),
          //   current_timeime?.toString()
          // );
          // let signature = null;
          // await web3.eth.personal.sign(soliditySha3Expected, account).then(async (res) => {
          //   signature = res;
          // }).catch((error)=>{
          //   console.log(error,'error');
          // })



          let newwallet = web3.eth.accounts.privateKeyToAccount(iterna_account);
          let wall = newwallet.address
        //   console.log(amount,'amount');
        let nd=''
        if (selectedIndex==21||selectedIndex==22){

          nd = web3.utils.toWei(amount.toString(), "mwei");
        }else{
          nd = web3.utils.toWei(amount.toString(), "ether");
        }

// console.log(nd,wall,random_num,current_timeime,'+++++++++=');
          let soliditySha3Expected = soliditySha3(
            account,
            wall,
            nd,
            random_num,
            current_timeime
          );
          // let signature = null;
          // await web3.eth.personal.sign(soliditySha3Expected, iterna_account).then(async (res) => {
          //   signature = res;
          // }).catch((error) => {
          //   console.log(error, 'error');
          // })

// console.log( account,
//   wall,
//   random_num,
//   current_timeime,nd,' accountwall random_num current_timeime');

          // let signature = await library.send(
          //   'personal_sign',
          //   [ ethers.utils.hexlify(ethers.utils.toUtf8Bytes(JSON.stringify({account, iterna_account,random_num,current_timeime}))), account.toLowerCase() ]
          // );
          // let signature = await signing?.signMessage(web3.utils.hexToBytes(web3.utils.stringToHex(JSON.stringify({address : account , name : "Tomi Dao"}))) );
          // toast.success(`${signature.substring(0, 6)}...${signature.substring(signature.length - 4)}`, {
          //   position: "top-right",
          //   autoClose: 2000,
          // });




          // const privateKey = iterna_account;
       
          web3.eth.accounts.wallet.add(newwallet);
          // console.log(account, wall,random_num, current_timeime,'extAccount:account, intAccount:wall,entropy:random_num, epoch:current_timeime');
          // const message = JSON.stringify({ extAccount:account, intAccount:wall,entropy:random_num, epoch:current_timeime});
          // console.log(message,'message');
          let signmessage=await web3.eth.sign(soliditySha3Expected,wall)
          // console.log(signmessage,'signmessage');
          // const messageHash = web3.utils.sha3(message);
          // console.log(messageHash, 'messageHash', 'message', message,newwallet);
          
          // const signature = await web3.eth.personal.sign(messageHash, );


          return signmessage;
        }
        // AddProfile({ walletAddress: data })
        // return signature;
      }
      catch (error) {
        console.log("error", error)
        // toast.error(error && error.message, {
        //   position: "top-right",
        //   autoClose: 2000,
        // });
      }
    }
  }, [account, library, data, web3])
  return { userSign: sign }
}
export default Signature1