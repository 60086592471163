import React from 'react'
import Navbar from '../landing/header/navbar1'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { lte } from 'lodash'
// import { map } from 'lodash'
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { saveData, getData } from '../../db';
import Navbar1 from '../landing/header/navbar1'
import { useTranslation } from 'react-i18next'
const VerifyPhrases = () => {
    const { t } = useTranslation()
    let localData= localStorage.getItem('phraseStatus')
    const history = useHistory()
    const [orignal, serOrignal] = useState([])
    const [secertPhas, setSecertPhas] = useState([])
    let [secertPhas1, setSecertPhas1] = useState([])
    const value = localStorage.getItem('arry');
    useEffect(() => {
     
        // console.log('sdfsd','data');
        // handleClick()
        handleClick()
    }, [localData]);
    
    let array1 = []
    const clickMe = (e, i) => {
        //    console.log(e,i);
        //    secertPhas.find((r,q)=>i==q)
        let x=0


        const counts = {};
        
        // console.log(count,'counts');
        const count = secertPhas?.filter((value) => value.checked).length;
        const count1 = secertPhas?.filter((value) => value.checked==false).length;
        for (let q = 0; q < secertPhas.length; q++) {
            // console.log(secertPhas[q]);
            if (q == i) {
                // console.log(secertPhas[q].aa,'checkedArry');
                secertPhas1.push(secertPhas[q].aa)
                if (secertPhas[q].checked){
                    secertPhas[q].checked=false
                    secertPhas[q].vall=''
                }else
                {
                    secertPhas[q].checked=true
                    secertPhas[q].vall=count+1
                }
                // console.log("secertPhas",secertPhas)

            }
        }

        setSecertPhas([...secertPhas])

    }
const submittt=()=>{
    // console.log(orignal,'secertPhas1');
    // console.log(secertPhas1,'secertPhas1');
    let chee=false
    for (let q = 0; q < secertPhas.length; q++) {
     
        // console.log(q);
        // console.log(secertPhas[q]);
        // if (q == i) {
        //     // console.log(secertPhas[q].aa,'checkedArry');
        //     secertPhas1.push(secertPhas[q].aa)
        //     if (secertPhas[q].checked){
        //         secertPhas[q].checked=false
        //         secertPhas[q].vall=''
        //     }else
        //     {
        //         secertPhas[q].checked=true
        //         secertPhas[q].vall=count+1
        //     }
            // console.log("secertPhas",secertPhas)

            if (orignal[q]!=secertPhas1[q]){
                chee=true  
        }

        }
        if(chee){
            // toast.error("Phrases is not varified")
            history.push('/secretrecoveryphrase')
        }else{
            // toast.success("Phrases varified")
            history.push('/congrats')
        }
    }
    function shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }
    const handleClick = async () => {
 
        const result = await getData('tpl_phars');
        console.log(result,'result');
        let a = result
        serOrignal([...a])
        shuffleArray(a)
        // console.log(a, 'a');
        // setSecertPhas(a)
        let arry1 = []
        // a.map((e)=>{
        for (let i = 0; i < a.length; i++) {
            // console.log(a[i], 'e');
            let aa = a[i]
            let checked = false
            let result = { aa, checked: checked ,vall:0 }
            // console.log(result, 'result');
            arry1.push(result)

        }
        // console.log(arry1);
        setSecertPhas(arry1)
      };

      const handleClick12 = async () => {
        // await saveData('tpl_password',pasword);
        // const result = await getData('tpl_password');
        // const result1 = await getData('selected_privateKey');
        // const mail = await localStorage.getItem('mail')
        // console.log(mail,'mail');
        // if(!mail){
        //   history.push('/login')
        // }
        // if(result && result1){
        //   history.push('/unlock')
        }
        // setData(result);
      // };
        // setData(result);
    //   };
    //   useEffect(() => {
    //     handleClick12()
    //   },[]);
    var languageselected = localStorage.getItem('codelanguage')
    return (
        <>
            <Navbar1/>
            <section className={languageselected == "ar" ? "secret-recovery direction-for-arabic" : "secret-recovery"}>
                <div className="theme-container">
                    <div className="secret-recover-div">
                        <div className="upper-heading ">
                            {/* <img src="\Assets\import-assets\arrow-left.svg" alt="img" className='img-fluid' /> */}
                            <h6>{t('route-verifyphrases-H1')}</h6>
                        </div>
                        <div className="bottom-content">
                            <p className="para">
                           {t('route-verifyphrases-T1')}.
                            </p>
                            <div className="phrase-content">
                                {
                                    secertPhas?.map((e, i) => {
                                        // console.log(e.checked,'e.checked');
                                        return (
                                            <button className={e.checked ? "single-phrase " : "single-phrase"} disabled={e.vall==0?false:true} onClick={() => clickMe(e, i)}>
                                                  {e.checked?<span>{e.vall}</span>:''}
                                                <p>{e.aa}</p>
                                            </button>

                                        )
                                    })
                                }


                            </div>
                            <div className="twice-btn">
                            <button id="speicalAZ222" onClick={submittt} className='btn-verify done-btn w-100'>{t('route-verifyphrases-B1')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default VerifyPhrases
