import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { usdtContract } from "../../utils/contractHelpers";
import { dopContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const AllowanceTpl = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.tpl;
  const tokenAddress1 = Environment.busd;



  const allowanceTpl = useCallback(
    async (e) => {
      let contract =''
      if(e===Environment?.Dop){
        // console.log(e,'noor');
         contract = dopContract(e, web3);
      }else{
        contract = usdtContract(e, web3);
      }
        // console.log(tokenAddress,account,'noor');
      try {
        const buy = await contract.methods.allowance(account,tokenAddress).call({from:account})
        // console.log('sdsdss' ,  buy)
        return buy;
      } catch (error) {
        throw error;
      }
    },
    [web3, account]
  );
  return { allowanceTpl: allowanceTpl };
};
export default AllowanceTpl;