import React, { useState, useEffect } from 'react';
import './earnrewards.scss';
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../../hooks/useWeb3";
import ReactApexChart from 'react-apexcharts';
import axios from "axios";
import { API_URL } from "../../utils/ApiUrl";
import Loader from '../../hooks/loader';
import { io } from "socket.io-client";
import { Dropdown, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from 'react-redux';
import { SendStatus, WithdrawStatus, DepositeStatus } from '../../redux/action';
import Environment from '../../utils/Environment';
import TwitterLogin from 'react-twitter-auth';
import { deleteAllData } from '../../db';
import { FlashAuto, FlashOnSharp, Tune } from '@material-ui/icons';
import MintDop from '../../hooks/dataFetchers/mintDop';
import MintAll from '../../hooks/dataFetchers/mintAll';
// import GleamWidget from './gleam';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next'
import { setupNetwork } from '../../utils/wallet';

const Earnrewards = ({ internalAount, setStateusCheck, stateusCheck, setDefaultt, refralCode, theme }) => {
    const { t } = useTranslation()
    let iddd = localStorage.getItem('idd');
    let sign121 = localStorage.getItem('sign');
    let success121 = localStorage.getItem('success')
    let kakaoLocal = localStorage.getItem('kakao')
    // let linkUser = localStorage.getItem('linke')
    let nodataModel=localStorage.getItem('important');
    const { mintDop } = MintDop()
    const { mintAll } = MintAll()
    const [linkUser, setLinkUser] = useState(null)
    const dispatch = useDispatch();
    const [arg, setArg] = useState(null)
    const { account, chainId } = useWeb3React();
    const [bar, setBar] = useState(0)
    const [counter, setCounter] = useState(0)
    const [id, setId] = useState(null)
    // const [restTweeter,setRestTweeter]=useState(false)
    const [checkedNew1, setCheckedNew1] = useState(false)
    const [inputFeild6, setInputFeild6] = useState(null);
    const [cheked111, setCheked111] = useState(null)
    const [cheked1, setCheked1] = useState(false)
    const [authVarifier, setAuthVarifier] = useState(null)
    const [twiterToken, setTwiterToken] = useState(null)
    const [userCount, setUserCount] = useState(null)
    const [twitterAutization, setTwitterAutization] = useState(false)
    const web3 = useWeb3();
    const [oauthVerifier1, setOauthVerifier1] = useState(null)
    const [oauthToken1, setOauthToken1] = useState(null)
    const [capchaa, setCapcha] = useState(null)
    const [completionCount, stepsCompletionCount] = useState(null)
    const [completionCountPercents, tepsCompletionCountPercent] = useState(null)
    const [progressBar, setProgressBar] = useState(null)
    const [progressBarPer, setProgressBarPer] = useState(null)
    const [step8class, setStep8Class] = useState(false)


    const linkText = `Big opportunity!\u{1F680} \n\n I just completed the #DOPtestnet\u{1F510} and now I qualify for future DOP token rewards!\n\n@dop_org tackles blockchain over-transparency, giving users control of their financial data.\n\nFinish testnet tasks now for future token rewards: `
    const itemsAssets =
        [

            { igm: "/Assets/tpldashboard/myprivateassets/imgtwo.svg", symbol: 'USDT', name: "DOP_USDT", indecBy: "0", address: Environment?.usdt },
            { igm: "/Assets/tpldashboard/myprivateassets/imgthree.svg", symbol: 'USDC', name: "DOP_USDC", indecBy: "1", address: Environment?.USDC },
            { igm: "/Assets/tpldashboard/myprivateassets/imgfour.svg", symbol: 'SHIB', name: "DOP_SHIB", indecBy: "2", address: Environment?.SHIB },
            { igm: "/Assets/tpldashboard/myprivateassets/imgfive.svg", symbol: 'LINK', name: "DOP_LINK", indecBy: "3", address: Environment?.LINK },
            { igm: "/Assets/tpldashboard/myprivateassets/imgsix.svg", symbol: 'UNI', name: "DOP_UNI", indecBy: "4", address: Environment?.UNI },
            { igm: "/Assets/tpldashboard/myprivateassets/imgseven.svg", symbol: 'APE', name: "DOP_APE", indecBy: "5", address: Environment?.APE },
            { igm: "/Assets/tpldashboard/myprivateassets/imgeight.svg", symbol: 'PEPE', name: "DOP_PEPE", indecBy: "6", address: Environment?.PEPE },
            { igm: "/Assets/tpldashboard/myprivateassets/imgnine.svg", symbol: 'CHZ', name: "DOP_CHZ", indecBy: "7", address: Environment?.CHZ },
            { igm: "/Assets/tpldashboard/myprivateassets/imgten.svg", symbol: 'COMP', name: "DOP_COMP", indecBy: "8", address: Environment?.COMP },
            { igm: "/Assets/tpldashboard/myprivateassets/imgeleven.svg", symbol: 'TOMI', name: "DOP_TOMI", indecBy: "9", address: Environment?.TOMI }
        ]


    const [copy, setCopy] = useState(false);
    const [copyy, setCopyy] = useState(false);
    const [copyyy, setCopyyy] = useState(false);
    const [copyyyy, setCopyyyy] = useState(false);
    const [copppy, setCoppy] = useState(false);
    const textCopiedFun2 = () => {
        setCopy(true);
        setTimeout(() => {
            setCopy(false);
        }, 1000);
    };
    const textCopiedFun3 = () => {
        setCopyyy(true);
        setTimeout(() => {
            setCopyyy(false);
        }, 1000);
    };
    const textCopiedFun4 = () => {
        setCopyyyy(true);
        setTimeout(() => {
            setCopyyyy(false);
        }, 1000);
        clickApi('copyUrl')
    };
    const textCopiedFun1 = () => {
        setCopyy(true);
        setTimeout(() => {
            setCopyy(false);
        }, 1000);
    };
    const textCopiedFunnn2 = () => {
        setCoppy(true);
        setTimeout(() => {
            setCoppy(false);
        }, 1000);

        clickApi('copyUrl')

    };

    const [nameOfToken, setNameOftokem] = useState('DOP_USDT');
    const [symbolOfToken, setSymbolOftokem] = useState('DOP_USDT');
    const [addrssOfToken, setAddressOfToken] = useState(Environment?.usdt);
    const [imgeOfToken, setImgeOfToken] = useState('/Assets/tpldashboard/myprivateassets/imgtwo.svg');
    const [dopToken, setDopToken] = useState(Environment?.Dop)
    // glaim varable
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const state = {
        series: [bar],
        options: {
            chart: {
                height: 210,
                width: 210,
                type: 'radialBar',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: 0,
                    endAngle: 360,
                    hollow: {
                        margin: 0,
                        size: '70%',
                        background: 'var(--body-bg)',
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front',
                    },
                    track: {
                        background: 'var(--newradialclr)',
                        strokeWidth: '100%',
                        margin: 0, // margin is in pixels
                    },

                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: -10,
                            show: true,
                            color: '#81828A',
                            fontSize: '15px'
                        },
                        value: {
                            formatter: function (val) {
                                return parseInt(val) + '%';
                            },
                            color: 'var(--card-border)',
                            fontSize: '24px',
                            show: true,
                        }
                    }
                },
            },
            colors: ['var(--card-border)'],
            stroke: {
                lineCap: 'round'
            },
            labels: [t('Landing-T3')],
        },
    }

    const [stepTwo, setSteptwo] = useState(false)
    const [stepTwo1, setSteptwo1] = useState(false)
    const localData1 = localStorage.getItem('deposit');
    const localData2 = localStorage.getItem('send');
    const localData3 = localStorage.getItem('widthraw');
    const [loaderr, setLoader] = useState(null)
    const [completeData, setCompleteData] = useState(null)
    const stepTwoactive = async () => {
        if (account) {
            setLoader(true)

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${API_URL}rewards/getSepolia?internalWalletAddress=${internalAount}&&externalWalletAddress=${account}&&sign=${sign121}`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };

            axios.request(config)
                .then((response) => {
                    setTimeout(() => {
                        Btnclick('btntwo');
                        setSteptwo(true);
                        api()
                        window.location.reload()
                    }, "5000");

                })
                .catch((error) => {
                    console.log(error);
                    setLoader(false)
                    if (error.response.data.statusCode == 500) {
                        toast.error(t('check3'));
                    }
                    else {
                        toast.error(error.response.data.message);
                    }

                });

        } else {
            toast.warning(t('check4'));
        }

    }
    const apiForDop = async () => {


        const axios = require('axios');
        let data = JSON.stringify({
            "claim_Dop": {
                "isCompleted": true
            }
            ,
            "sign": sign121,
            "internalWalletAddress": internalAount,
            "externalWalletAddress": account
        });

        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${API_URL}rewards/${id}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                Btnclick('btnthree');
                setLoader(false)
                setSteptwo1(true);
                api()
                window.location.reload()
            })
            .catch((error) => {
                console.log(error);
                setLoader(false)
                toast.error(error.response.data.message);

            });
        // if (account) {
        //     setLoader(true)

        //     let config = {
        //         method: 'get',
        //         maxBodyLength: Infinity,
        //         url: `${API_URL}rewards/getDop/?internalWalletAddress=${internalAount}&&externalWalletAddress=${account}`,
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //     };

        //     axios.request(config)
        //         .then((response) => {

        //             setTimeout(() => {
        //                 Btnclick('btnthree');
        //                 setLoader(false)
        //             }, "10000");
        //             setSteptwo1(true);
        //             api()
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //             setLoader(false)
        //             toast.error(error.response.data.message);
        //         });

        // } else {
        //     toast.warning("please connect wallet");
        // }

    }

    const switchChain = async () => {
        if (chainId === 11155111) {
            stepTwoactive1()
        }
        else {
            let a = await setupNetwork()
            if (a) {
                stepTwoactive1()
            }
        }
    }

    const stepTwoactive1 = async () => {
        setLoader(true)
        try {
            if (account) {
                let q = await mintDop(Environment.Dop, 2, 1000)
                if (q) {
                    // setLoader(false)
                    apiForDop()

                }

            } else {
                toast.warning(t('check4'));
                setLoader(false)
            }
        }
        catch (err) {
            console.log(err, 'err in mint dop');
            // toast.error('The Sepolia netwrok appears to be busy. Try again later')
            setLoader(false)
        }

    }

    const [stepThree, setStepthree] = useState(false)
    const apiForAlltoken = async () => {

        const axios = require('axios');
        let data = JSON.stringify({
            "claim_Testnet_Assets": {
                "isCompleted": true
            },
            "sign": sign121,
            "internalWalletAddress": internalAount,
            "externalWalletAddress": account

        });

        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${API_URL}rewards/${id}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                Btnclick('btnthree');
                setLoader(false)
                Btnclick('btnfour');
                setStateusCheck('cahdfdfnged')
                window.location.reload()
                // api()
            })
            .catch((error) => {
                console.log(error);
                setLoader(false)
                toast.error(error.response.data.message);

            });


    }


    const switchChain1 = async () => {
        if (chainId === 11155111) {
            stepThreeactive()
        }
        else {
            let a = await setupNetwork()
            if (a) {
                stepThreeactive()
            }
        }
    }
    const stepThreeactive = async () => {
        setLoader(true)
        try {
            if (account) {
                let q = await mintAll()
                if (q) {
                    // setLoader(false)
                    apiForAlltoken()

                }

            } else {
                toast.warning(t('check4'));
                setLoader(false)
            }
        }
        catch (err) {
            console.log(err, 'err in mint dop');
            // toast.error('The Sepolia netwrok appears to be busy. Try again later')
            setLoader(false)
        }

        // if (account) {
        //     setLoader(true)
        //     let config = {
        //         method: 'get',
        //         maxBodyLength: Infinity,
        //         url: `${API_URL}rewards/getTokens?internalWalletAddress=${internalAount}&&externalWalletAddress=${account}`,
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //     };

        //     axios.request(config)
        //         .then((response) => {

        //             // console.log(response?.data?.data);
        //             // setStepthree(true);
        //             // api()
        //             setTimeout(() => {
        //                 setLoader(false)
        //                 Btnclick('btnfour');
        //                 setStateusCheck('cahdfdfnged')
        //                 // window.location.reload()
        //             }, "15000");
        //             // setStateusCheck('cahnged')
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //             setLoader(false)
        //             toast.error(error.response.data.message);
        //         });
        // } else {
        //     toast.warning("please connect wallet");
        // }


    }

    const [stepFour, setStepFour] = useState(false)

    const stepFouractive = () => {
        setDefaultt('deposit')
    }
    // console.log(,'cliamCheck');s

    const [stepFive, setStepFive] = useState(false)

    const stepFiveactive = () => {
        setDefaultt('transfer')
    }
    const [stepFive1, setStepFive1] = useState(false)

    const stepFiveactive1 = () => {
        setDefaultt('withdraw')
    }

    const [stepSix, setStepSix] = useState(false)
    const [lastStep, setLastStep] = useState(false)

    const stepSixactive = () => {
        setStepSix(true);
    }

    const [stepSeven, setStepSeven] = useState(false)

    const stepSevenactive = () => {
        // console.log('click me');
        // apiCall()

    }
    const balaceOf = async () => {
        var balance = await web3.eth.getBalance(account);
        // console.log(balance, 'balace');
        if (balance > 0) {

        }
        // {
        // if(){
        //     if(){
        //         if(){
        //             if(){
        //                 if(){


        //                 }
        //             }
        //         }

        //     }

        // }
        // }
    }
    const api = async () => {
        // console.log(account, 'accountaccount', internalAount);
        setLoader(true)
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${API_URL}rewards/walletaddress?internalWalletAddress=${internalAount}&&externalWalletAddress=${account}&&sign=${sign121}`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        axios.request(config)
            .then((response) => {
                setLoader(false)
                // new flow for new user
                let a = new Date(response?.data?.data?.updatedReward.createdAt)
                let b = new Date(1703116800 * 1000)
                if (response?.data?.data?.updatedReward) {
                    // new low added for creation date 
                    if (response?.data?.data?.updatedReward?.referrals > 3 && a >= b) {
                        setProgressBar(3)
                        setProgressBarPer(100)
                        Btnclick('final');
                        let v = response?.data?.data?.stepsCompletionCount
                        tepsCompletionCountPercent(v * 20)
                        stepsCompletionCount(v)

                    } else {
                        setProgressBar(response?.data?.data?.updatedReward?.referrals)
                        setProgressBarPer((response?.data?.data?.updatedReward?.referrals * 100) / 3)
                        // new low added for creation date 
                        let v = response?.data?.data?.stepsCompletionCount
                        tepsCompletionCountPercent(v * 20)
                        stepsCompletionCount(v)
                    }
                    if (response?.data?.data?.stepsCompletionCount >= 100) {
                        tepsCompletionCountPercent(2000)
                        stepsCompletionCount(100)
                    }
                    dispatch(SendStatus(response?.data?.data?.updatedReward?.decrypt_Assets?.isCompleted))
                    dispatch(WithdrawStatus(response?.data?.data?.updatedReward?.follow_Us_On_Twitter?.isCompleted))
                    dispatch(DepositeStatus(response?.data?.data?.updatedReward?.send_Assets?.isCompleted))
                    setUserCount(response?.data?.data?.updatedReward?.referrals)
                    setId(response?.data?.data?.updatedReward?._id)
                    setCompleteData(response?.data?.data?.updatedReward)
                    setCounter(response?.data?.data?.updatedReward?.completionStatus)
                    setBar(response?.data?.data?.updatedReward?.overallCompletionPercentage > 100 ? 100 : response?.data?.data?.updatedReward?.overallCompletionPercentage)

                    setStep8Class(response?.data?.data?.updatedReward?.threeInvites?.isCompleted)
                    setLinkUser(`https://doptest.dop.org?id=${response?.data?.data?.updatedReward.influencerId.referalCode}`)
                    if (response?.data?.data?.updatedReward?.claim_Sepolia?.isCompleted) {
                        setSteptwo(true)
                    }
                    if (response?.data?.data?.updatedReward?.claim_Sepolia?.isCompleted && response?.data?.data?.updatedReward?.claim_Testnet_Assets?.isCompleted && response?.data?.data?.updatedReward?.claim_Dop?.isCompleted) {

                        setStepthree(true)

                    }
                    if (response?.data?.data?.updatedReward?.claim_Dop?.isCompleted && response?.data?.data?.updatedReward?.claim_Sepolia?.isCompleted) {

                        setSteptwo1(true)
                    }
                    if (response?.data?.data?.updatedReward?.decrypt_Assets?.isCompleted && response?.data?.data?.updatedReward?.encrypt_Assets?.isCompleted && response?.data?.data?.updatedReward?.send_Assets?.isCompleted && response?.data?.data?.updatedReward?.claim_Sepolia?.isCompleted && response?.data?.data?.updatedReward?.claim_Testnet_Assets?.isCompleted) {
                        setStepSix(true);
                    }
                    if (response?.data?.data?.updatedReward?.follow_Us_On_Twitter?.isCompleted) {
                        setLastStep(true)

                    }
                    if (response?.data?.data?.updatedReward?.encrypt_Assets?.isCompleted && response?.data?.data?.updatedReward?.claim_Sepolia?.isCompleted && response?.data?.data?.updatedReward?.claim_Testnet_Assets?.isCompleted && response?.data?.data?.updatedReward?.claim_Dop?.isCompleted) {
                        setStepFour(true)
                    }
                    if (response?.data?.data?.updatedReward?.encrypt_Assets?.isCompleted && response?.data?.data?.updatedReward?.claim_Sepolia?.isCompleted && response?.data?.data?.updatedReward?.claim_Testnet_Assets?.isCompleted && response?.data?.data?.updatedReward?.claim_Dop?.isCompleted) {
                        setStepFive(true)
                    }
                    if (response?.data?.data?.updatedReward?.send_Assets?.isCompleted && response?.data?.data?.updatedReward?.encrypt_Assets?.isCompleted && response?.data?.data?.updatedReward?.claim_Sepolia?.isCompleted && response?.data?.data?.updatedReward?.claim_Testnet_Assets?.isCompleted && response?.data?.data?.updatedReward?.claim_Dop?.isCompleted) {
                        setStepFive1(true)
                    }
                    if (response?.data?.data?.updatedReward?.send_Assets?.isCompleted && response?.data?.data?.updatedReward?.encrypt_Assets?.isCompleted && response?.data?.data?.updatedReward?.claim_Sepolia?.isCompleted && response?.data?.data?.updatedReward?.claim_Testnet_Assets?.isCompleted && response?.data?.data?.updatedReward?.claim_Dop?.isCompleted && response?.data?.data?.updatedReward?.follow_Us_On_Twitter?.isCompleted && response?.data?.data?.updatedReward?.decrypt_Assets?.isCompleted) {
                        // setStepSeven(true);
                        if (!success121) {
                            // handleShow11()
                        }

                    }
                }


            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
                setLastStep(false)
                setId(null)
                setCompleteData(null)
                setBar(0)
                setCounter(0)
                setSteptwo(false)
                setStepthree(false)
                setStepSix(false);
                setSteptwo1(false)
                setStepSeven(false);
                setStepFive1(false)
                setStepFive(false)
                setStepFour(false)
            });

    }


    // useEffect(() => {
    //     if (account && internalAount) {
    //         api()
    //     } else {
    //         setLastStep(false)
    //         setId(null)
    //         setCompleteData(null)
    //         setBar(0)
    //         setCounter(0)
    //         setSteptwo(false)
    //         setStepthree(false)
    //         setStepSix(false);
    //         setSteptwo1(false)
    //         setStepSeven(false);
    //         setStepFive1(false)
    //         setStepFive(false)
    //         setStepFour(false)
    //     }
    //     // windoewwwww()
    //     // hrello()
    // }, [account, internalAount, stateusCheck, sign121])
    // console.log(internalAount,'account');





    // useEffect(() => {
    //     getSocket();
    // }, [])
    const getSocket = async () => {
        try {
            const socket = io("wss://socket.tdns.network", {
                reconnectionDelayMax: 1000,
            });
            socket.on('connect', function () {
            });
            const listener = (...args) => {

                // if (args?.tokenId === "emited") {
                // console.log(args,'args');
                setArg(...args)
                // Bids()
                // highestBidFun()
                // expirtyTimeFun()
            }
            // }
            socket.on("bidReceived", listener);
        } catch (ex) { }
    }
    //     const apiCall = async () => {
    //         setLoader(true)
    //         const axios = require('axios');
    //         let data = JSON.stringify({
    //             "follow_Us_On_Twitter":{
    //     "isCompleted":true
    //   }
    //         });

    //         let config = {
    //             method: 'patch',
    //             maxBodyLength: Infinity,
    //             url: `${API_URL}rewards/${id}`,
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             data: data
    //         };

    //         axios.request(config)
    //             .then((response) => {
    //                 // console.log(response.data,'nooooooor');
    //                 // localStorage.setItem('follow_Us_On_Twitter', true)
    //                 api()
    //                 setLoader(false)
    //                 handleClose2()
    //                 Btnclick('btnone');
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setLoader(false)
    //             });
    //     }





    // window.onGleamEvent = function (event) {
    //     // console.log(event,'noooooor');
    //     if (event?.action?.id === 7368856) {

    //         // setShow2(false)
    //         apiCall()

    //     }

    // };
    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = "https://widget.gleamjs.io/e.js";
    //     script.async = true;
    //     document.body.appendChild(script);
    //     return () => {
    //         document.body.removeChild(script);
    //     }
    // }, []);
    const apiCall = async () => {
        if (account) {
            setLoader(true)
            const axios = require('axios');

            let config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${API_URL}rewards/${id}/toggle-twitter?oauth_verifier=${oauthVerifier1}&oauth_token=${oauthToken1}`,
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            axios.request(config)
                .then((response) => {
                    // console.log(response.data,'nooooooor');
                    // localStorage.setItem('follow_Us_On_Twitter', true)
                    // toast.success("You have successfully follow DOP")
                    // api()
                    setLoader(false)
                    // handleCloseconfirmed()
                    const url = 'https://twitter.com/dop_org';
                    window.open(url, '_blank');
                    handleShowconfirmed()
                    // handleClose2()

                    // Btnclick('btnone');
                    // toast.error(error.response.data.message);
                })
                .catch((error) => {
                    console.log(error, 'nooor', error.response.data);
                    if (error.response.data.oauth_token) {
                        setOauthToken1(error.response.data.oauth_token)
                        setOauthVerifier1(error.response.data.oauth_token_secret)
                        const url = 'https://twitter.com/dop_org';
                        window.open(url, '_blank');
                        handleShowcode()
                        setLoader(false)

                    }
                    else {
                        setTwitterAutization(false)
                        setLoader(false)
                        toast.error(error.response.data.message);
                    }





                });
        } else {
            toast.warn(t('check4'))
        }
    }
    const apiCall1 = async (e) => {
        if (account) {
            let data = JSON.stringify({
                "oauth_token": oauthToken1,
                "oauth_token_secret": oauthVerifier1,
                "recaptcha": e,
            });
            setLoader(true)
            const axios = require('axios');

            let config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${API_URL}rewards/${id}/recaptcha/toggle-twitter`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    // console.log(response.data,'nooooooor');
                    // localStorage.setItem('follow_Us_On_Twitter', true)
                    // toast.success("You have successfully follow DOP")
                    // api()
                    // setLoader(false)
                    // handleCloseconfirmed()
                    window.location.reload()
                    // handleClose2()

                    // Btnclick('btnone');
                    // toast.error(error.response.data.message);
                })
                .catch((error) => {
                    console.log(error, 'nooor', error.response.data);
                    toast.error(error.response.data.message);
                    setTwitterAutization(false)
                    setLoader(false)
                    handleClosecode()


                });
        } else {
            toast.warn(t('check4'))
        }
    }



    const [selectedItem, setSelectedItem] = useState(null);
    const items = [internalAount]

    const addToMEtamask = async () => {
        await (window).ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: dopToken,
                    symbol: 'DOP',
                    decimals: 18,
                    image: imgeOfToken,
                },
            },
        })
    }
    const addToMEtamask1 = async () => {
        await (window).ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: addrssOfToken,
                    symbol: symbolOfToken,
                    decimals: symbolOfToken === 'DOP_USDT' || symbolOfToken === 'DOP_USDC' ? 6 : 18,
                    image: imgeOfToken,
                },
            },
        })
    }

    const [clickedButton, setClickedButton] = useState(localStorage.getItem('clickedButton') || null);
    // useEffect(() => {
    //     if (clickedButton === 'btnone') {
    //         const element = document.querySelector('.maintwo');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     } else if (clickedButton === 'btntwo') {
    //         const element = document.querySelector('.mainthree');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     } else if (clickedButton === 'btnthree') {
    //         const element = document.querySelector('.mainfour');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     } else if (clickedButton === 'btnfour') {
    //         const element = document.querySelector('.mainfive');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    //     else if (clickedButton === 'btnfour21') {
    //         const element = document.querySelector('.lasssstClas');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    //     else if (clickedButton === 'btnfive') {
    //         const element = document.querySelector('.mainsix');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    //     else if (clickedButton === 'btnseven') {
    //         const element = document.querySelector('.maineight');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    //     else if (clickedButton === 'btnsix') {
    //         const element = document.querySelector('.mainseven');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    //     else if (clickedButton === 'final') {
    //         const element = document.querySelector('.cvvddv');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    // }, [clickedButton]);
    // useEffect(() => {
    //     if (clickedButton === 'btnone') {
    //         const element = document.querySelector('.maintwo');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     } else if (clickedButton === 'btntwo') {
    //         const element = document.querySelector('.mainthree');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     } else if (clickedButton === 'btnthree') {
    //         const element = document.querySelector('.mainfour');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     } else if (clickedButton === 'btnfour') {
    //         const element = document.querySelector('.mainfive');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    //     else if (clickedButton === 'btnfour21') {
    //         const element = document.querySelector('.lasssstClas');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    //     else if (clickedButton === 'btnfive') {
    //         const element = document.querySelector('.mainsix');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    //     else if (clickedButton === 'btnseven') {
    //         const element = document.querySelector('.maineight');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    //     else if (clickedButton === 'btnsix') {
    //         const element = document.querySelector('.mainseven');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    //     else if (clickedButton === 'final') {
    //         const element = document.querySelector('.cvvddv');
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    // }, [completeData]);
    const Btnclick = (buttonName) => {
        setClickedButton(buttonName);
        if (buttonName == 'btnfour21') {
            return
        } else {
            localStorage.setItem('clickedButton', buttonName); // Save the clicked button to local storage
        }
    };

    const [show11, setShow11] = useState(false);
    // console.log(show11,'show11show11');

    const handleClose11 = async () => {
        // e?.preventDefault()
        // if (checkedNew1){
        // let data = JSON.stringify({
        //     email : inputFeild6
        // });
        // setLoader(true)
        // let config = {
        //     method: 'post',
        //     maxBodyLength: Infinity,
        //     url: `${API_URL}rewards/update/email/${iddd}`,
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     data: data
        // };

        // axios.request(config)
        //     .then((response) => {
        // console.log(response, 'response');
        // setLoader(false)

        // localStorage.setItem("success", true)
        setShow11(false)

        setCopyy(true);
        setTimeout(() => {
            Btnclick('btnfour21');
        }, 1000);
        // window.location.reload()
        // })
        // .catch((error) => {
        //     console.log(error);
        //     toast.error(error.response.data.message);
        //     setLoader(false)
        // });
    }
    // };
    const handleShow11 = () => setShow11(true);
    const onSuccess = (response) => {
        // console.log("respnce", response?.url)
        const parsedUrl = new URL(response?.url);

        // Get the search parameters from the URL
        const searchParams = parsedUrl.searchParams;

        // Get the values of oauth_verifier and oauth_token
        const oauthVerifier = searchParams.get('oauth_verifier');
        const oauthToken = searchParams.get('oauth_token');
        // if(oauthVerifier != '' & oauthToken != ''){
        //     getvalueUserid(oauthVerifier, oauthToken)
        // }
        // console.log('OAuth Verifier:', oauthVerifier);
        // console.log('OAuth Token:', oauthToken);
        if (response.status === 200) {
            setTwitterAutization(true)
            setOauthVerifier1(oauthVerifier)
            setOauthToken1(oauthToken)
            // toast.success("X authorized accessfully")

            // } else if (response.status == 400) {
            //     toast.error('X account must be created 30 days before')
            // } else {
            //     toast.error('Something went wrong')
            // }

        }
        // Handle the authentication success here
    };

    const onFailed = (error) => {
        // console.error('Twitter Auth Error:', error);
        toast.error(t('check5'))
        // setRestTweeter(false)
        // Handle the authentication error here
    };
    // const inputField1Handler2 = (e) => {
    //     if (e.target.checked == true) {
    //         setCheked1(true)
    //     } else {
    //         setCheked1(false)
    //     }
    // }
    const checkFollow = () => {
        let data = JSON.stringify({
            "oauth_token": twiterToken,
            "oauth_token_secret": authVarifier
        });
        setLoader(true)
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `http://192.168.20.95:8000/rewards/check-follow`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                // console.log(response, 'response');
                setLoader(false)
                // console.log(response.data,'nooooooor');
                // localStorage.setItem('follow_Us_On_Twitter', true)
                // apiCall()
            })
            .catch((error) => {
                console.log(error);
                toast.error(error.response.data.message);
                setLoader(false)
            });
    }
    const refrushCode = async () => {
        await deleteAllData()
        localStorage.clear();
        // localStorage.setItem('refreshA1',false)
        window.location.pathname = '/';
    }

    const inputField1Handler5 = (e) => {
        let value = e.target.value;
        if (!isValidEmail(value)) {
            setInputFeild6(value);
            setCheckedNew1(false)
        } else {
            setInputFeild6(value);
            setCheckedNew1(true)
        }

    };
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    const inputField1Handler2 = (e) => {
        if (e.target.checked == true) {
            setCheked1(true)
        } else {
            setCheked1(false)
        }
    }

    function onChange(value) {
        setCapcha(value)
        // console.log("Captcha value:", value);
        apiCall1(value)
    }
    //  const onSubmit = () => {
    //     const recaptchaValue = recaptchaRef.current.getValue();
    //     onSubmit(recaptchaValue);
    //   }

    const [showconfirmed, setShowconfirmed] = useState(false);

    const handleCloseconfirmed = () => setShowconfirmed(false);
    const handleShowconfirmed = () => setShowconfirmed(true);

    const [showcode, setShowcode] = useState(false);

    const handleClosecode = () => setShowcode(false);
    const handleShowcode = () => setShowcode(true);


    const twiterPost = async () => {
        const tweetUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(linkUser)}&text=${encodeURIComponent(linkText)}`;
        window.open(tweetUrl, '_blank');
        clickApi('copyTwitter')
    }
    const wahtsapp = async () => {
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(`${linkText} ${linkUser}`)}`;
        window.open(whatsappUrl, '_blank');
        clickApi('copyWhatsapp')
    }
    const facebook = async () => {
        const facebookUrl = `https://www.facebook.com/dialog/share?app_id=YOUR_APP_ID&display=popup&href=${encodeURIComponent(linkUser)}&quote=${encodeURIComponent(linkText)}`;
        window.open(facebookUrl, '_blank');
        clickApi('copyFaceBook')
    }
    const taligram = async () => {
        const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(linkUser)}&text=${encodeURIComponent(linkText)}`;

        // Open the Telegram share link in a new window
        window.open(shareUrl, '_blank');
        clickApi('copyTelegram')
    }
    const line = () => {
        // Construct the LINE share URL
        const shareUrl = `https://line.me/R/msg/text/?${encodeURIComponent(`${linkText} ${linkUser}`)}`;

        // Open the LINE share link in a new window
        window.open(shareUrl, '_blank');
        clickApi('copyLine')
    }
    const kakao = () => {
        if (!kakaoLocal) {
            console.log('is kakao initialized', window.Kakao.isInitialized(), kakaoLocal);
            localStorage.setItem('kakao', true)
            window.Kakao.init("a89e530b367e35f6cf851f73842679f2");
        }
        window.Kakao.Share.sendDefault({
            objectType: 'text',
            text: `${linkText}`,
            link: {
                // Must match the site domain registered in [My Application] > [Platform]
                mobileWebUrl: 'https://doptest.dop.org',
                webUrl: 'https://doptest.dop.org',
            },

            buttons: [
                {
                    title: 'View on Web',
                    link: {
                        mobileWebUrl: `${linkUser}`,
                        webUrl: `${linkUser}`,
                    },
                }
            ],
        });

        clickApi('copyTalk')
    }

    const wechat = () => {
        const weChatShareUrl = `weixin://send?text=${encodeURIComponent(linkText)}&url=${encodeURIComponent(linkUser)}`;

        // Open the WeChat share link in a new window
        window.open(weChatShareUrl, '_blank');
        clickApi('copyVchat')
    }
    const clickApi = async (asynchello) => {
        // console.log(asynchello,'asynchello');
        // let sa= asynchello
        const axios = require('axios');
        let data = {
            [asynchello]: true

        };

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${API_URL}rewards/click`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                // console.log(response,'noooooor');
            })
            .catch((error) => {
                console.log(error);


            });



    }
    var languageselected = localStorage.getItem('codelanguage')

    const [showdisclaimer, setShowdisclaimer] = useState(false);
    const handleClosedisclaimer = () => {
        localStorage.setItem("important",true)
        setShowdisclaimer(false)};
    const handleShowdisclaimer = () => setShowdisclaimer(true);

    useEffect(()=>{
        if(!nodataModel){
            handleShowdisclaimer()
        }
    },[])
    return (

        <>
            {loaderr &&

                <Loader text={t('')} />
            }
            <section className={languageselected == "ar" ? "earnrewards cvvddv direction-for-arabic" : "earnrewards cvvddv"}>
                <div className="earnheadings">
                    <div>
                        <h6 className="earnhead">Thank you for participating in DOP testnet</h6>
                        <p className="earnpara">DOP Mainnet will be launching soon.</p>

                    </div>
                    {/* <button className="redbtnreset" onClick={refrushCode}>Reset Progress</button> */}
                </div>
                <div className="newboxes newboxesfinal">
                    <div className="earnprogressbox">

                        <div className="newfinal-content">
                            <p className='para-text'>While the rewards for completion are no longer available, we invite you to continue exploring DOP's core capabilities. Test to your heart's content and help us refine the future of data ownership on the blockchain.</p>
                            <p className="orange-text">The reward phase of the testnet is over</p>
                        </div>
                    </div>

                    <div className="invitenewbox">
                        <div className="right-new-final">
                            <h6>Testnet Rewards Claim</h6>
                            <p>Eligible users will be able to claim their rewards here. Stay tuned. </p>
                            <a href="#" className='btn-claim'>CLAIM</a>
                        </div>
                    </div>



                </div>
                <div className="after-v5-new-ui">
                    <div className="inner-box">
                        <h6>Things that you can still do</h6>
                        <p>You can encrypt, decrypt, send your private assets and experience the DOP testnet.</p>
                        <div className="twice-btns">
                            <a onClick={stepFouractive}>Encrypt</a>
                            <a onClick={stepFiveactive}>SEND</a>
                            <a onClick={stepFiveactive1}>DECRYPT</a>
                        </div>
                    </div>
                    <div className="inner-box">
                        <h6>Need some more test tokens?</h6>
                        <p>You can still claim test tokens to do transactions with.</p>
                        <div className="twice-btns">
                            <a href="https://www.alchemy.com/faucets/ethereum-sepolia" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M10.999 0.500224C10.6664 0.500214 10.6641 0.989701 11.0009 1.0007C12.9436 1.0007 14.6018 2.39455 14.9392 4.30781C15.2765 6.22109 14.1951 8.09782 12.3695 8.76229C10.5784 9.41423 8.58195 8.70919 7.58998 7.09339L8.02942 7.21106C8.35523 7.30356 8.48725 6.81033 8.15881 6.72768L7.13444 6.45278C6.97502 6.41631 6.8594 6.51628 6.8283 6.63002L6.55342 7.65393C6.46092 7.97975 6.95415 8.11179 7.03679 7.78331L7.15543 7.33948C8.26555 9.16881 10.5201 9.96552 12.5394 9.23054C14.5899 8.48422 15.8102 6.37083 15.4313 4.22188C15.0452 1.99192 13.0736 0.482033 10.999 0.500224ZM5.98216 9.50047C5.59438 9.50047 4.90398 9.76193 4.35578 10.0244C4.24852 10.0757 4.18558 10.1108 4.09261 10.1572C4.18965 10.3349 4.24983 10.5356 4.24983 10.7514V14.1214C4.56819 14.066 4.89114 14.015 5.25075 14.0013C5.55979 14.0013 6.13746 14.4393 6.51289 14.7044C7.04788 15.0821 7.62415 15.4998 8.24912 15.4998C8.43724 15.4998 8.62621 15.4379 8.87506 15.345C10.2427 14.8338 13.1274 13.2017 14.8078 12.2187C15.233 11.9835 15.3727 11.439 15.1325 11.0229C14.902 10.6237 14.4342 10.5265 13.9793 10.6816L10.8403 11.7375C10.8628 11.9417 10.8262 12.1534 10.7163 12.3439C10.4414 12.8199 9.82662 13.0154 9.35062 12.7406L7.83361 11.8657C7.52001 11.7056 7.79033 11.2407 8.08457 11.434L9.59962 12.3085C9.84325 12.4492 10.142 12.3695 10.2827 12.1259C10.4233 11.8823 10.3433 11.584 10.0996 11.4433C8.984 10.7992 7.00351 9.50047 5.98216 9.50047ZM1.25142 10.0005C0.830604 10.0005 0.5 10.3407 0.5 10.7519V14.2504C0.5 14.6599 0.830829 15.0013 1.24654 15.0013H3.00088C3.45735 15.0013 3.74986 14.6135 3.74986 14.2499V10.7514C3.74986 10.3313 3.41004 10.0005 3.00137 10.0005H1.25142ZM1.75091 13.5009C1.88897 13.5009 2.00089 13.6128 2.00089 13.7509C2.00089 13.8889 1.88897 14.0008 1.75091 14.0008C1.61284 14.0008 1.50092 13.8889 1.50092 13.7509C1.50092 13.6128 1.61284 13.5009 1.75091 13.5009Z" fill="white" />
                            </svg>Claim Test Tokens</a>
                        </div>
                    </div>
                    <div className="inner-box">
                        <h6>Stay tuned in the official DOP community channels</h6>
                        <p>Follow DOP for the latest updates and announcements.</p>
                        <div className="twice-btns">
                            <a  href="https://twitter.com/dop_org" target="_blank">follow us on <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M0.597765 1L6.34172 8.72183L0.561493 15H1.86239L6.92296 9.50337L11.0118 15H15.4388L9.37164 6.84383L14.7518 1H13.4509L8.79039 6.06229L5.02477 1H0.597765ZM2.51083 1.96344H4.5446L13.5254 14.0364H11.4916L2.51083 1.96344Z" fill="white" />
                            </svg></a>
                            <a  href="https://t.me/Dop_org" target="_blank">join us on <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M2.04014 7.14054C5.74991 5.39553 8.22367 4.24511 9.46142 3.68928C12.9955 2.10229 13.7298 1.82661 14.2084 1.81741C14.3137 1.8155 14.5491 1.84367 14.7016 1.97724C14.8303 2.09003 14.8657 2.24238 14.8827 2.34931C14.8996 2.45625 14.9207 2.69984 14.9039 2.89018C14.7124 5.06265 13.8838 10.3347 13.4622 12.7679C13.2838 13.7975 12.9326 14.1427 12.5925 14.1765C11.8535 14.2499 11.2924 13.6492 10.5766 13.1426C9.45658 12.35 8.82384 11.8565 7.73667 11.083C6.48025 10.1892 7.29474 9.69785 8.01076 8.89492C8.19815 8.68479 11.4542 5.4873 11.5172 5.19723C11.5251 5.16096 11.5324 5.02573 11.458 4.95433C11.3836 4.88293 11.2738 4.90734 11.1945 4.92676C11.0822 4.95429 9.29295 6.2311 5.8268 8.75719C5.31893 9.13371 4.85891 9.31716 4.44676 9.30755C3.99239 9.29695 3.11836 9.03018 2.46862 8.80215C1.67167 8.52247 1.03828 8.3746 1.09344 7.8996C1.12216 7.6522 1.43773 7.39918 2.04014 7.14054Z" fill="white" />
                            </svg></a>

                        </div>
                    </div>
                </div>
            </section>



            <Modal className={languageselected == "ar" ? "important-note verifymodal direction-for-arabic" : "important-note verifymodal"} show={showcode} onHide={handleClosecode} centered>
                <Modal.Body>
                    <h6 className="codehead">{t('human')}</h6>
                    <div className="captchamain">
                        {/* add capctha  */}

                        <ReCAPTCHA
                            sitekey="6LeXFjYpAAAAAJpYzCIqwoZ7rMaP0KMawdRh_ji5"
                            onChange={onChange}
                            className="recaptchaframe"
                        />

                    </div>
                </Modal.Body>
            </Modal>
            <Modal className={languageselected == "ar" ? "important-note direction-for-arabic" : "important-note"} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('Landing-Step1-T1')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="importantparaimg">


                        <div>

                            {/* Other content */}
                        </div>


                        {/* <img src="\Assets\tpldashboard\earnrewards\lightimg.svg" alt="claimimg" className="claimimg dark" />
                        <img src="\Assets\tpldashboard\earnrewards\darkimg.svg" alt="claimimg" className="claimimg white" /> */}
                        {/* <p className="claimpara">Explore DOP Testnet and earn rewards.</p> */}
                    </div>
                    {/* <button className='continuebutton' onClick={() => {
                        // localStorage.setItem("model2", true)
                        // handleClose2();
                    }}>Start earning rewards</button> */}
                </Modal.Body>
            </Modal>
            {/* <a  class="e-widget no-button generic-loader" href="https://gleam.io/mt9FW/dop-token"  target="_blank"rel="nofollow">DOP Token</a> */}
            {/* <script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true"></script> */}


            {/* <button className="asdadasdsada" onClick={waitinglistcreate}><h6 className="mr-3">Continue  </h6><img src="/whitlist/arrow-right.png" className="img-fluid " alt="" /></button> */}

            {/* new custom modal here................ */}
            {/* <button onClick={handleShow2}>df</button> */}
            <div class={show2 ? "custom-modal custom-show-modal forzindex" : "custom-modal forzindex"}>
                <div class="custom-modal-content">
                    <div className="inner-set">
                        <div className="left">
                            <h6>{t('Landing-Step1-T1')}</h6>
                        </div>
                        <div className="right">
                            <img onClick={handleClose2} src="\Assets\import-assets\close-circledark.svg" alt="img" className='img-fluid dark' />
                            <img onClick={handleClose2} src="\Assets\import-assets\close-circlewhite.svg" alt="img" className='img-fluid white' />
                        </div>
                    </div>
                    <div className="main-body">
                        {/* <p className='twitterpara'>Click the button below to follow @dop_org on X (Twitter).</p> */}
                        <div className="twitterbutton mb-4 mt-4">

                            <a id="speicalAZ239" target="_blank" href="https://twitter.com/dop_org">
                                <button className="lowewrbtn">
                                    {t('Landing-Step1-T1')}
                                    <img src="\Assets\twitterbtnwhite.svg" alt="img" className='img-fluid white ' />
                                    <img src="\Assets\Frame8.png" alt="img" className='img-fluid dark ' />
                                </button>
                            </a>


                            <div className="checkboxmain">
                                <div class="content">
                                    <label class="checkBox">
                                        <input type="checkbox" id="ch1" onChange={inputField1Handler2} />
                                        <div class="transition"></div>
                                    </label>
                                </div>
                                <p className="checkboxpara">{t('follow-X')}.</p>
                            </div>
                            <p className='attentionpara'>Attention: A snapshot will be taken at an undisclosed date and time before the token launch. Users who do not follow us at that moment will not be eligible for the testnet reward.</p>
                            {cheked1 ?
                                <button id="speicalAZ240" type="submit" onClick={apiCall} className='lowewrbtn mb-0'>
                                    {t('route-landing-TermCheckbox-B1')}
                                </button>
                                :
                                <button id="speicalAZ241" type="submit" className='blackbtn'>
                                    {t('route-landing-TermCheckbox-B1')}
                                </button>

                            }

                        </div>






                        {/* <button className="btn-done" onClick={apiCall}>Done</button> */}
                        {/* <button className="btn-done" onClick={checkFollow}>Done</button> */}



                        {/* <a class="e-widget no-button generic-loader" href="https://gleam.io/mt9FW/dop-token" rel="nofollow">DOP Token</a> */}
                    </div>
                </div>
            </div>
            {/* <button onClick={handleShow11}>dfs</button> */}
            <Modal className={languageselected == "ar" ? "important-note congratulationmodal direction-for-arabic" : "important-note congratulationmodal"} show={show11} onHide={handleClose11} centered >
                <Modal.Body>
                    {/* <form onSubmit={(e) => handleClose11(e)} className="w-100" > */}
                    <div className="borderdiv">
                        <img src="\Assets\doneblack.svg" alt="congratulationimg" className="congratulationimg white" />
                        <img src="\Assets\Donewhite.svg" alt="congratulationimg" className="congratulationimg dark" />
                    </div>
                    <h6 className="congratulationhead">{t('almost-there')}...</h6>
                    <p className="conratulationpara">
                        {t('almost-there-T1')} <span className='green'> {t('almost-there-T2')} . </span>

                        <br />          <br />
                        {t('Landing-Step8-T3')}:
                    </p>
                    <div className="blackmodalbox">
                        <p className="conratulationparaaa">
                            {/* refralCode */}
                            {copy ? (
                                <h6 className="adadasdasdasdasdasdsad xfgdfghfg">
                                    {linkUser?.slice(0, 10)}...
                                    {linkUser?.slice(linkUser.length - 5, linkUser.length)}{" "}
                                    {t('copied')}
                                </h6>
                            ) : (
                                <>
                                    {linkUser && (
                                        <CopyToClipboard text={linkUser} onCopy={textCopiedFun2}>
                                            <h6 className="adadasdasdasdasdasdsad">
                                                {linkUser?.slice(0, 15)}...
                                                {linkUser?.slice(
                                                    linkUser.length - 5,
                                                    linkUser.length
                                                )}{" "}
                                                <img style={{ cursor: "pointer" }} src="\Assets\copy.svg" alt="img" className='img-fluid' />
                                            </h6>
                                        </CopyToClipboard>
                                    )}
                                </>
                            )}
                        </p>
                    </div>
                    {/* <h6 className="congratulationhead">Enter your email to finalize the test</h6> */}

                    {/* <div className="material-textfield" style={{ margin: '30px 0px' }}>
                        <input placeholder="Enter your email address"
                            onChange={inputField1Handler5}
                            value={inputFeild6}
                            type="text"
                        />
                        <label>Email Address</label>
                    </div> */}
                    {/* <div className="socialsmain">
                        <div className="socialicons">
                            <a href="https://dop.org/" target='blank' className='scoialiconlick'>
                                <img src="\Assets\congratulation\webdark.svg" alt="img" className='dark' />
                                <img src="\Assets\congratulation\weblight.svg" alt="img" className='white' />
                            </a>
                            <a href="https://twitter.com/dop_org" target='blank' className='scoialiconlick'>
                                <img src="\Assets\congratulation\twitternewlogodark.svg" alt="img" className='dark' />
                                <img src="\Assets\congratulation\twitternewlogolight.svg" alt="img" className='white' />
                            </a>
                            <a href="https://t.me/dop_org" target='blank' className='scoialiconlick'>
                                <img src="\Assets\congratulation\telegramdark.svg" alt="img" className='dark' />
                                <img src="\Assets\congratulation\telegramlight.svg" alt="img" className='white' />
                            </a>
                            <a href="https://medium.com/dop-org" target='blank' className='scoialiconlick'>
                                <img src="\Assets\congratulation\mediumdark.svg" alt="img" className='dark' />
                                <img src="\Assets\congratulation\mediumlight.svg" alt="img" className='white' />
                            </a>
                            <a href="https://www.youtube.com/@DataOwnershipProtocol" target='blank' className='scoialiconlick'>
                                <img src="\Assets\congratulation\YouTubedark.svg" alt="img" className='dark' />
                                <img src="\Assets\congratulation\YouTubedark.svg" alt="img" className='white' />
                            </a>
                        </div>

                    </div> */}

                    <div className="newsocialicons white">
                        <a onClick={taligram}>
                            <img src="\Assets\stepeightimages\telegramlighttheme.png" alt="scoialiconimg" className="scoialiconimg" />
                        </a>
                        <a onClick={twiterPost}>
                            <img src="\Assets\stepeightimages\twitterlighttheme.png" alt="scoialiconimg" className="scoialiconimg" />
                        </a>
                        <a onClick={wahtsapp}>
                            <img src="\Assets\stepeightimages\whatsapplighttheme.png" alt="scoialiconimg" className="scoialiconimg" />
                        </a>
                        {/* <a onClick={wechat}>
                                        <img src="\Assets\stepeightimages\wechatlighttheme.png" alt="scoialiconimg" className="scoialiconimg" />
                                    </a> */}
                        {/* <a onClick={facebook}>
                                        <img src="\Assets\stepeightimages\messengerlighttheme.png" alt="scoialiconimg" className="scoialiconimg" />
                                    </a> */}
                        <a onClick={line}>
                            <img src="\Assets\stepeightimages\linelighttheme.png" alt="scoialiconimg" className="scoialiconimg" />
                        </a>
                        <a onClick={kakao}>
                            <img src="\Assets\stepeightimages\talklighttheme.png" alt="scoialiconimg" className="scoialiconimg" />
                        </a>
                    </div>


                    <div className="newsocialicons dark">
                        <a onClick={taligram}>
                            <img src="\Assets\stepeightimages\telegramdarktheme.png" alt="scoialiconimg" className="scoialiconimg" />
                        </a>
                        <a onClick={twiterPost}>
                            <img src="\Assets\stepeightimages\twitterdarktheme.png" alt="scoialiconimg" className="scoialiconimg" />
                        </a>
                        <a onClick={wahtsapp}>
                            <img src="\Assets\stepeightimages\whatsappdarktheme.png" alt="scoialiconimg" className="scoialiconimg" />
                        </a>
                        {/* <a onClick={wechat}>
                                        <img src="\Assets\stepeightimages\wechatdarktheme.png" alt="scoialiconimg" className="scoialiconimg" />
                                    </a> */}
                        {/* <a onClick={facebook}>
                                        <img src="\Assets\stepeightimages\messengerdarktheme.png" alt="scoialiconimg" className="scoialiconimg" />
                                    </a> */}
                        <a onClick={line}>
                            <img src="\Assets\stepeightimages\linedarktheme.png" alt="scoialiconimg" className="scoialiconimg" />
                        </a>
                        <a onClick={kakao}>
                            <img src="\Assets\stepeightimages\talkdarktheme.png" alt="scoialiconimg" className="scoialiconimg" />
                        </a>

                    </div>
                    {/* <button onClick={handleClose11} className='btn-common'>Done</button> */}

                    {/* {checkedNew1  ? */}


                    <button id="speicalAZ242" type='submit' onClick={handleClose11} className='btn-common'>{t('route-Congrats-Impt-Note-B1')}</button>
                    {/* :
<button className='blackbtn'>Done</button> */}




                    {/* </form> */}
                </Modal.Body>
            </Modal>

            <Modal className={languageselected == "ar" ? "important-note confirmedmodal direction-for-arabic" : "important-note confirmedmodal"} show={showconfirmed} onHide={handleCloseconfirmed} centered backdrop='static'>
                <Modal.Body>
                    <h6 className="confirmtophead">{t('conform-Follow')}</h6>
                    <div className="greenishbox">
                        <p className="greenishpara">
                            {t('attention')}.
                        </p>
                    </div>

                    <div className="checkboxmain">
                        <div class="content">
                            <label class="checkBox">
                                <input type="checkbox" id="ch1" onChange={inputField1Handler2} />
                                <div class="transition"></div>
                            </label>
                        </div>
                        <p className="checkboxpara">{t('follow-X')}.</p>
                    </div>
                    {cheked1 ?
                        <button id="speicalAZ243" type="submit" onClick={() => {
                            handleCloseconfirmed()
                            api()
                        }} className='donebtn mb-0'>
                            {t('route-Congrats-Impt-Note-B1')}
                        </button>
                        :
                        <button type="submit" className='blackbtn'>
                            {t('route-Congrats-Impt-Note-B1')}
                        </button>
                    }


                </Modal.Body>
            </Modal>



            <Modal className="modal-showstart modal-finalclaim" show={showdisclaimer} onHide={handleClosedisclaimer} centered backdrop="static"
                keyboard={false}>
                <Modal.Header>
                    <Modal.Title>
                        <img src="\Assets\disclaimer.svg" alt="disclaimerimg" className="disclaimerimg" />
                        Important Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="finalannounce">
                        <h5>Thank you for participating in DOP testnet</h5>
                        <h6>While the rewards for completion are no longer available, we invite you to continue exploring DOP's core capabilities. Test to your heart's content and help us refine the future of data ownership on the blockchain.</h6>
                    
                    </div>

                    <button className='btn-okay' onClick={handleClosedisclaimer}>
                        Okay
                    </button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Earnrewards