import React, { useEffect, useState } from 'react'
import "./transaction.scss"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Deposit from './Deposit';
import Transfer from './Transfer';
import Withdraw from './Withdraw';
import ApproveDop from '../../../hooks/dataFetchers/appriveDop.js';
import Environment from "../../../utils/Environment";
import AllowanceTpl from '../../../hooks/dataFetchers/AllowanceTpl';
import { useWeb3React } from "@web3-react/core";
import { GetUsers } from '../../../redux/action';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import useWeb3 from '../../../hooks/useWeb3';
const Transaction = ({itemsAssets, internalAount, userBalance, tab,stateusCheck ,setStateusCheck,setDefaultt,defalttt}) => {
    let v =useSelector((state)=>state?.UserReducer?.allowance)
    const web3 = useWeb3();
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const [selectedItemAsset, setSelectedItemAsset] = useState("");
    const [allowance, setAlowance] = useState(null)
    const [rend, setrend] = useState(false)
    // console.log(v,'compo');
    const { allowanceTpl } = AllowanceTpl();
    const { account } = useWeb3React();
    // console.log(userBalance,'userBalance');
              
    const alowence = async (e) => {
        try {
            // console.log(e);
            let r = await allowanceTpl(Environment.Dop)
            // console.log(r, 'noooor');
            if (r) {
                dispatch(GetUsers(r/10**18))
            }
        }
        catch (err) {
            console.log(err.message, 'noor');
        }
    }

    useEffect(()=>{
        if(account&&web3)
            alowence()
            // account
    },[web3])
    var languageselected = localStorage.getItem('codelanguage')
    return (
        <>
            <section className={languageselected == "ar" ? "transaction direction-for-arabic" : "transaction"}>

                {tab === 'deposit' &&
                    <>
                        <h5 className='Transfer'>{t('Sidebar-Transaction-H1')} {selectedItemAsset}</h5>
                        <Deposit setSelectedItemAsset={setSelectedItemAsset} selectedItemAsset={selectedItemAsset} internalAount={internalAount} userBalance={userBalance} stateusCheck={stateusCheck} defalttt={defalttt} />
                    </>


                }
                {tab === 'transfer' &&
                <>
                       <h5 className='Transfer'>{t('Sidebar-Transaction-H2')}</h5>
                    <Transfer setSelectedItemAsset={setSelectedItemAsset} selectedItemAsset={selectedItemAsset} itemsAssets={itemsAssets} internalAount={internalAount}  userBalance={userBalance} setStateusCheck={setStateusCheck} setDefaultt={setDefaultt} defalttt={defalttt}  />
                    </>

                }
                {tab === 'withdraw' &&
                <>
                <h5 className="Transfer">{t('Sidebar-Transaction-H3')} {selectedItemAsset}</h5>
                <Withdraw setSelectedItemAsset={setSelectedItemAsset} selectedItemAsset={selectedItemAsset} itemsAssets={itemsAssets} internalAount={internalAount}  userBalance={userBalance} setStateusCheck={setStateusCheck} setDefaultt={setDefaultt} defalttt={defalttt}/>
                </>


                }
                {/* <Tab title="Transfer">
                        <Transfer userBalance={userBalance} />
                    </Tab>
                    <Tab  title="Withdraw">
                      <Withdraw userBalance={userBalance}  />
                    </Tab> */}
            </section>
        </>
    )
}

export default Transaction
