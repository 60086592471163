import React from 'react'
import "./banner.scss";
import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import Navbar1 from '../header/navbar1';
import { useHistory } from 'react-router-dom';

const WelcomeDop = () => {
    const history = useHistory();
    const { t } = useTranslation()
    const [token, setToken] = useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [inputFeild6, setInputFeild6] = useState(null);
    const [checkedNew1, setCheckedNew1] = useState(false)
    const [showdisclaimer1, setShowdisclaimer1] = useState(false);
    const handleClosedisclaimer1 = () => setShowdisclaimer1(false);
    const handleShowdisclaimer1 = () => setShowdisclaimer1(true);
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    const inputField1Handler5 = (e) => {
        let value = e.target.value;
        if (!isValidEmail(value)) {
            setInputFeild6(value);
            setCheckedNew1(false)
        } else {
            setInputFeild6(value);
            setCheckedNew1(true)
        }

    };

    const modallhandler = async (e) => {
        e?.preventDefault()
        // const token = await executeRecaptcha('your_action_name'); // Replace with your specific action name
        // console.log('reCAPTCHA token:', token);
        if (cheked1 && checkedNew1) {
            localStorage.setItem('email', inputFeild6)
            localStorage.setItem('modal', true)
            // handleClosedisclaimer1()
            history.push('/')
            // window.location.reload()
        }
    }
    const [cheked1, setCheked1] = useState(false)
    const [cheked2, setCheked2] = useState(false)
    const inputField1Handler2 = (e) => {
        if (e.target.checked == true) {
            setCheked1(true)
        } else {
            setCheked1(false)
        }
    }
    var languageselected = localStorage.getItem('codelanguage')
    return (
        <>
            <Navbar1 />
            <section className={languageselected == "ar" ? "welcome-dop direction-for-arabic" : "welcome-dop"}>
                <div className="parent-welcome">
                    <div className="upper-content">
                        <h4>{t('Welh1')} </h4>
                        {/* <p>{t('welp1')}</p>
                        <p>{t('welp2')}</p> */}
                        {/* <div className="twice-link">
                            <a target='_blank' href="https://medium.com/dop-org/how-to-use-the-dop-testnet-864cdaf423f5"><svg className='firstsvg' xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M6.76884 5.65625C10.5072 5.65625 13.5374 8.70768 13.5374 12.4715C13.5374 16.2354 10.5069 19.2866 6.76884 19.2866C3.03073 19.2866 0 16.2354 0 12.4715C0 8.70768 3.0305 5.65625 6.76884 5.65625ZM17.5782 6.05547C19.4474 6.05547 20.9626 8.92775 20.9626 12.4715H20.9628C20.9628 16.0144 19.4476 18.8876 17.5784 18.8876C15.7092 18.8876 14.194 16.0144 14.194 12.4715C14.194 8.92867 15.709 6.05547 17.5782 6.05547ZM22.8097 6.72375C23.4669 6.72375 24 9.29708 24 12.4715C24 15.6451 23.4672 18.2193 22.8097 18.2193C22.1522 18.2193 21.6196 15.6458 21.6196 12.4715C21.6196 9.29731 22.1524 6.72375 22.8097 6.72375Z" fill="white" />
                            </svg> {t('wela1')}</a>
                            <a target='_blank' href="https://www.youtube.com/watch?v=6hnXYIWye4w"><svg className='secondsvg' xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                <rect y="0.5" width="20" height="20" rx="10" fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8908 6.39167C15.4262 6.53582 15.8484 6.95798 15.9925 7.4934C16.2602 8.47156 16.2499 10.5103 16.2499 10.5103C16.2499 10.5103 16.2499 12.5387 15.9925 13.5168C15.8484 14.0523 15.4262 14.4744 14.8908 14.6186C13.9126 14.876 9.99997 14.876 9.99997 14.876C9.99997 14.876 6.0976 14.876 5.10914 14.6083C4.57372 14.4641 4.15156 14.042 4.00741 13.5066C3.75 12.5387 3.75 10.5 3.75 10.5C3.75 10.5 3.75 8.47156 4.00741 7.4934C4.15156 6.95798 4.58402 6.52553 5.10914 6.38137C6.0873 6.12396 9.99997 6.12396 9.99997 6.12396C9.99997 6.12396 13.9126 6.12396 14.8908 6.39167ZM12.0078 10.5L8.75407 12.3739V8.62602L12.0078 10.5Z" fill="black" />
                            </svg> {t('wela2')}</a>
                        </div> */}
                        <p style={{ maxWidth: "524px", fontSize: "12px", margin: "20px auto" }}>{t('welp3')}</p>
                    </div>
                    <div className="main-card">
                        <form onSubmit={(e) => modallhandler(e)} className="w-100" >
                            {/* <div className="mainmodalstartpara forscroll">
            <h6 className="termshead">Terms & Conditions</h6>
            <p className="mainmodalinnerpara">
              THE CORE TEAM, INCLUSIVE OF ITS AFFILIATES AND REPRESENTATIVES, HEREBY PROVIDES NOTICE THAT ANY FUNDS, CONSIDERATIONS, CONTRIBUTIONS, INCOME, PAYMENT, OR OTHER FINANCIAL BENEFITS DERIVED FROM THE SALE OF DOP TOKENS, WHETHER FROM A PRIVATE SALE, PUBLIC SALE, OR ANY OTHER MEANS ("RECEIVED FUNDS"), MAY BE UTILIZED AT THE ABSOLUTE DISCRETION OF THE CORE TEAM WITHOUT ANY RESTRICTION
            </p>
            <p className="mainmodalinnerpara">
              FOR CLARITY, THIS INCLUDES, BUT IS NOT LIMITED TO, THE USE OF RECEIVED FUNDS FOR NONBUSINESS-RELATED ENDEAVORS. NO REPRESENTATION, WARRANTY, OR ASSURANCE IS MADE BY THE CORE TEAM REGARDING THE SPECIFIC ALLOCATION OR UTILIZATION OF THE RECEIVED FUNDS FOR ANY PARTICULAR PURPOSE, INCLUDING ANY BUSINESS-RELATED OBJECTIVES. ANY PARTY PURCHASING DOP TOKENS ACKNOWLEDGES AND AGREES THAT THE CORE TEAM RESERVES FULL DISCRETION OVER THE USAGE OF THE RECEIVED FUNDS. SUCH PURCHASERS EXPRESSLY WAIVE AND RELINQUISH ANY RIGHT TO RAISE CLAIMS AGAINST THE CORE TEAM, ITS REPRESENTATIVES, SHAREHOLDERS, DIRECTORS, EMPLOYEES, SERVICE PROVIDERS, AFFILIATES, AND ANY RELATED PARTIES CONCERNING THE ALLOCATION OR UTILIZATION OF THE RECEIVED FUNDS.
            </p>
          </div> */}
                            <div className="checkboxmain">
                                <div class="content">
                                    <label class="checkBox">
                                        <input type="checkbox" id="ch1" onChange={inputField1Handler2} />
                                        <div class="transition"></div>
                                    </label>
                                </div>
                                <p className="checkboxpara">{t('route-landing-TermCheckbox-T1')} <a className="textdecor" target="_blank" href="\termofuse.pdf">{t('route-landing-TermCheckbox-T11')}.</a></p>
                            </div>
                            <div className="material-textfield" style={{ margin: '30px 0px' }}>
                                <input placeholder={t('route-landing-TermCheckbox-T2')} onChange={inputField1Handler5}
                                    value={inputFeild6}
                                    type="text"
                                />
                                <label>{t('route-landing-TermCheckbox-T2')}</label>
                                {/* <img src="\Assets\import-assets\clipboard-text-dark.svg" alt="clip" className='clip dark' />
                <img src="\Assets\import-assets\clipboard-text-white.svg" alt="clip" className='clip white' /> */}
                            </div>

                            {cheked1 && checkedNew1 ?

                                <button type="submit" id="speicalAZ217" className='continuebutton'>
                                    {t('welb1')}
                                </button>
                                :

                                <button type="submit" className='blackbtn'>
                                    {t('welb1')}
                                </button>

                            }
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WelcomeDop
