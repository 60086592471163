import React, { useState } from 'react'
import "./generalsetting.scss"
import Modal from 'react-bootstrap/Modal';

const GeneralSetting = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    const [routes, setRoutes] = useState(0);


    var languageselected = localStorage.getItem('codelanguage')
    return (
        <>
            {routes == 0 ?
                <section className={languageselected == "ar" ? "generalsetting direction-for-arabic" : "generalsetting"}>
                    <div className="upper-heading">
                        <h6>General Settings</h6>
                    </div>
                    <div className="bottom-content">
                        <div className="inner-item" onClick={handleShow}>
                            <div className="left-side">
                                <div className="imggg">
                                    <img src="\Assets\button-svgs\translate-icon.svg" alt="img" className='img-fluid' />
                                </div>
                                <div className="textt">
                                    <h6>Language</h6>
                                    <p>English</p>
                                </div>
                            </div>
                            <div className="right-side">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='iconns'>
                                        <path d="M12.9509 9.40796L8.23425 4.69962C8.15678 4.62152 8.06462 4.55952 7.96307 4.51721C7.86152 4.47491 7.7526 4.45312 7.64259 4.45312C7.53258 4.45312 7.42366 4.47491 7.32211 4.51721C7.22056 4.55952 7.12839 4.62152 7.05092 4.69962C6.89571 4.85576 6.80859 5.06697 6.80859 5.28712C6.80859 5.50728 6.89571 5.71849 7.05092 5.87462L11.1759 10.0413L7.05092 14.1663C6.89571 14.3224 6.80859 14.5336 6.80859 14.7538C6.80859 14.9739 6.89571 15.1852 7.05092 15.3413C7.1281 15.42 7.22014 15.4827 7.3217 15.5256C7.42326 15.5685 7.53233 15.5908 7.64259 15.5913C7.75284 15.5908 7.86191 15.5685 7.96347 15.5256C8.06504 15.4827 8.15708 15.42 8.23425 15.3413L12.9509 10.633C13.0355 10.5549 13.103 10.4602 13.1492 10.3548C13.1954 10.2494 13.2192 10.1355 13.2192 10.0205C13.2192 9.90537 13.1954 9.79153 13.1492 9.68612C13.103 9.5807 13.0355 9.48599 12.9509 9.40796Z" fill="#252525" />
                                    </svg>
                            </div>
                        </div>
                        <div className="inner-item" onClick={handleShow1}>
                            <div className="left-side">
                                <div className="imggg">
                                    <img src="\Assets\button-svgs\export-mnemonic.svg" alt="img" className='img-fluid' />
                                </div>
                                <div className="textt">
                                    <h6>Export mnemonic</h6>
                                    <p>Export your wallet mnemonic</p>
                                </div>
                            </div>
                            <div className="right-side">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='iconns'>
                                        <path d="M12.9509 9.40796L8.23425 4.69962C8.15678 4.62152 8.06462 4.55952 7.96307 4.51721C7.86152 4.47491 7.7526 4.45312 7.64259 4.45312C7.53258 4.45312 7.42366 4.47491 7.32211 4.51721C7.22056 4.55952 7.12839 4.62152 7.05092 4.69962C6.89571 4.85576 6.80859 5.06697 6.80859 5.28712C6.80859 5.50728 6.89571 5.71849 7.05092 5.87462L11.1759 10.0413L7.05092 14.1663C6.89571 14.3224 6.80859 14.5336 6.80859 14.7538C6.80859 14.9739 6.89571 15.1852 7.05092 15.3413C7.1281 15.42 7.22014 15.4827 7.3217 15.5256C7.42326 15.5685 7.53233 15.5908 7.64259 15.5913C7.75284 15.5908 7.86191 15.5685 7.96347 15.5256C8.06504 15.4827 8.15708 15.42 8.23425 15.3413L12.9509 10.633C13.0355 10.5549 13.103 10.4602 13.1492 10.3548C13.1954 10.2494 13.2192 10.1355 13.2192 10.0205C13.2192 9.90537 13.1954 9.79153 13.1492 9.68612C13.103 9.5807 13.0355 9.48599 12.9509 9.40796Z" fill="#252525" />
                                    </svg>
                            </div>
                        </div>
                        <div className="inner-item" onClick={() => setRoutes(1)}>
                            <div className="left-side">
                                <div className="imggg">
                                    <img src="\Assets\button-svgs\chainprefer.svg" alt="img" className='img-fluid' />
                                </div>
                                <div className="textt">
                                    <h6>Chain Preferences</h6>
                                    <p>Chain Preferences</p>
                                </div>
                            </div>
                            <div className="right-side">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='iconns'>
                                        <path d="M12.9509 9.40796L8.23425 4.69962C8.15678 4.62152 8.06462 4.55952 7.96307 4.51721C7.86152 4.47491 7.7526 4.45312 7.64259 4.45312C7.53258 4.45312 7.42366 4.47491 7.32211 4.51721C7.22056 4.55952 7.12839 4.62152 7.05092 4.69962C6.89571 4.85576 6.80859 5.06697 6.80859 5.28712C6.80859 5.50728 6.89571 5.71849 7.05092 5.87462L11.1759 10.0413L7.05092 14.1663C6.89571 14.3224 6.80859 14.5336 6.80859 14.7538C6.80859 14.9739 6.89571 15.1852 7.05092 15.3413C7.1281 15.42 7.22014 15.4827 7.3217 15.5256C7.42326 15.5685 7.53233 15.5908 7.64259 15.5913C7.75284 15.5908 7.86191 15.5685 7.96347 15.5256C8.06504 15.4827 8.15708 15.42 8.23425 15.3413L12.9509 10.633C13.0355 10.5549 13.103 10.4602 13.1492 10.3548C13.1954 10.2494 13.2192 10.1355 13.2192 10.0205C13.2192 9.90537 13.1954 9.79153 13.1492 9.68612C13.103 9.5807 13.0355 9.48599 12.9509 9.40796Z" fill="#252525" />
                                    </svg>
                            </div>
                        </div>
                        <div className="inner-item" onClick={handleShow3}>
                            <div className="left-side">
                                <div className="imggg">
                                    <img src="\Assets\button-svgs\theme-mode.svg" alt="img" className='img-fluid' />
                                </div>
                                <div className="textt">
                                    <h6>Theme Mode</h6>
                                    <p>Dark</p>
                                </div>
                            </div>
                            <div className="right-side">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='iconns'>
                                        <path d="M12.9509 9.40796L8.23425 4.69962C8.15678 4.62152 8.06462 4.55952 7.96307 4.51721C7.86152 4.47491 7.7526 4.45312 7.64259 4.45312C7.53258 4.45312 7.42366 4.47491 7.32211 4.51721C7.22056 4.55952 7.12839 4.62152 7.05092 4.69962C6.89571 4.85576 6.80859 5.06697 6.80859 5.28712C6.80859 5.50728 6.89571 5.71849 7.05092 5.87462L11.1759 10.0413L7.05092 14.1663C6.89571 14.3224 6.80859 14.5336 6.80859 14.7538C6.80859 14.9739 6.89571 15.1852 7.05092 15.3413C7.1281 15.42 7.22014 15.4827 7.3217 15.5256C7.42326 15.5685 7.53233 15.5908 7.64259 15.5913C7.75284 15.5908 7.86191 15.5685 7.96347 15.5256C8.06504 15.4827 8.15708 15.42 8.23425 15.3413L12.9509 10.633C13.0355 10.5549 13.103 10.4602 13.1492 10.3548C13.1954 10.2494 13.2192 10.1355 13.2192 10.0205C13.2192 9.90537 13.1954 9.79153 13.1492 9.68612C13.103 9.5807 13.0355 9.48599 12.9509 9.40796Z" fill="#252525" />
                                    </svg>
                            </div>
                        </div>
                    </div>
                </section> :
                routes == 1 ?
                    <section className='chain-prefer'>
                        <div className="main-heading">
                            <h6>Chain Preferences</h6>
                        </div>
                        <div className="bottom-side">
                            <div className="inner-item">
                                <img src="\Assets\button-svgs\eth-icon.svg" alt="img" className='img-fluid ' />
                                <h6>ETH</h6>
                            </div>
                            <div className="inner-item">
                                <img src="\Assets\button-svgs\tether-icon.svg" alt="img" className='img-fluid white' />
                                <img src="\Assets\button-svgs\tether-icondark.svg" alt="img" className='img-fluid dark' />
                                <h6>USDT</h6>
                            </div>
                            <div className="inner-item">
                                <img src="\Assets\button-svgs\bnb-icon.svg" alt="img" className='img-fluid white' />
                                <img src="\Assets\button-svgs\bnb-icondark.svg" alt="img" className='img-fluid dark' />
                                <h6>BNB</h6>
                            </div>
                            <div className="inner-item">
                                <img src="\Assets\button-svgs\dai-icon.svg" alt="img" className='img-fluid white' />
                                <img src="\Assets\button-svgs\dai-icondark.svg" alt="img" className='img-fluid dark' />
                                <h6>DAI</h6>
                            </div>
                        </div>
                        <div className="bottom-inner">
                            <h6>Ethereum</h6>
                        </div>
                        <div className="bottom-inner">
                            <h6>1</h6>
                        </div>
                        <div className="rpc-url">
                            <div className="upper">
                                <h6>RPC URL</h6>
                                <div className="iconss">
                                    <img src="\Assets\button-svgs\rotate-right.svg" alt="" className='white' />
                                    <img src="\Assets\button-svgs\add-circle.svg" alt="img" className='img-fluid white' />
                                    <img src="\Assets\button-svgs\rotate-rightdark.svg" alt="" className='dark' />
                                    <img src="\Assets\button-svgs\add-circledark.svg" alt="img" className='img-fluid dark' />
                                </div>
                            </div>
                            <div className="bottom-area">
                                <div className="inner-text">
                                    <p>wss://mainnet.infura.io/ws/v3/9aa3d95b3bc440fa88ea12eaa4</p>
                                    <img src="\Assets\button-svgs\close-icon.svg" alt="img" className='img-fluid white' />
                                    <img src="\Assets\button-svgs\close-icondark.svg" alt="img" className='img-fluid dark' />
                                </div>
                                <div className="inner-text">
                                    <p>wss://mainnet.infura.io/ws/v3/9aa3d95b3bc440fa88ea12eaa4</p>
                                    <img src="\Assets\button-svgs\close-icon.svg" alt="img" className='img-fluid white' />
                                    <img src="\Assets\button-svgs\close-icondark.svg" alt="img" className='img-fluid dark' />

                                </div>
                            </div>
                        </div>
                        <div className="twice-btn">
                            <button className='btn-cancel'>Cancel</button>
                            <button className='btn-confirm'>Confirm</button>
                        </div>
                    </section> : ""
            }

            <Modal className={languageselected == "ar" ? "language-modal direction-for-arabic" : "language-modal"} show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Language</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bottom-content">
                        <div className="inner-item">
                            <div className="custom-radio">
                                <input type="radio" id="test1" name="radio-group" />
                                <label for="test1">English</label>
                            </div>
                        </div>
                        <div className="inner-item">
                            <div className="custom-radio">
                                <input type="radio" id="test2" name="radio-group" />
                                <label for="test2">한국어</label>
                            </div>
                        </div>
                        <div className="inner-item">
                            <div className="custom-radio">
                                <input type="radio" id="test3" name="radio-group" />
                                <label for="test3">Русский</label>
                            </div>
                        </div>
                        <div className="inner-item">
                            <div className="custom-radio">
                                <input type="radio" id="test4" name="radio-group" />
                                <label for="test4">Українська</label>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>

            <Modal className={languageselected == "ar" ? "language-modal direction-for-arabic" : "language-modal"} show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Export mnemonic</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bottom-content">

                        <p className="text-para">
                            Warning: Do not show this wallet mnemonic to anyone!. The wallet mnemonic phrase can be used to steal all your accounts
                        </p>

                        <div className="material-textfield">
                            <input placeholder="Enter new password" type="text" />
                            <label>New password</label>
                            <img src="\Assets\import-assets\eye-dark.svg" alt="eye" className='eye dark' />
                <img src="\Assets\import-assets\eye-white.svg" alt="eye" className='eye white' />
                        </div>

                        <div className="twice-btn">
                            <button onClick={handleClose1} className='btn-cancel'>Cancel</button>
                            <button onClick={() => {
                                handleClose1();
                                handleShow2();
                            }} className='btn-confirm'>Confirm</button>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>

            <Modal className={languageselected == "ar" ? "language-modal direction-for-arabic" : "language-modal"} show={show2} onHide={handleClose2} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Export mnemonic</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bottom-contentt">
                        <p className="para">
                            Please select each words to ensure its correctness.
                        </p>
                        <div className="phrase-content">
                            <div className="single-phrase">
                                <p>resultls</p>
                            </div>
                            <div className="single-phrase">
                                <p>hen</p>
                            </div>
                            <div className="single-phrase">
                                <p>mango</p>
                            </div>
                            <div className="single-phrase">
                                <p>garment</p>
                            </div>
                            <div className="single-phrase">
                                <p>pizza</p>
                            </div>
                            <div className="single-phrase">
                                <p>supply</p>
                            </div>
                            <div className="single-phrase">
                                <p>pudding</p>
                            </div>
                            <div className="single-phrase">
                                <p>garage</p>
                            </div>
                            <div className="single-phrase">
                                <p>goose</p>
                            </div>
                            <div className="single-phrase">
                                <p>nose</p>
                            </div>
                            <div className="single-phrase">
                                <p>swap</p>
                            </div>
                            <div className="single-phrase">
                                <p>vayage</p>
                            </div>
                        </div>
                        <div className="twice-btn">
                            <button className='btn-cancel'>Copy text</button>
                            <button className='btn-confirm'>Download File</button>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>

            <Modal className={languageselected == "ar" ? "language-modal direction-for-arabic" : "language-modal"} show={show3} onHide={handleClose3} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Theme Mode</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bottom-content">
                        <div className="inner-item">
                            <div className="custom-radio">
                                <input type="radio" id="test1" name="radio-group" />
                                <label for="test1">Dark Theme</label>
                            </div>
                        </div>
                        <div className="inner-item">
                            <div className="custom-radio">
                                <input type="radio" id="test2" name="radio-group" />
                                <label for="test2">Light Theme</label>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default GeneralSetting
