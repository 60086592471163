import React from 'react'
import Navbar from '../landing/header/navbar1'
import { Link } from 'react-router-dom';
import './create.scss'
import { useState,useEffect } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveData, getData } from '../../db';
import { useHistory } from "react-router-dom";
import useWeb3 from "../../hooks/useWeb3";
import Navbar1 from '../landing/header/navbar1';
import { useTranslation } from 'react-i18next'
const { ethers } = require("ethers");
const CreateWallet = () => {
  const { t } = useTranslation()
  const web3 = useWeb3();
  const history = useHistory()
  const [pasword,setPasword]=useState('')
  const [pasword1,setPasword1]=useState('')
  const [mod,setMod]=useState(false)
  const [mod1,setMod1]=useState(false)
  const [secretPhase,setSecretPhase]=useState(null)
  const [chekked,setChekked]=useState(false)
  const [chekked1,setChekked1]=useState(false)
  const [mod11,setMod11]=useState(false)
  const [copied, setCopied] = useState(false);
  const [copied1, setCopied1] = useState(false);
  const [copied2, setCopid2] = useState(false);
const handler=(e)=>{
  // console.log('pass',e.target.value);
  setPasword(e.target.value)
}
const handler1=(e)=>{
  setPasword1(e.target.value) 
  // console.log('confirm',e.target.value);
}
const submit=(e)=>{
  e?.preventDefault()
  // console.log('click me '); 
  if(chekked1&&mod11){
  if(pasword!=''&&pasword1!=''){
    if(pasword===pasword1){
    // toast.success("Password is changes Successfully")
    handleClick()
    history.push('/unlock')
    }else{
      setCopid2(true)
      setTimeout(() => {
        setCopid2(false)
      }, 2000)
    }
  }else
  {
     setCopied1(true)
        setTimeout(() => {
            setCopied1(false)
        }, 2000)
  }
}else{
  setCopied(true)
  setTimeout(() => {
      setCopied(false)
  }, 2000)
}
}
const hide=()=>{
  // console.log();
  if(mod){
    setMod(false)
  }
  else{
    setMod(true)
  }
}
const hide1=()=>{
  // console.log();
  if(mod1){
    setMod1(false)
  }
  else{
    setMod1(true)
  }
}
const handleClick = async () => {
  await saveData('tpl_password',pasword);
  // const result = await getData('key');
  // setData(result);
};
const handler2=()=>{
  if(chekked){
    setChekked(false)
  }
  else{
    setChekked(true)
  }
}
const handler3=(e)=>{
  var length = e.target.value.length;
  // console.log('pass',length);
  setSecretPhase(e.target.value)
  setChekked1(false)
  if(length<=66){
    try{
      const newwallet = web3.eth.accounts.privateKeyToAccount(e.target.value);
      // console.log(newwallet,'newwallet');
  if(newwallet){
    handleClick1(newwallet)
      setChekked1(true)
  }else{
    setChekked1(false)
    // handleClick1(newwallet)
  }
      
      // history.push('/unlock')
    }
    catch(error){
      setChekked1(false)
      // toast.error("Private key is incorrect")
    }
  }else if (length>66){
   
    try{
      const newwallet = ethers.Wallet.fromMnemonic((e.target.value).toString())
      // console.log(newwallet,'newwallet');
  if(newwallet){
    handleClick1(newwallet)
      setChekked1(true)
  }else{
    setChekked1(false)
    // handleClick1(newwallet)
  }
      
      // history.push('/unlock')
    }
    catch(error){
      console.log(error);
      setChekked1(false)
      // toast.error("Private key is incorrect")
    }
  
  }

}
const handleClick1 = async (a) => {
  const result1 = await getData('tpl_data');
  const result12 = await getData('selected_privateKey');
  // if(result1&&result1){
    const newwallet = web3.eth.accounts.privateKeyToAccount(result12);
    let dumObj=result1.find((i)=>i.account_address==a.address)
    // console.log(dumObj,'dumObj',result12,result1);
    if(dumObj){
      setMod11(true)
      handleClick2(a)
    }else{
      setMod11(false)
    }
// if(!dumObj){
//   setMod11(true)
//    if (!result1){
//     await saveData('tpl_data',[{
//       private_key:a.privateKey,
//       account_address:a.address,
//       depoite:[],
//       transfer:[],
//       withDraw:[],
//       }]);
//   }else
//   {
//     result1.push(
//       {
//         private_key:a.privateKey,
//       account_address:a.address,
//         depoite:[],
//         transfer:[],
//         withDraw:[],
//         }
//     )
//     await saveData('tpl_data',result1);
//   }
//     handleClick2(a)
// }else{
//   toast.error("Account Already exist in your Tpl Account")
// }
  // }
  // else{
  //   setMod11(true)
  //   await saveData('tpl_data',[{
  //     private_key:a.privateKey,
  //     account_address:a.address,
  //     depoite:[],
  //     transfer:[],
  //     withDraw:[],
  //     }]);
  // }

 
  // setData(result);
};
const handleClick2 = async (a) => {
  await saveData('selected_privateKey',a.privateKey);
  // setData(result);
};
const handleClick12 = async () => {
  // await saveData('tpl_password',pasword);
  // const result = await getData('tpl_password');
  // const result1 = await getData('selected_privateKey');
  const mail = await localStorage.getItem('mail')
  // console.log(mail,'mail');
  // if(!mail){
    // history.push('/login')
  // }
  // if(result && result1){
  //   history.push('/unlock')
  // }
  // setData(result);
// };
  // setData(result);
};
// useEffect(() => {
//   handleClick12()
// },[]);
var languageselected = localStorage.getItem('codelanguage')
  return (
    <>
      <Navbar1 />
      <section className={languageselected == "ar" ? "createwallet direction-for-arabic" : "createwallet"}>
        <div className="theme-container">
          <div className="innerimport">
            <div className="importheader">
              <Link to="/">
                <img src="\Assets\import-assets\arrow-left.svg" alt="backarrow" className="backarrow" />
              </Link>
              <p className="importhead">{t('route-reset-H1')}</p>
            </div>
            <form onSubmit={(e)=>submit(e)} className="w-100">
            <div className="material-textfield mb-3">
                <input value={secretPhase} onChange={(e)=>handler3(e)} placeholder="Enter your Private Key or Secret Phrase" type={chekked?"text":"password"} />
                <label>{t('route-reset-T1')}</label>
                {/* <img src="\Assets\import-assets\clipboard-text-dark.svg" alt="clip" className='clip' /> */}
                {/* <img src="\Assets\import-assets\clipboard-text-white.svg" alt="clip" className='clip white' /> */}
              </div>
              </form>
              {copied&&
              <p className="text-error">*{t('Invalid-Address')}</p>}
              <div class="form-check radiocheck-btnn mb-4">
                <input value={chekked} onChange={(e)=>handler2(e)}  class="form-check-input" type="checkbox" id="flexCheckDefault" />
                <label className="form-check-label importheadddddd" for="flexCheckDefault">
               {t('Sidebar-Account-H3-T4')}
                </label>
              </div>
              <form onSubmit={(e)=>submit(e)} className="w-100">
            <div className="importbody">
              <div className="material-textfield">
                <input value={pasword} onChange={(e)=>handler(e)} placeholder="Enter a new password" type={mod?"text":"password"} />
                <label>{t('route-reset-T2')}</label>
                <img onClick={hide}  src="\Assets\import-assets\eye-dark.svg" alt="eye" className='eye dark' />
                <img onClick={hide}  src="\Assets\import-assets\eye-white.svg" alt="eye" className='eye white' />
              </div>
              <div className="material-textfield">
                <input value={pasword1} onChange={(e)=>handler1(e)}  placeholder="Confirm new password" type={mod1?"text":"password"} />
                <label>{t('route-reset-T3')}</label>
                <img onClick={hide1}  src="\Assets\import-assets\eye-dark.svg" alt="eye" className='eye dark' />
                <img onClick={hide1}  src="\Assets\import-assets\eye-white.svg" alt="eye" className='eye white' />
              </div>
              {copied1&&
  <p className="text-error">*{t('Invalid-Password')}</p>}
    {copied2&&
  <p className="text-error">*{t('password-match')}</p>}
              {/* <Link to="/secretrecoveryphrase"> */}
              <button id="speicalAZ220" type='submit' className="common-btntwo">{t('Sidebar-Account-H3-B1')}</button>
              {/* </Link> */}
            </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default CreateWallet
