import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { routerContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const RouterPrice = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.router;
  const contract = routerContract(tokenAddress, web3);


  const routerPrice = useCallback(
    async (price) => {
        let nd = web3.utils.toWei(price.toString(), "mwei");
      // console.log(interAccount,random,v,r,s,time,indux,'interAccount,random,v,r,s,time,indux');
      // console.log(interAccount,random,v,r,s,time,'interAccount,random,v,r,s,time');
        // console.log(a,b,c,d,e,r,'a,b,c,d,e');
    //   amount = amount.toString()
    // console.log(interAccount,random.toString(),time.toString(),v,r,s,'interAccount,random.toString(),time.toString(),v,r,s');
      try {
        // console.log(account,interAccount,random,time,v,r,s,);
        const buy = await contract.methods.getAmountsOut(nd,[Environment.usdt,Environment.Dop]).call()
        // console.log('interAccount,random,v,r,s,time,indux' ,  buy)
        return buy;
      } catch (error) {
        throw error;
      }
    },
    [contract, account,web3]
  );
  return { routerPrice: routerPrice };
};
export default RouterPrice;
