import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { dopContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const ApproveDop = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  // const tokenAddress = Environment.busd;
  const tokenAddress1 = Environment.Dop;
  const tokenAddress = Environment.tpl;


  const approveDop = useCallback(
    async (amount) => {
      let gasPrice = await web3.eth.getGasPrice()
      gasPrice = parseInt(gasPrice) + 4000000000
      try {
        const contract = dopContract(tokenAddress1, web3);
        let nd = web3.utils.toWei(amount.toString(), "ether");
        const gas = await contract.methods
          .approve(tokenAddress, nd)
          .estimateGas({ from: account });
        // let gasPrice = await web3.eth.getGasPrice();
        const details = await contract.methods
          .approve(tokenAddress, nd)
          .send({
            from: account,
            gas: gas,
            gasPrice,
          });
        return details;
        // console.log('sdsdss' ,  buy)
        // return details;
      } catch (error) {
        throw error;
      }
    },
    [account,web3]
  );
  return {approveDop: approveDop };
};
export default ApproveDop;
