import React, { useEffect, useState } from "react";
import "./navbar.scss";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../../../hooks/useWeb3";
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import axios from "axios";
import Signature from "../../../hooks/dataSenders/userSign"
import Signaturee3 from "../../../hooks/dataSenders/userSign3";
import { saveData, getData } from '../../../db';
import UserBalanceTpl from "../../../hooks/dataFetchers/UserBalaceTpl";
import { io } from "socket.io-client";
import Loader from '../../../hooks/loader';
import { browserName, isBrowser } from 'react-device-detect';
import { Dropdown } from "react-bootstrap";
import i18next from 'i18next'
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

const Navbar = ({ setItemsAssets, setBalance, setInternalAount, internalAount, setShow, show, itemsAssets, setDefaultt }) => {
  var languageselected = localStorage.getItem('codelanguage')
  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
      igm: "/Assets/englishimg.svg"
    },
    {
      code: 'jp',
      name: '日本語',
      country_code: 'jp',
      igm: "/Assets/japanimg.svg",
    },
    {
      code: 'ko',
      name: '한국인',
      country_code: 'kr',
      igm: "/Assets/koreaimg.svg"
    },
    {
      code: 'cn',
      name: '中文',
      country_code: 'cn',
      igm: "/Assets/china.svg"
    },
    {
      code: 'ru',
      name: 'Русский',
      country_code: 'ru',
      igm: "/Assets/russia.svg"
    },
    {
      code: 'ar',
      name: 'عربي',
      country_code: 'ar',
      igm: "/Assets/arabic.svg"
    },
  ]
  const currentLanguageCode = cookies.get('i18next')
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()
  var languageactive = currentLanguage?.name;
// console.log("llll",languageactive);
  const [loaderr, setLoarder] = useState(false)
  // console.log('itemsAssets',browserName,isBrowser);
  const localRouteId = localStorage.getItem('routeId')
  const { userBalanceTpl } = UserBalanceTpl();
  const { ethereum } = window;
  const now = Date.now();
  let r = ''
  let s = ''
  let v = ''
  let newwallet = ''
  let random = 0
  const { userSign } = Signature()
  const { sign3 } = Signaturee3()
  const localData1 = localStorage.getItem('sign');
  const web3 = useWeb3();
  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const [account11, setAcount11] = useState(null)
  const [arg, setArg] = useState(null)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark-theme");
  const toggleTheme = () => {
    if (theme === "light-theme") {
      setTheme("dark-theme");
      localStorage.setItem("theme", "dark-theme");
    } else {
      setTheme('light-theme');
      localStorage.setItem("theme", "light-theme");

    }
  }
  let locadata = localStorage.getItem('connectorId')
  useEffect(() => {
    document.body.className = theme;
  }, [theme]);
  const trustWallet = async () => {
    localStorage.setItem("flag", "true");
    localStorage.setItem("connectorId", "walletconnect");
    if (account) {
      logout();
      localStorage.clear();
    } else {
      login("walletconnect");
      setAcount11(account)
      gettingSign()
      // localStorage.setItem('connectorId', 'walletconnect');
      handleClose()
      // handleClose()
    }
  };


  const connectMetaMask1 = () => {
    // console.log(account, "account connected");

    localStorage.setItem("connectorId", "injected");
    localStorage.setItem("flag", "true");
    if (account) {
      logout();
    } else {
      login("injected");
      gettingSign()
      handleClose()
      // setAcount11(account)
      // window.location.reload()
    }
  };
  const handleShow1 = async () => {
    await logout();
    localStorage.removeItem('connectorId')
    localStorage.removeItem('flag')

    // window.location.reload()
  }
  useEffect(() => {
    if (account&&web3) {

      gettingSign()
    }
    // ,account, arg, internalAount
  }, [web3])
  // console.log(account);
  useEffect(() => {

    if (!locadata) {
      handleShow();
    }

  }, [arg])

  const [show12, setShow12] = useState(false);

  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const gettingSign = async () => {
    // console.log("vvvvvvvvvvvv", ethereum.enable)
    // const accounts = await ethereum.request({
    //   method: 'eth_requestAccounts',
    // });
    // const accounts = 
    // console.log("=a=a=a=a=a=a=a=a", accounts)
    // return 0;

    // console.log('gettingSign');

    // if (localData1 == null) {
    const result = await getData('selected_privateKey');
    newwallet = web3.eth.accounts.privateKeyToAccount(result);
    newwallet = newwallet?.address.toLowerCase()
    random = Math.floor((Math.random() * 1000000) + 1);
    setInternalAount(newwallet)
    // console.log(newwallet?.address,'newwallet',b,now);

    // console.log(newwallet,random,now,account,'newwallet,random,now,account============');

    const res1 = await userSign(result, random, now, account);
    const res3 = await sign3(result, random, now, account);
    console.log(res3,'res3');
    // setUserSing(res1)
    if (res1) {
      // console.log(res1,'res1');
      localStorage.setItem('sign', res3)
      let res12 = res1.substring(2)
      // console.log(res12,'res12');
      r = '0x' + res12.slice(0, 64);
      s = '0x' + res12.slice(64, 128);
      v = '0x' + res12.slice(128, 130);
      // console.log(r,s,v,'wqeqweqwe');
      getBlanace(newwallet, random, v, r, s, now)
    }
  }
  const getBlanace = async () => {
    // setLoarder(true)
    let dummy = []
    for (let a = 0; a < itemsAssets?.length; a++) {


      try {
        let res = await userBalanceTpl(newwallet, random, v, r, s, now, itemsAssets[a]?.indecBy)
        // console.log(res);
        let a1 = ''
        if (res) {
          // console.log(itemsAssets[a]?.indecBy, 'itemsAssets[1]?.indecBy');
          if (itemsAssets[a]?.indecBy == 21 || itemsAssets[a]?.indecBy == 22) {
            a1 = res / 10 ** 6

          } else {
            a1 = res / 10 ** 18
          }
          // console.log(itemsAssets[a],'interAccount,random,v,r,s,time,indux');
          let tempLLend = itemsAssets[a];
          // console.log(a1);
          let tempLockedLending2 = { ...tempLLend, amountt: a1 };
          dummy.push(tempLockedLending2)
          // console.log('>>>>',);
          // setBalance(a)

        }
      } catch (err) {
        console.log(err, 'err');
        // setLoarder(false)

        let tempLLend = itemsAssets[a];
        let tempLockedLending2 = { ...tempLLend, amountt: '' };
        dummy.push(tempLockedLending2)
      }
      dummy.sort((a, b) => {
        // console.log(a,b,'sorting');
        return b?.amountt - a?.amountt
      })
    }
    // console.log(dummy, 'sorting');
    setItemsAssets(dummy)
    //  console.log(dummy,'dummy');
    setLoarder(false)
  }


  // useEffect(() => {
  //   getSocket();
  // }, [])

  useEffect(() => {
    if(languageselected == 'en'){
      setImgeOfLanguage('/Assets/englishimg.svg')
    }else if(languageselected == 'jp'){
      setImgeOfLanguage('/Assets/japanimg.svg')
      
    } else if(languageselected == 'ko'){
      setImgeOfLanguage('/Assets/koreaimg.svg')
    }else if(languageselected == 'cn'){
      setImgeOfLanguage('/Assets/china.svg')
    }else if(languageselected == 'ru'){
      setImgeOfLanguage('/Assets/russia.svg')
    }else if(languageselected == 'ar'){
      setImgeOfLanguage('/Assets/arabic.svg')
    }
   
  }, [])
  const getSocket = async () => {
    try {
      const socket = io("wss://socket.tdns.network", {
        reconnectionDelayMax: 1000,
      });
      socket.on('connect', function () {
      });
      const listener = (...args) => {

        // if (args[0]?.tokenId === "emited") {
        // console.log(args,'args');
        setArg(...args)
        // Bids()
        // highestBidFun()
        // expirtyTimeFun()
      }
      // }
      socket.on("bidReceived", listener);
    } catch (ex) { }
  }

  useEffect(() => {
    // Check if MetaMask is installed
    const isInstalled = typeof window.ethereum !== 'undefined';
    setIsMetaMaskInstalled(isInstalled);
  }, []);

  // console.log(itemsAssets,'itemsAssetsss');

  // const [selectedLanguage, setSelectedLanguage] = useState("");


  const GlobeIcon = ({ width = 24, height = 24 }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="currentColor"
      className="bi bi-globe"
      viewBox="0 0 16 16"
    >
      <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
    </svg>
  )

  const changelanguage = (code) => {
    i18next.changeLanguage(code)
    localStorage.setItem('codelanguage', code)
  }
  // const Languages =
  //   [

  //     { igm: "/Assets/englishimg.svg", name: "English" },
  //     { igm: "/Assets/japanimg.svg", name: "Japanese" },
  //     { igm: "/Assets/koreaimg.svg", name: "Korean" }
  //   ]

  const [nameOfLanguage, setNameOfLanguage] = useState('English');
  const [imgeOfLanguage, setImgeOfLanguage] = useState('/Assets/englishimg.svg');
  return (

    <>
      {loaderr &&

        <Loader text={''} />
      }

      <section className={languageselected == "ar" ? "main-navbar direction-for-arabic" : "main-navbar"}>
        <div class="theme-container">
          <nav class="navbar navbar-expand-lg">
            <div class="container-fluid p-0">
              <a class="navbar-brand" style={{ cursor: "pointer" }} onClick={() => {
                window.location.reload()
              }}>
                {theme == 'light-theme' ?
                  <svg width="106" height="38" viewBox="0 0 106 38" fill="none" xmlns="http://www.w3.org/2000/svg" className="logo">
                    <path d="M0 0.0219727H18.4915C23.6538 0.0219727 28.6048 2.02144 32.2551 5.58053C35.9054 9.13961 37.9562 13.9668 37.9562 19.0001C37.9562 24.0334 35.9054 28.8605 32.2551 32.4196C28.6048 35.9787 23.6538 37.9782 18.4915 37.9782V28.2486C21.0072 28.2486 23.4199 27.2742 25.1988 25.5397C26.9777 23.8053 27.9771 21.4529 27.9771 19.0001C27.9771 16.5472 26.9777 14.1948 25.1988 12.4604C23.4199 10.726 21.0072 9.75156 18.4915 9.75156V9.75433H8.75912V18.5134H0V0.0219727Z" fill="black" />
                    <path d="M8.75912 18.5134L18.4915 18.5134V28.2458H8.75912L8.75912 18.5134Z" fill="black" />
                    <path d="M45.9717 28.4895V10.6313H60.3574C60.9858 10.6313 61.5645 10.7884 62.0937 11.1026C62.6228 11.4168 63.0444 11.8384 63.3586 12.3676C63.6728 12.8802 63.8299 13.4506 63.8299 14.079V25.0419C63.8299 25.6703 63.6728 26.249 63.3586 26.7781C63.0444 27.2907 62.6228 27.7041 62.0937 28.0183C61.5645 28.3325 60.9858 28.4895 60.3574 28.4895H45.9717ZM49.5185 25.1907H60.2086C60.2913 25.1907 60.3657 25.1659 60.4319 25.1163C60.498 25.0502 60.5311 24.9758 60.5311 24.8931V14.2278C60.5311 14.1451 60.498 14.079 60.4319 14.0294C60.3657 13.9632 60.2913 13.9302 60.2086 13.9302H49.5185C49.4358 13.9302 49.3614 13.9632 49.2953 14.0294C49.2457 14.079 49.2209 14.1451 49.2209 14.2278V24.8931C49.2209 24.9758 49.2457 25.0502 49.2953 25.1163C49.3614 25.1659 49.4358 25.1907 49.5185 25.1907Z" fill="black" />
                    <path d="M70.0054 28.4895C69.3771 28.4895 68.7984 28.3325 68.2692 28.0183C67.7566 27.7041 67.3433 27.2907 67.0291 26.7781C66.7149 26.249 66.5578 25.6703 66.5578 25.0419V14.079C66.5578 13.4506 66.7149 12.8802 67.0291 12.3676C67.3433 11.8384 67.7566 11.4168 68.2692 11.1026C68.7984 10.7884 69.3771 10.6313 70.0054 10.6313H80.9684C81.5967 10.6313 82.1672 10.7884 82.6798 11.1026C83.2089 11.4168 83.6306 11.8384 83.9448 12.3676C84.2589 12.8802 84.416 13.4506 84.416 14.079V25.0419C84.416 25.6703 84.2589 26.249 83.9448 26.7781C83.6306 27.2907 83.2089 27.7041 82.6798 28.0183C82.1672 28.3325 81.5967 28.4895 80.9684 28.4895H70.0054ZM70.1295 25.1907H80.7948C80.8774 25.1907 80.9519 25.1659 81.018 25.1163C81.0841 25.0502 81.1172 24.9758 81.1172 24.8931V14.2278C81.1172 14.1451 81.0841 14.079 81.018 14.0294C80.9519 13.9632 80.8774 13.9302 80.7948 13.9302H70.1295C70.0468 13.9302 69.9724 13.9632 69.9062 14.0294C69.8566 14.079 69.8318 14.1451 69.8318 14.2278V24.8931C69.8318 24.9758 69.8566 25.0502 69.9062 25.1163C69.9724 25.1659 70.0468 25.1907 70.1295 25.1907Z" fill="black" />
                    <path d="M87.1474 28.4895V10.6562H101.533C102.162 10.6562 102.74 10.8132 103.269 11.1274C103.799 11.4416 104.22 11.8632 104.534 12.3924C104.849 12.905 105.006 13.4754 105.006 14.1038V18.9156C105.006 19.5439 104.849 20.1226 104.534 20.6518C104.22 21.1809 103.799 21.6026 103.269 21.9167C102.74 22.2144 102.162 22.3632 101.533 22.3632L90.4215 22.388V28.4895H87.1474ZM90.7191 19.0644H101.384C101.467 19.0644 101.541 19.0396 101.608 18.99C101.674 18.9404 101.707 18.8742 101.707 18.7916V14.2278C101.707 14.1451 101.674 14.079 101.608 14.0294C101.541 13.9632 101.467 13.9302 101.384 13.9302H90.7191C90.6364 13.9302 90.562 13.9632 90.4959 14.0294C90.4463 14.079 90.4215 14.1451 90.4215 14.2278V18.7916C90.4215 18.8742 90.4463 18.9404 90.4959 18.99C90.562 19.0396 90.6364 19.0644 90.7191 19.0644Z" fill="black" />
                  </svg>

                  :
                  <svg width="106" height="38" viewBox="0 0 106 38" fill="none" xmlns="http://www.w3.org/2000/svg" className="logo">
                    <path d="M0 0.0219727H18.4915C23.6538 0.0219727 28.6048 2.02144 32.2551 5.58053C35.9054 9.13961 37.9562 13.9668 37.9562 19.0001C37.9562 24.0334 35.9054 28.8605 32.2551 32.4196C28.6048 35.9787 23.6538 37.9782 18.4915 37.9782V28.2486C21.0072 28.2486 23.4199 27.2742 25.1988 25.5397C26.9777 23.8053 27.9771 21.4529 27.9771 19.0001C27.9771 16.5472 26.9777 14.1948 25.1988 12.4604C23.4199 10.726 21.0072 9.75156 18.4915 9.75156V9.75433H8.75912V18.5134H0V0.0219727Z" fill="white" />
                    <path d="M8.75912 18.5134L18.4915 18.5134V28.2458H8.75912L8.75912 18.5134Z" fill="white" />
                    <path d="M45.9717 28.4895V10.6313H60.3574C60.9858 10.6313 61.5645 10.7884 62.0937 11.1026C62.6228 11.4168 63.0444 11.8384 63.3586 12.3676C63.6728 12.8802 63.8299 13.4506 63.8299 14.079V25.0419C63.8299 25.6703 63.6728 26.249 63.3586 26.7781C63.0444 27.2907 62.6228 27.7041 62.0937 28.0183C61.5645 28.3325 60.9858 28.4895 60.3574 28.4895H45.9717ZM49.5185 25.1907H60.2086C60.2913 25.1907 60.3657 25.1659 60.4319 25.1163C60.498 25.0502 60.5311 24.9758 60.5311 24.8931V14.2278C60.5311 14.1451 60.498 14.079 60.4319 14.0294C60.3657 13.9632 60.2913 13.9302 60.2086 13.9302H49.5185C49.4358 13.9302 49.3614 13.9632 49.2953 14.0294C49.2457 14.079 49.2209 14.1451 49.2209 14.2278V24.8931C49.2209 24.9758 49.2457 25.0502 49.2953 25.1163C49.3614 25.1659 49.4358 25.1907 49.5185 25.1907Z" fill="white" />
                    <path d="M70.0054 28.4895C69.3771 28.4895 68.7984 28.3325 68.2692 28.0183C67.7566 27.7041 67.3433 27.2907 67.0291 26.7781C66.7149 26.249 66.5578 25.6703 66.5578 25.0419V14.079C66.5578 13.4506 66.7149 12.8802 67.0291 12.3676C67.3433 11.8384 67.7566 11.4168 68.2692 11.1026C68.7984 10.7884 69.3771 10.6313 70.0054 10.6313H80.9684C81.5967 10.6313 82.1672 10.7884 82.6798 11.1026C83.2089 11.4168 83.6306 11.8384 83.9448 12.3676C84.2589 12.8802 84.416 13.4506 84.416 14.079V25.0419C84.416 25.6703 84.2589 26.249 83.9448 26.7781C83.6306 27.2907 83.2089 27.7041 82.6798 28.0183C82.1672 28.3325 81.5967 28.4895 80.9684 28.4895H70.0054ZM70.1295 25.1907H80.7948C80.8774 25.1907 80.9519 25.1659 81.018 25.1163C81.0841 25.0502 81.1172 24.9758 81.1172 24.8931V14.2278C81.1172 14.1451 81.0841 14.079 81.018 14.0294C80.9519 13.9632 80.8774 13.9302 80.7948 13.9302H70.1295C70.0468 13.9302 69.9724 13.9632 69.9062 14.0294C69.8566 14.079 69.8318 14.1451 69.8318 14.2278V24.8931C69.8318 24.9758 69.8566 25.0502 69.9062 25.1163C69.9724 25.1659 70.0468 25.1907 70.1295 25.1907Z" fill="white" />
                    <path d="M87.1474 28.4895V10.6562H101.533C102.162 10.6562 102.74 10.8132 103.269 11.1274C103.799 11.4416 104.22 11.8632 104.534 12.3924C104.849 12.905 105.006 13.4754 105.006 14.1038V18.9156C105.006 19.5439 104.849 20.1226 104.534 20.6518C104.22 21.1809 103.799 21.6026 103.269 21.9167C102.74 22.2144 102.162 22.3632 101.533 22.3632L90.4215 22.388V28.4895H87.1474ZM90.7191 19.0644H101.384C101.467 19.0644 101.541 19.0396 101.608 18.99C101.674 18.9404 101.707 18.8742 101.707 18.7916V14.2278C101.707 14.1451 101.674 14.079 101.608 14.0294C101.541 13.9632 101.467 13.9302 101.384 13.9302H90.7191C90.6364 13.9302 90.562 13.9632 90.4959 14.0294C90.4463 14.079 90.4215 14.1451 90.4215 14.2278V18.7916C90.4215 18.8742 90.4463 18.9404 90.4959 18.99C90.562 19.0396 90.6364 19.0644 90.7191 19.0644Z" fill="white" />
                  </svg>

                }
                <span className="testnetspan"><span className="testnetcircle"></span>{t('Navbar-T1')}</span>
              </a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                {theme == 'light-theme' ? <img src="\Assets\bar-light.svg" alt="img" className="img-fluid" /> : <img src="\Assets\bar-dark.svg" alt="img" className="img-fluid" />}
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">

                {/* <div className="shdvs">
                    <div className="ajnbdsajdbnsj eegergegergerge">

                      <div className="dropdown dropmenucssnew">
                        <button
                          className="btn btn-link efdfsgfdsgdfgdfgdfg dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <GlobeIcon />
                          <h1>{languageactive}</h1>

                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li>
                            <span className="dropdown-item-text">{t('Languages')}</span>
                          </li>
                          {languages.map(({ code, name, country_code }) => (
                            <li key={country_code}>
                              <a
                                href="#"
                                className='dropdown-item'
                                onClick={() => {
                                  changelanguage(code)
                                }}
                              >
                                <span
                                  className={`flag-icon flag-icon-${country_code} mx-2`}
                                  style={{
                                    opacity: currentLanguageCode === code ? 0.5 : 1,
                                  }}
                                ></span>
                                {name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                     

                    </div>
                  </div> */}

                <div class="dropdown" className="languagedropdown">
                  <button id="dropdown-basic" class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img style={{ width: "27px", height: "27px" }} src={imgeOfLanguage} alt="img" className='img-fluid' />{languageactive}
                  </button>
                  <ul class="dropdown-menu">
                    {languages?.map((e) => {

                      return (
                        <li key={e.country_code}>
                          <a
                           id="speicalAZ211" 
                            className="dropdownitm"
                            onClick={() => {
                              changelanguage(e.code)
                              setNameOfLanguage(e.name)
                              setImgeOfLanguage(e.igm)
                            }}><img src={e.igm} alt="img" className='img-fluid' />{e.name}</a>
                        </li>
                      )
                    })

                    }
                  </ul>
                </div>
             
                {account ?
                  <div onClick={handleShow1} className="walletmain  set-cursor">

                    {/* <div className="twice-btn">
                      <button  className='common-btnone w-100 wallet'>connect wallet</button>
                    </div> */}


                    <div className="wallettexts">
                      <h6 className="wallethead redishpara">{t('Navbar-T3')}</h6>
                      <p className="walletpara">            {account?.slice(0, 8)}...
                        {account.slice(account.length - 3, account.length)}</p>

                    </div>
                  </div>
                  :
                  <>
                    {isMetaMaskInstalled ?

                      <div onClick={handleShow} className="walletmain set-cursor">

                        {/* <div className="twice-btn">
                  <button  className='common-btnone w-100 wallet'>connect wallet</button>
                </div> */}


                        <div className="wallettexts">
                          <h6 className="wallethead">{t('Navbar-T2')}</h6>
                          {/* <p className="walletpara">Connect</p> */}
                        </div>
                      </div>
                      :
                      <>
                        {isBrowser ?
                          <div className="walletmain ms-auto set-cursor">
                            <a href="https://metamask.io/" target="_blank">
                              <div className="wallettexts">
                                <h6 className="wallethead">{t('Navbar-T2')}</h6>
                              </div>
                            </a>
                          </div>
                          :
                          <div onClick={handleShow} className="walletmain ms-auto set-cursor">

                            {/* <div className="twice-btn">
<button  className='common-btnone w-100 wallet'>connect wallet</button>
</div> */}


                            <div className="wallettexts">
                              <h6 className="wallethead">{t('Navbar-T2')}</h6>
                              {/* <p className="walletpara">Connect</p> */}
                            </div>
                          </div>

                        }
                      </>


                    }
                  </>
                }

                <div className="themeimg">
                  {theme == 'light-theme' ? <img style={{ cursor: 'pointer' }} onClick={toggleTheme} src="/Assets/header-assets/blacksun.svg" alt="moon" className="moon" /> : <img style={{ cursor: 'pointer' }} onClick={toggleTheme} src="/Assets/header-assets/sun.svg" alt="logo" className="logo" />}

                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
      <Modal className={languageselected == "ar" ? "important-note direction-for-arabic" : "important-note"} show={show} onHide={handleClose} centered backdrop="static" >
        <Modal.Body>
          <div className="inner-set">
            <div className="left">
              <h6>{t('connect-wallet')}</h6>
              <p><span className="bolddiii">{t('connect-wallet-T2')}</span> {t('connect-wallet-T1')}.
              </p>
            </div>
            {/* <div className="right">
              <img onClick={handleClose} src="\Assets\import-assets\close-circledark.svg" alt="img" className='img-fluid dark' />
              <img onClick={handleClose} src="\Assets\import-assets\close-circlewhite.svg" alt="img" className='img-fluid white' />
            </div> */}

          </div>

          <div className="twice-btn-wallet ">
            {/* {
              isBrowser&& */}

            {/* } */}
            {isBrowser ?
              <button  id="speicalAZ212"  className='btn-verifyss my-2 mt-3 metamask ' onClick={connectMetaMask1}>
                <img src="\Assets\nav-walt\metamask 2.svg" alt="tokeninnerimg" className="tokeninnerimg dark " />
                <img src="\Assets\nav-walt\metamaskwhite.svg" alt="tokeninnerimg" className="tokeninnerimg white " />
                Metamask</button>
              :
              <>
                {browserName === 'Safari' || browserName === "Mobile Safari" || browserName === "Brave" || browserName === 'Firefox' || browserName === 'Chrome' ?
                  <a  id="speicalAZ213"  href={localRouteId ? `https://metamask.app.link/dapp/testnet.dop.org/id=${localRouteId}/` : "https://metamask.app.link/dapp/testnet.dop.org/"} className="hideBTN">
                    <button className='btn-verifyss my-2 '>
                      <img src="\Assets\nav-walt\metamask 2.svg" alt="tokeninnerimg" className="tokeninnerimg dark " />
                      <img src="\Assets\nav-walt\metamaskwhite.svg" alt="tokeninnerimg" className="tokeninnerimg white " />
                      Metamask
                    </button>
                  </a>
                  :
                  <button  id="speicalAZ214"  className='btn-verifyss  my-2 ' onClick={connectMetaMask1}>
                    <img src="\Assets\nav-walt\metamask 2.svg" alt="tokeninnerimg" className="tokeninnerimg dark " />
                    <img src="\Assets\nav-walt\metamaskwhite.svg" alt="tokeninnerimg" className="tokeninnerimg white " />
                    Metamask
                  </button>
                }</>


            }



          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal className='important-note' show={show12} onHide={handleClose12}  backdrop="static"
        keyboard={false} centered>
        <Modal.Header >
          <Modal.Title>
            Claim Testnet Tokens</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="claimmodalpara">Claim your Testnet Tokens to explore DOP Testnet.</p>
          <button onClick={() => {
           localStorage.setItem("model1",true)
            handleClose12();
            handleShow();
          }} className='continuebutton'>
            Claim dop testnet tokens
          </button>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default Navbar;
