// export const API_URL = 'http://ec2-18-236-118-29.us-west-2.compute.amazonaws.com:8006/'
// export const API_URL = 'http://192.168.20.63:8000/' 
// export const API_URL = 'http://ec2-54-190-35-48.us-west-2.compute.amazonaws.com:8082/v1/'


// export const API_URL = 'https://staging-rewards.dop.org/'


export const API_URL = 'https://rewards-api.dop.org/'
// export const API_URL = 'http://ec2-18-236-118-29.us-west-2.compute.amazonaws.com:8004/'

// 




// export const API_URL = 'http://192.168.20.79:8000/'