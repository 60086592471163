import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import useWeb3 from "../../../hooks/useWeb3";
import { saveData, getData } from '../../../db';
import { toast } from 'react-toastify';
import { useWeb3React } from "@web3-react/core";
import WithDrawTpl from "../../../hooks/dataFetchers/WithDrawTpl";
import Signature1 from "../../../hooks/dataSenders/userSign1"
import Loader from '../../../hooks/loader';
import { io } from "socket.io-client";
import BalacefAccount from "../../../hooks/dataFetchers/BalaceOfAccount";
import AllowanceTpl from "../../../hooks/dataFetchers/AllowanceTpl";
import RouterPrice from '../../../hooks/dataFetchers/Rourterprice';
import { set } from 'lodash';
import axios from 'axios';
import { API_URL } from "../../../utils/ApiUrl";
import ApproveDop from '../../../hooks/dataFetchers/appriveDop.js';
import Environment from "../../../utils/Environment";
import { GetUsers } from '../../../redux/action';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { setupNetwork } from '../../../utils/wallet';
import BalacefAccountDop from '../../../hooks/balanceOfDop';
const Withdraw = ({internalAount,itemsAssets,setSelectedItemAsset,selectedItemAsset,setStateusCheck,setDefaultt,defalttt}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const [dopBlance,setDopBalance]=useState(null)
    let sign121 = localStorage.getItem('sign');
    let v = useSelector((state) => state?.UserReducer?.allowance)
    let withDrawStatus = useSelector((state) => state?.UserReducer?.withDraw)
    const localData2 = localStorage.getItem('idd');
    const { routerPrice } = RouterPrice()
    const { approveDop } = ApproveDop();
    const [itemsAssetsOrignal, setItemsAssetsOrignal] = useState(null);
    // const [allowance,setAlowance]=useState(null)
    // console.log(allowance,'widthraw');
    // console.log(v,'withdraw');
    const {balacefAccountDop}=BalacefAccountDop()
    useEffect(() => {
        // handleClick()
        if (account) {
            setItemsAssetsOrignal(itemsAssets)
        }
    }, [itemsAssets]);
    const { allowanceTpl } = AllowanceTpl();
    const [selectedIndex, setSelectedIndex] = useState("");
    const [selectedAccount, setSelectedAccount] = useState("");
    const [userBalance, setUserBalance] = useState(null)
    const [copied, setCopied] = useState(false);
    const [copied1, setCopied1] = useState(false);
    const [copied2, setCopied2] = useState(false);
    const { balacefAccount } = BalacefAccount()
    const [balancee, setBalancee] = useState(null)
    let typeParamId = ''
    const [loaderr, setLoarder] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => {
        setShow3(false)
        setAmount('')
        // setWalletAddress('')
        setTplPassword1('')


        setSelectedItemAsset('')
        setSelectedItemNumber('')
        setSelectedItemimg('')

        setSelectedIndex('')
        setUserBalance('')
        setSelectedAccount('')
    };
    const handleShow3 = () => setShow3(true);

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    const web3 = useWeb3();
    const [mod, setMod] = useState(false)
    const [tplPassword, setTplPassword] = useState(null)
    const [tplPassword1, setTplPassword1] = useState(null)
    const [walletAddres, setWalletAddress] = useState(null)
    const [amount, setAmount] = useState(false)
    const [newAmount, setNewAmount] = useState(null)
    const [check, setcheck] = useState(false)
    const { withDrawTpl } = WithDrawTpl();
    const { account,chainId } = useWeb3React();
    const { userSign } = Signature1()
    const [showgift, setShowgift] = useState(false);
    const handleClosegift = () => setShowgift(false);
    const [mainHsh, setHashh] = useState(null)
    const hide = () => {
        // console.log('dsfds', mod);
        if (mod) {
            setMod(false)
        }
        else {
            setMod(true)
        }
    }

    useEffect(() => {
        if (web3&&account){
        balaceOfDoppp()
        }
        // account
    }, [web3]);
    const balaceOfDoppp=async()=>{
        let v = await balacefAccountDop()
        setDopBalance(v)
        console.log(v,'vvvvvvvv');
    }
    const getPasswor = async () => {
        const result = await getData('tpl_password');
        setTplPassword(result)
    }
    useEffect(() => {
        getPasswor()

    }, []);
    const handler2 = (e) => {
        setTplPassword1(e.target.value)
    }
    const handler1 = (e) => {
        var length = e.target.value.split(" ").join("").length;
        setWalletAddress(e.target.value)
        setcheck(false)
        if (length === 42) {
            let r = web3.utils.isAddress(e.target.value)
            if (r) {
                setcheck(true)

            } else {
                setcheck(false)
            }
        }
    }
    const handler3 = (e) => {
        let a = parseFloat(e.target.value)
        if (a > userBalance) {
            setCopied1(true)
            setTimeout(() => {
                setCopied1(false)
            }, 2000)
            setAmount('')
        } else {
            setAmount(e.target.value)
            setNewAmount(a * 0.01)

        }
    }
    // console.log(newAmount,'amount');
    const confirm = async () => {
        const result = await getData('selected_privateKey');
        let dataHistory = await getData('tpl_data');

        let dumObj = dataHistory.find((i) => i.private_key == result)
        let newwallet = web3.eth.accounts.privateKeyToAccount(result);
        let newwallet1 = newwallet?.address
        let now = Date.now();
        let num = Math.floor((Math.random() * 1000000) + 1);
        // console.log(result,num,now,account,amount,'result,num,now,account,amount');
        const res1 = await userSign(result, num, now, amount, selectedIndex);
        if (res1) {
            // console.log(res1, 'res1');
            let res12 = res1.substring(2)
            // console.log(res12,'res12');
            let r = '0x' + res12.slice(0, 64);
            let s = '0x' + res12.slice(64, 128);
            let v = '0x' + res12.slice(128, 130);

            try {
                const res = await withDrawTpl(amount, newwallet1, now, num, v, r, s, walletAddres, selectedIndex);
                if (res) {
                    handleClose2();
                    handleShow3();
                    setHashh(res?.transactionHash)
                    // const socket = io("wss://socket.tdns.network", {
                    //     reconnectionDelayMax: 1000,
                    // });
                    // socket.on('connect', function () {
                    // });
                    // typeParamId = 'emited'
                    // socket.emit("bid", typeParamId);
                    const now = Date.now();
                    let historyObj = {
                        amount: amount,
                        date: now,
                        asets: selectedItemAsset,
                        hash: res?.transactionHash,

                    }
                    dumObj.withDraw.push(historyObj);
                    let index = dataHistory.indexOf(dumObj.private_key);
                    if (index > -1) {
                        historyObj = historyObj.splice(index, 1, dumObj)
                    }
                    await saveData('tpl_data', dataHistory);
                    let a = await api()
                    if (a) {

                        apiCall(a)
                        alowence()
                    }
                }
            } catch (e) {
                // toast.error(e.message);
                setLoarder(false)
                alowence()
                // toast.error('The Sepolia netwrok appears to be busy. Try again later')
                console.log(e, "e");
                // setMainLoader(false);
            }
        }
    }
    const switchChain=async()=>{
        if(chainId===11155111) 
        {
          submit()
        }
        else{
         let a = await setupNetwork()
         if (a){
          submit()
         }
        } 
    }
    const submit = async () => {
        setLoarder(true)
        if (account) {
            if (walletAddres && check) {
                if (amount) {
                    if (tplPassword1) {
                        if (tplPassword1 === tplPassword) {
                            // if(newAmount>26?){

                            // }else{

                            // }
                            // if(amount >= allowance)
                            // {
                            //     console.log('if ',allowance);
                            //     const w= await approveDop(amount)
                            //     if(w){

                            //         console.log('if if ');
                            //         confirm()
                            //     }
                            // }
                            // else{
                            //     console.log('else');
                            //     confirm()
                            // }
                            let a = newAmount > 26 ? newAmount : 26
                            // console.log(a,'aaaa',newAmount);
                            try {

                                let q = await routerPrice(a)
                                if (q) {
                                    let newVal = q[1] / 10 ** 18
                                    console.log(v,newVal,'qqqqq',dopBlance);
                                    if(dopBlance>=newVal){
                                    if (newVal >= v) {
                                        try {
                                           
                                            const w = await approveDop(10000000)
                                            if (w) {
                                                confirm()
                                            }
                                        } catch (err) {
                                            // toast.error('The Sepolia netwrok appears to be busy. Try again later')
                                            console.log(err);
                                            setLoarder(false)
        
                                        }
                                    }
        
                                    else {
                                        confirm()
                                    }
                                }
                                else{
                                    toast.error(t('new_eror'))
                                    setLoarder(false)
                                }
                            }
                            }catch(err){
                                console.log(err,'qqqqq');
                                setLoarder(false)
                                alowence()
                            }
                            // if (newAmount>26?){

                            // }

                        }
                        else {
                            setCopied2(true)
                            setTimeout(() => {
                                setCopied2(false)
                            }, 2000)
                            setLoarder(false)
                        }

                    } else {

                        setCopied2(true)
                        setTimeout(() => {
                            setCopied2(false)
                        }, 2000)
                        setLoarder(false)

                        setLoarder(false)
                    }
                } else {
                    setCopied1(true)
                    setTimeout(() => {
                        setCopied1(false)
                    }, 2000)
                    setLoarder(false)
                }
            } else {
                setCopied(true)
                setTimeout(() => {
                    setCopied(false)
                }, 2000)

                setLoarder(false)
            }
        } else {
            // toast.error('Please connect metamask')
            setLoarder(false)
        }
    }



    // useEffect(() => {
    //     // handleClick()
    //     if (account){
    //         balaceOf()
    //     //   setSelectedItem(items)
    //     }
    // }, [account]);
    // const balaceOf=async()=>{
    //     // var balance = await web3.eth.getBalance(account);
    //     let balace = await balacefAccount(account)
    //     // console.log(balace,'balace');
    //     if(balace){

    //         setBalancee(balace)
    //     }
    //   }
    useEffect(() => {
        setWalletAddress(account)
        setcheck(true)
    }, [account])

    const withdraHandler = (e) => {
        e?.preventDefault()
        if (walletAddres && check) {
            if (amount) {
                if (tplPassword1 === tplPassword) {
                    handleShow2()

                } else {

                    setCopied2(true)
                    setTimeout(() => {
                        setCopied2(false)
                    }, 2000)
                }
            } else {
                setCopied1(true)
                setTimeout(() => {
                    setCopied1(false)
                }, 2000)
            }

        }
        else {
            // console.log('else');
            setCopied(true)
            setTimeout(() => {
                setCopied(false)
            }, 2000)

        }
    }

    // const [selectedItemAsset, setSelectedItemAsset] = useState("");
    const [selectedItemNumber, setSelectedItemNumber] = useState("");
    const [selectedItemimg, setSelectedItemimg] = useState("");

    // const itemsAssets =
    //     [
    //         { igm: "/Assets/tpldashboard/myprivateassets/imgone.svg", name: "tpETH", Number: "1.312" },
    //         { igm: "/Assets/tpldashboard/myprivateassets/imgtwo.svg", name: "tpUSDT", Number: "1,521.63" },
    //         { igm: "/Assets/tpldashboard/myprivateassets/imgthree.svg", name: "tpUSDC", Number: "23,412.12" },
    //         { igm: "/Assets/tpldashboard/myprivateassets/imgfour.svg", name: "tpSHIB", Number: "12,232.56" },
    //         { igm: "/Assets/tpldashboard/myprivateassets/imgfive.svg", name: "tpLINK", Number: "1,434.31" },
    //         { igm: "/Assets/tpldashboard/myprivateassets/imgsix.svg", name: "tpUNI", Number: "1.312" },
    //         { igm: "/Assets/tpldashboard/myprivateassets/imgseven.svg", name: "tpAPE", Number: "1,521.63" },
    //         { igm: "/Assets/tpldashboard/myprivateassets/imgeight.svg", name: "tpPEPE", Number: "23,412.12" },
    //         { igm: "/Assets/tpldashboard/myprivateassets/imgnine.svg", name: "tpCHZ", Number: "12,232.56" },
    //         { igm: "/Assets/tpldashboard/myprivateassets/imgten.svg", name: "tpCOMP", Number: "1,434.31" },
    //         { igm: "/Assets/tpldashboard/myprivateassets/imgeleven.svg", name: "tpTOMI", Number: "541,123.23" }
    //     ]
    const handlerForSelect = (e, i) => {
        setSelectedItemAsset(e.name)
        setSelectedItemNumber(e.amountt?.toFixed(3))
        setSelectedItemimg(e.igm)
        handleClose1()
        setSelectedIndex(e.indecBy)
        setUserBalance(e.amountt?.toFixed(3))
        setAmount('')
        setSelectedAccount(e?.address)
        setTimeout(() => {
            setItemsAssetsOrignal(itemsAssets)
        }, 1000);
    }


    const amountHamderSearch = (e) => {
        let qw = (e.target.value)
        qw = qw?.toUpperCase()
        // console.log(qw);
        // let result = text.includes("world");
        // console.log(qw);
        let dummy = []
        for (let a = 0; a < 11; a++) {
            // console.log(itemsAssets[a]?.name?.toUpperCase()?.includes(qw));
            // console.log();
            let we = itemsAssets[a]?.name?.toUpperCase()
            if (qw != '') {
                if (we?.includes(qw)) {
                    // console.log('if',itemsAssets[a]?.name);
                    dummy.push(itemsAssets[a])
                }
                //  else if (qw==''){

                //     dummy.push(itemsAssetsOrignal[a])

                // }
            } else {
                dummy.push(itemsAssets[a])
            }
            // console.log(dummy,'dummy');
            setItemsAssetsOrignal(dummy)
        }
    }



    const apiCall = async (e) => {
        const axios = require('axios');
        let data = JSON.stringify({
            "decrypt_Assets": {
                "isCompleted": true
            }
            ,
            "sign": sign121,
            "internalWalletAddress": internalAount,
            "externalWalletAddress": account

        });

        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${API_URL}rewards/${e}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                //   console.log(JSON.stringify(response.data));
                setLoarder(false)
                localStorage.setItem('widthraw', true)
                //   setStateusCheck('dsfesad')
            })
            .catch((error) => {
                setLoarder(false)
                console.log(error);
                //   toast.error(error.response.data.message);
            });
    }


    // const alowence = async (e) => {
    //     try {
    //         // console.log(e);
    //         let r = await allowanceTpl(Environment.Dop)
    //         // console.log(r,r/10**18,'noooor');
    //         if(r){
    //             setAlowance(r/10**18)
    //         }

    //     }
    //     catch (err) {
    //         console.log(err.message, 'noor');
    //     }
    // }
    // useEffect(()=>{
    //     // const interval = setInterval(() => {
    //         alowence()
    //     // }, 10000);
    // },[account,allowance])
    const alowence = async (e) => {
        try {
            // console.log(e);
            let r = await allowanceTpl(Environment.Dop)
            // console.log(r, 'noooor');
            if (r) {
                dispatch(GetUsers(r / 10 ** 18))
            }
        }
        catch (err) {
            console.log(err.message, 'noor');
        }
    }

    useEffect(() => {
        handleClose3()
    }, [defalttt])

    const [clickedButton, setClickedButton] = useState(localStorage.getItem('clickedButton') || null);
    useEffect(() => {
        if (clickedButton === 'btnseven') {
            const element = document.querySelector('.maineight');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [clickedButton]);
    const Btnclick = (buttonName) => {
        setClickedButton(buttonName);
        localStorage.setItem('clickedButton', buttonName); // Save the clicked button to local storage
        window.location.reload();
    };


    const api = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${API_URL}rewards/walletaddress?internalWalletAddress=${internalAount}&&externalWalletAddress=${account}&&sign=${sign121}`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        let response = await axios.request(config)
        try {
            if (response) {
                if (response?.data?.data?.updatedReward) {
                    return response?.data?.data?.updatedReward?._id
                }
            }
        }
        catch (err) {
            setLoarder(false)
            console.log(err);
        }


    }
    var languageselected = localStorage.getItem('codelanguage')
    return (

        <>
            {loaderr &&

                <Loader text={t('check7')} />
            }

            <section className={languageselected == "ar" ? "deposit withdraw direction-for-arabic" : "deposit withdraw"}>
                <form onSubmit={(e) => withdraHandler(e)} className="w-100">

                    {/* <div className="asset" onClick={handleShow1}>
                    <div className="btn-drop">
                        <p>Private Assets <img src="\Assets\button-svgs\pending-icon.svg" alt="img" className='img-fluid ms-2 white' /><img src="\Assets\button-svgs\pending-iconlight.svg" alt="img" className='img-fluid ms-2 dark' /></p>
                        <h6>Select Assets</h6>
                        <img src="\Assets\button-svgs\arrow-down.svg" alt="img" className='img-fluid' />
                    </div>
                </div>
                <div className="tpl-address">
                    <div class="dropdown">
                        <p>Recipient Address <img src="\Assets\button-svgs\pending-icon.svg" alt="img" className='img-fluid ms-2 white' /><img src="\Assets\button-svgs\pending-iconlight.svg" alt="img" className='img-fluid ms-2 dark' /></p>
                        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Enter recipient address 
                        </button>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#">Account 01 | mqcmjqqo42DTQvn853rGxHfyvrEsc4xnth</a></li>
                            <li><a class="dropdown-item" href="#">Account 01 | mqcmjqqo42DTQvn853rGxHfyvrEsc4xnth</a></li>
                            <li><a class="dropdown-item" href="#">Account 01 | mqcmjqqo42DTQvn853rGxHfyvrEsc4xnth</a></li>
                        </ul>
                    </div>
                </div> */}
                    <div className="material-textfield">
                        <input placeholder={t('Sidebar-Transaction-H3-T1')} value={walletAddres} type="text" onChange={(e) => handler1(e)} />
                        <label>{t('External-Account')}</label>
                        {copied &&
                            <p className="text-error">*{t('plz')}</p>}
                        {/* <img src="\Assets\import-assets\eye-dark.svg" alt="eye" className='eye dark' />
                <img src="\Assets\import-assets\eye-white.svg" alt="eye" className='eye white' /> */}
                    </div>
                    <div className="asset" onClick={handleShow1} style={{ marginTop: '30px' }}>
                        <div className="btn-drop">
                            <p>{t('Select-encrypted-token')} </p>
                            {selectedItemAsset ? <>
                                <div className="selectmain">
                                    <div className="selectleft">
                                        <div className="selectimgmain">
                                            <img src={selectedItemimg} className='img-fluid selectinnerimg' />
                                            <div className="tpmainimg">
                                                <img src="\Assets\tpldashboard\history\rightcheckimg.svg" alt="img" className="tpinnerimg dark" />
                                                <img src="\Assets\tpldashboard\history\rightcheckimgdark.svg" alt="img" className="tpinnerimg white" />
                                            </div>
                                        </div>
                                        <h5 className="selecttext">{selectedItemAsset}</h5>
                                    </div>
                                    <h5 className="selectnumber">
                                        {selectedItemNumber}
                                    </h5>
                                </div>
                            </> :
                                <h6>{t('Select-encrypted-token-T1')}</h6>}
                            <img src="\Assets\button-svgs\arrow-down.svg" alt="img" className='img-fluid' />
                        </div>
                    </div>

                    <div className="material-textfield">
                        <input placeholder={t('D5')} value={amount} type="number" onChange={(e) => handler3(e)} />
                        {/* <label>Amount<img src="\Assets\button-svgs\pending-icon.svg" alt="img" className='img-fluid ms-2 white' /><img src="\Assets\button-svgs\pending-iconlight.svg" alt="img" className='img-fluid ms-2 dark' /></label> */}
                        {/* <img src="\Assets\import-assets\eye-dark.svg" alt="eye" className='eye dark' />
                <img src="\Assets\import-assets\eye-white.svg" alt="eye" className='eye white' /> */}
                    </div>
                    {copied1 &&
                        <p className="text-error">*{t('Insufficient-Funds')}</p>}
                    <div className="material-textfield">
                        <input value={tplPassword1} placeholder="************" type={mod ? "text" : "password"} onChange={(e) => handler2(e)} />
                        <label>{t('Sidebar-Transaction-H3-T2')} </label>
                        <img onClick={hide} src="\Assets\import-assets\eye-dark.svg" alt="eye" className='eye dark' />
                        <img onClick={hide} src="\Assets\import-assets\eye-white.svg" alt="eye" className='eye white' />
                    </div>
                    {copied2 &&
                        <p className="text-error">*{t('Invalid-Password')}</p>}
                    {/* <div className="balance-div">
                    <p>tpUSD</p>
                    <h6>{parseFloat(userBalance).toFixed(4)}</h6>
                </div> */}
                    <button id="speicalAZ263" type='submit' className='btn-deposit'>{t('Sidebar-Transaction-H3')}</button>
                </form>
            </section>

            {/* select network modal here..................... */}
            <Modal className={languageselected == "ar" ? "selectnetwork-modal direction-for-arabic" : "selectnetwork-modal"} show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Network</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="select-chain">
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\ether-icon.svg" alt="img" className='img-fluid' />
                            Ethereum
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\bsc-icon.svg" alt="img" className='img-fluid' />
                            Binance Smart Chain
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\avax-icon.svg" alt="img" className='img-fluid' />
                            Avalanche
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\polygon-icon.svg" alt="img" className='img-fluid' />
                            Polygon
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* select asset modal here..................... */}
            <Modal className={languageselected == "ar" ? "selectnetwork-modal direction-for-arabic" : "selectnetwork-modal"} show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Select-Asset')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="option-field">
                        <img src="\Assets\button-svgs\search-normal.svg" alt="img" className='img-fluid search-icon' />
                        <input type="search" placeholder={t('search-assets')} onChange={(e) => amountHamderSearch(e)} />
                    </div>
                    <div className="select-chain">
                        {
                            itemsAssetsOrignal?.map((e, i) => {
                                return (
                                    <div className="inner-chain" onClick={() => handlerForSelect(e, i)}>
                                        <div className='forflex'>
                                            <div className="modalmainimg">
                                                <img src={e?.igm} alt="img" className='img-fluid modalinnerimg' />
                                                <div className="tpmainimg">
                                                    <img src="\Assets\tpldashboard\history\rightcheckimg.svg" alt="img" className="tpinnerimg dark" />
                                                    <img src="\Assets\tpldashboard\history\rightcheckimgdark.svg" alt="img" className="tpinnerimg white" />
                                                </div>
                                            </div>
                                            <p className="modalmaintext">{e?.name}</p>
                                        </div>
                                        <p className="modalmainnumber"> {e?.amountt > 0 ? parseFloat(e?.amountt)?.toFixed(3) : "0.000"}</p>
                                    </div>)
                            })
                        }


                        {/* <div className="inner-chain">
                            <img src="\Assets\button-svgs\bsc-icon.svg" alt="img" className='img-fluid' />
                            Binance Smart Chain
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\avax-icon.svg" alt="img" className='img-fluid' />
                            Avalanche
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\polygon-icon.svg" alt="img" className='img-fluid' />
                            Polygon
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\ether-icon.svg" alt="img" className='img-fluid' />
                            Ethereum
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\bsc-icon.svg" alt="img" className='img-fluid' />
                            Binance Smart Chain
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\avax-icon.svg" alt="img" className='img-fluid' />
                            Avalanche
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\polygon-icon.svg" alt="img" className='img-fluid' />
                            Polygon
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\ether-icon.svg" alt="img" className='img-fluid' />
                            Ethereum
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\bsc-icon.svg" alt="img" className='img-fluid' />
                            Binance Smart Chain
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\avax-icon.svg" alt="img" className='img-fluid' />
                            Avalanche
                        </div>
                        <div className="inner-chain">
                            <img src="\Assets\button-svgs\polygon-icon.svg" alt="img" className='img-fluid' />
                            Polygon
                        </div> */}
                    </div>
                </Modal.Body>
            </Modal>

            {/* transaction summary modal here..................... */}
            <Modal className={languageselected == "ar" ? "summary-modal direction-for-arabic" : "summary-modal"} show={show2} onHide={handleClose2} centered>
                <Modal.Header >
                    <Modal.Title>
                        <h6>{t('Transaction-Summary')}</h6>
                        <p>{t('Transaction-Summary_T1')}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bottom-content">
                        <div className="inner-text">
                            <p>{t('W1')}</p>
                            <h6>Sepolia</h6>
                        </div>
                        <div className="inner-text">
                            <p>{t('W2')}</p>
                            <h6>{selectedItemAsset}</h6>
                        </div>
                        <div className="inner-text">
                            <p>{t('W3')}</p>
                            <h6>{walletAddres}</h6>
                        </div>
                        <div className="inner-text">
                            <p>{t('W4')}</p>
                            <h6>$ 26.000</h6>
                        </div>
                        {copied &&
                            <p className="text-error">*{t('W6')}</p>}

                        <div className="inner-text">
                            <p>{t('W5')}</p>
                            <h6>{amount ? parseFloat(amount).toFixed(4) : ''} {selectedItemAsset}</h6>
                        </div>
                        {copied1 &&
                            <p className="text-error">*{t('Insufficient-Funds')} </p>}
                        {copied2 &&
                            <p className="text-error">*{t('Incorrect-Password')}</p>}

                    </div>
                    <div className="twice-btn">
                        <button id="speicalAZ264" onClick={handleClose2} className='btn-cancel'>{t('Cancel')}</button>
                        <button id="speicalAZ265" onClick={switchChain} className='btn-confirm'>{t('Confirm')}</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* transaction progress modal here..................... */}


            {/* transaction summary modal here..................... */}
            <Modal className={languageselected == "ar" ? "success-modal direction-for-arabic" : "success-modal"} show={show4} onHide={handleClose4} centered>
                <Modal.Body>
                    <div className="success-div">
                        <img src="\Assets\checkmark.svg" alt="img" className='img-fluid dark' />
                        <img src="\Assets\checkmarklight.svg" alt="img" className='img-fluid white' />
                        <h6>Withdraw sent successfully</h6>
                        {/* <p>Check transaction history for more information.</p> */}
                    </div>
                    <div className="twice-btn">
                        <button id="speicalAZ267" onClick={handleClose4} className='btn-cancel'>Close</button>
                        <button id="speicalAZ268" className='btn-confirm'>Withdraw History</button>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal className={languageselected == "ar" ? "successsssss-modal direction-for-arabic" : "successsssss-modal"} show={show3} onHide={handleClose3} centered>
                <Modal.Body>
                    <div className="inner-content">
                        <img src="\Assets\checkmark.svg" alt="img" className='img-fluid dark' />
                        <img src="\Assets\checkmarklight.svg" alt="img" className='img-fluid white' />
                        <h6 className='main-head'>{t('Successfully-decrypted')}</h6>
                        <div className="inline-text">
                            <div className="tpmodalmainimg">
                                <img src={selectedItemimg} alt="img" className='img-fluid tpmodalinnerimg' />
                                <div className="tpmainedimg">
                                    <img src="\Assets\import-assets\tpUSDT.png" alt="innermainedimg" className="innermainedimg" />
                                </div>
                            </div>
                            <h4>{amount ? parseFloat(amount).toFixed(4) : '0.000'} <h6>{selectedItemAsset}</h6></h4>
                        </div>
                        <p className='para'>{t('Transaction-Summary_T3')}.</p>
                    </div>
                    <div className="twice-btns" style={{ gap: '20px' }}>
                        <button id="speicalAZ269" onClick={() => {
                            if (withDrawStatus === true) {
                                Btnclick('btnseven');
                                // window.location.reload()
                            } else {
                                handleClose3()
                                setShowgift(true)
                            }
                        }} className='btn-closee'>{t('Transaction-Summary_T4')}</button>
                        <button id="speicalAZ270" className='btn-explorer'> <a className='linksd' target="_blank" href={`https://sepolia.etherscan.io/tx/${mainHsh}`}>{t('Transaction-Summary_T5')} </a></button>

                        {/* <button className='btn-confirm'>transaction History</button> */}
                    </div>
                </Modal.Body>

            </Modal>


            <Modal className={languageselected == "ar" ? "important-note giftmodal-scss direction-for-arabic" : "important-note giftmodal-scss"} show={showgift} onHide={handleClosegift} centered backdrop="static" >
                <Modal.Body>
                    <div className="inner-set">
                        <div className="left">
                            <h6>{t('success')}</h6>
                        </div>
                    </div>

                    <div className="bottom-text">
                        <img src="\Assets\gift.svg" alt="img" className='img-fluid dark' />
                        <img src="\Assets\darkgift.svg" alt="img" className='img-fluid white' />
                        <h6>{t('Transaction-Summary_T6')}</h6>
                        <button id="speicalAZ271" className='btn-confirm' onClick={() => {
                            //   window.location.reload()
                            Btnclick('btnseven');
                            // window.location.reload()
                        }}> {t('Transaction-Summary_T7')} </button>
                    </div>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default Withdraw
