import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Navbar from '../landing/header/Navbar';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from "react-copy-to-clipboard";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useHistory } from "react-router-dom";
import { saveData, getData } from '../../db';
import Navbar1 from '../landing/header/navbar1';
import { useTranslation } from 'react-i18next'
const { ethers } = require("ethers");

const Congrats = () => {
  const { t } = useTranslation()
  const history = useHistory()
  // const value = localStorage.getItem('arry');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [privatekey, setPrivatekey] = useState(null)
  const [internal, setInternal] = useState(null)
  const [copied, setCopied] = useState(false);
  // console.log(value,'value');
  useEffect(() => {

    // setInternal()
    handleClick1()

  }, []);
  const copiedHandle = () => {
    setCopied(true);
    setTimeout(
      function () {
        setCopied(false);
      }.bind(this),
      1500
    );
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {copied ? t('copied') :  t('copy')}
    </Tooltip>
  );
  const handleClick = async () => {
    await saveData('selected_privateKey', privatekey);
    history.push('/unlock')
    // const result = await getData('key');
    // setData(result);
  };
  const handleClick1 = async () => {
    // await saveData('selected_privateKey',privatekey);
    // history.push('/unlock')
    const result1 = await getData('tpl_data');
    const result = await getData('tpl_phars');
    // result=result.toString()
    let bb = result.toString()
    // console.log(bb,'result');
    let b = bb.split(',').join(' ')
    // console.log(b,'b');
    try {

      const mnemonicWallet = ethers.Wallet.fromMnemonic(b)
      // console.log(mnemonicWallet,'mnemonicWallet');
      if (!result1) {
        await saveData('tpl_data', [{
          private_key: mnemonicWallet.privateKey,
          account_address: mnemonicWallet.address,
          depoite: [],
          transfer: [],
          withDraw: [],
        }]);
      } else {
        result1.push(
          {
            private_key: mnemonicWallet.privateKey,
            account_address: mnemonicWallet.address,
            depoite: [],
            transfer: [],
            withDraw: [],
          }
        )
        await saveData('tpl_data', result1);
      }

      // await saveData("selected_privateKey",mnemonicWallet.privateKey)
      setPrivatekey(mnemonicWallet.privateKey)
      handleClick2(mnemonicWallet.privateKey)
    }
    catch (err) {
      console.log(err, 'err');
    }
    // setData(result);
  };
  const handleClick2 = async (a) => {
    await saveData('selected_privateKey', a);
    // setData(result);
  };
  // console.log(privatekey,'privatekey');

  const handleClick12 = async () => {
    // await saveData('tpl_password',pasword);
    // const result = await getData('tpl_password');
    // const result1 = await getData('selected_privateKey');
    // const mail = await localStorage.getItem('mail')
    // console.log(mail,'mail');
    // if(!mail){
      // history.push('/login')
    // }
    // if(result && result1){
    //   history.push('/unlock')
    }
    // setData(result);
  // };
    // setData(result);
  // };
  // useEffect(() => {
  //   handleClick12()
  // },[]);
  var languageselected = localStorage.getItem('codelanguage')
  return (
    <>
      <Navbar1 />
      <section className={languageselected == "ar" ? "congrats direction-for-arabic" : "congrats"}>
        <div className="theme-container">
          <div className="congrats-card">
            <img src="\Assets\import-assets\congrats.svg" alt="img" className='img-fluid' />
            <div className="upper-heading">
              <h6>{t('route-Congrats-H1')}!</h6>
              <p>{t('route-Congrats-T1')}.</p>
            </div>
            <div className="bottom-content">
              <h6>{t('route-Congrats-T2')}</h6>
              <ul>
                <li>{t('route-Congrats-T3')}.</li>
                <li>{t('route-Congrats-T4')}.</li>
                <li> {t('route-Congrats-T5')}.</li>
              </ul>
            </div>
            <div className="twice-btn">
              <button id="speicalAZ223" onClick={handleShow} className='btn-verify done-btn w-100'>{t('route-landing-TermCheckbox-B1')}</button>
            </div>
          </div>
        </div>
      </section>

      <Modal className={languageselected == "ar" ? "important-note direction-for-arabic" : "important-note"} show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Body>
          <div className="inner-set">
            <div className="left">
              <h6>{t('Landing-Impt-T1')}</h6>
              <p>{t('route-Congrats-Impt-Note-T1')}.</p>
            </div>
            {/* <div className="right">
              <img onClick={handleClose} src="\Assets\import-assets\close-circle.svg" alt="img" className='img-fluid' />
            </div> */}
          </div>
          <h6 className="address-text">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
              className=""
            >
              <CopyToClipboard
                text={privatekey}
                onCopy={() => copiedHandle()}
              >
                <p className="copy_data click___One d-flex align-items-center ">
                  <span className="gsvsvcvst">{privatekey}</span> <img src="/Assets/button-svgs/copy.svg" alt="" className="logoimggpccro ml-3" />
                </p>
              </CopyToClipboard>
            </OverlayTrigger>
          </h6>
          <div className="twice-btn">
            {/* <button className='btn-skip'>Copy</button>  */}
            {/* <Link to="/unlock" className='w-100'> */}
            <button id="speicalAZ224" onClick={handleClick} className='btn-verify w-100'>{t('route-Congrats-Impt-Note-B1')}</button>
            {/* </Link> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Congrats
