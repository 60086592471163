import React, { useEffect, useState } from 'react'
import './tpldashboard.scss'
import Navbar from '../landing/header/Navbar'
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Deposithistory from './History/Deposithistory';
import Transferhistory from './History/Transferhistory';
import Withdrawhistory from './History/Withdrawhistory';
import PrivateAssets from './Account/PrivateAssets';
import CreateAccount from './Account/CreateAccount';
import ImportAccount from './Account/ImportAccount';
import Transaction from './Transaction/Transaction';
import GeneralSetting from './Preference/GeneralSetting';
import { saveData, getData } from '../../db';
import { Link, useHistory } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import Claim from '../Claim/Claim';
import Earnrewards from '../Earnrewards/Earnrewards';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { API_URL } from "../../utils/ApiUrl";
import { setupNetwork } from "../../utils/wallet";
import { useTranslation } from 'react-i18next'

const Tpldashboard = () => {
    const { t } = useTranslation()
    // let localll = localStorage.getItem('modal')
    const localdat = localStorage.getItem('idd')
    const localIp = localStorage.getItem('ip')
    const localCountry = localStorage.getItem('country')
    const localRouteId = localStorage.getItem('routeId')
    const localRouteEmail = localStorage.getItem('email')
    let sign121 = localStorage.getItem('sign');

    const [selectedItemAsset, setSelectedItemAsset] = useState("");
    const [defalttt, setDefaultt] = useState(null)
    const [refralCode, setRefralCode] = useState(null)

    const [itemsAssets, setItemsAssets] = useState(
        [
            // { igm: "/Assets/tpldashboard/myprivateassets/imgone.svg", name: "Sepolia ETH", indecBy: "0", Number: "1.312" },
            { igm: "/Assets/tpldashboard/myprivateassets/imgtwo.svg", name: "DOP_USDT", indecBy: "21", Number: "1,521.63" },
            { igm: "/Assets/tpldashboard/myprivateassets/imgthree.svg", name: "DOP_USDC", indecBy: "22", Number: "23,412.12" },
            { igm: "/Assets/tpldashboard/myprivateassets/imgfour.svg", name: "DOP_SHIB", indecBy: "23", Number: "12,232.56" },
            { igm: "/Assets/tpldashboard/myprivateassets/imgfive.svg", name: "DOP_LINK", indecBy: "24", Number: "1,434.31" },
            { igm: "/Assets/tpldashboard/myprivateassets/imgsix.svg", name: "DOP_UNI", indecBy: "25", Number: "1.312" },
            { igm: "/Assets/tpldashboard/myprivateassets/imgseven.svg", name: "DOP_APE", indecBy: "26", Number: "1,521.63" },
            { igm: "/Assets/tpldashboard/myprivateassets/imgeight.svg", name: "DOP_PEPE", indecBy: "27", Number: "23,412.12" },
            { igm: "/Assets/tpldashboard/myprivateassets/imgnine.svg", name: "DOP_CHZ", indecBy: "28", Number: "12,232.56" },
            { igm: "/Assets/tpldashboard/myprivateassets/imgten.svg", name: "DOP_COMP", indecBy: "29", Number: "1,434.31" },
            { igm: "/Assets/tpldashboard/myprivateassets/imgeleven.svg", name: "DOP_TOMI", indecBy: "30", Number: "541,123.23" }
        ])
    const { account, chainId } = useWeb3React();
    const history = useHistory()
    const [userBalance, setBalance] = useState(null)
    const [internalAount, setInternalAount] = useState(null)
    const [show, setShow] = useState(false);
    const [stateusCheck, setStateusCheck] = useState(null)
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // }, [])
    const handleClick = async () => {
        // await saveData('tpl_password',pasword);
        const result = await getData('tpl_password');
        const result1 = await getData('selected_privateKey');
        // const mail = await localStorage.getItem('mail')
        // console.log(mail,'mail');
        // if(!mail){
        //   history.push('/login')
        // }
        if (!result && !result1) {
            history.push('/')
            return
        }
        let localFirst = localStorage.getItem('firstTime')
        if (!localFirst) {
            localStorage.setItem('firstTime', 'true')
            clickmeee()
            setDefaultt('earn')
        } else {
            setDefaultt('earn')
        }
        // setData(result);
    };
    useEffect(() => {
        handleClick()
    }, []);
    const handlerrr = () => {
        if (account) {
            setShow(false)
        } else {
            setShow(true)
        }
    }

    const [show2, setShow2] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    let localModel = localStorage.getItem('model2')

    //     useEffect(() => {
    //         if (!localModel){
    //          handleShow2()
    //        }
    //      dcs

    //    }, [])






    const apiCall = async () => {
        let data
        if (localRouteId) {
            data = JSON.stringify({
                "internalWalletAddress": internalAount,
                "externalWalletAddress": account,
                "ip": `http://${localIp}/`,
                "referalByCode": localRouteId,
                "location": `${localCountry}`,
                "email": !localRouteEmail || localRouteEmail == 'null' || localRouteEmail == 'undefined' ? '' : localRouteEmail,
                "sign": sign121
            });
        } else {
            data = JSON.stringify({
                "internalWalletAddress": internalAount,
                "externalWalletAddress": account,
                "ip": `http://${localIp}/`,
                "location": `${localCountry}`,
                "email": !localRouteEmail || localRouteEmail == 'null' || localRouteEmail == 'undefined' ? '' : localRouteEmail,
                "sign": sign121
            });
        }


        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${API_URL}rewards`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(response, 'apiiiii', response, response.data.data.reward._id, response.data.data.user.referalCode);
                localStorage.setItem('idd', response.data.data.reward._id)
                // localStorage.setItem('linke', `https://testnet.dop.org/id=${response.data.data.user.referalCode}`)
                setStateusCheck('assdsa')
            })
            .catch((error) => {
                console.log(error, 'apiiii');
                //   localStorage.setItem('idd',null)
            });
    }

    useEffect(() => {
        if (sign121) {
            if (internalAount) {
                // if(!localdat){
                if (account) {

                    apiCall()
                }
            }
            // }


        }
    }, [internalAount, account, sign121])




    const clickmeee = () => {
    //     if (!localModel && localll) {
    //         handleShow2()

        // }
        setDefaultt('earn')

    }
    useEffect(() => {
        // console.log(chainId,'chainIdchainId');
        if (account) {
            if (chainId != 11155111) {
                setupNetwork()
            }
        }

    }, [account, chainId])

    // console.log(defalttt,'internalAount');
    var languageselected = localStorage.getItem('codelanguage')

    return (
        <>
            <Navbar setDefaultt={setDefaultt} setItemsAssets={setItemsAssets} itemsAssets={itemsAssets} setBalance={setBalance} setInternalAount={setInternalAount} internalAount={internalAount} show={show} setShow={setShow} />

            <section className={languageselected == "ar" ? "tpldashboard direction-for-arabic" : "tpldashboard"}>
                <div className="theme-container">
                    {defalttt &&
                        <Tab.Container id="left-tabs-example" activeKey={defalttt} defaultActiveKey={defalttt} onSelect={() => {

                            // console.log('chal gaya');
                        }}>
                            <Row>
                                <Col xl={3} lg={12} className="pe-0 padd-sm yvdycvyvdyuvc">
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link onClick={clickmeee} className='newclaimlink tpldashboard-nav mb-4' eventKey="earn" >
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className='white'>
                                                    <path d="M18.3333 11V16.5C18.3333 18.5259 16.6925 20.1667 14.6666 20.1667H7.33329C5.30746 20.1667 3.66663 18.5259 3.66663 16.5V11C3.66663 10.4959 4.07913 10.0834 4.58329 10.0834H6.38913C6.89329 10.0834 7.30579 10.4959 7.30579 11V13.8784C7.30579 14.5567 7.68163 15.18 8.27746 15.5009C8.54329 15.6475 8.83663 15.7209 9.13913 15.7209C9.48746 15.7209 9.83579 15.62 10.1383 15.4184L11.0091 14.85L11.8158 15.3909C12.375 15.7667 13.09 15.8125 13.6858 15.4917C14.2908 15.1709 14.6666 14.5567 14.6666 13.8692V11C14.6666 10.4959 15.0791 10.0834 15.5833 10.0834H17.4166C17.9208 10.0834 18.3333 10.4959 18.3333 11Z" fill="black" />
                                                    <path d="M19.7083 6.41671V7.33337C19.7083 8.34171 19.2225 9.16671 17.875 9.16671H4.12496C2.72246 9.16671 2.29163 8.34171 2.29163 7.33337V6.41671C2.29163 5.40837 2.72246 4.58337 4.12496 4.58337H17.875C19.2225 4.58337 19.7083 5.40837 19.7083 6.41671Z" fill="black" />
                                                    <path d="M10.67 4.58328H5.61001C5.29834 4.24411 5.30751 3.72161 5.63751 3.39161L6.93917 2.08995C7.27834 1.75078 7.83751 1.75078 8.17667 2.08995L10.67 4.58328Z" fill="black" />
                                                    <path d="M16.3808 4.58328H11.3208L13.8141 2.08995C14.1533 1.75078 14.7125 1.75078 15.0516 2.08995L16.3533 3.39161C16.6833 3.72161 16.6925 4.24411 16.3808 4.58328Z" fill="black" />
                                                    <path d="M12.8058 10.0834C13.3099 10.0834 13.7224 10.4959 13.7224 11V13.8692C13.7224 14.6025 12.9066 15.0425 12.3016 14.63L11.4766 14.08C11.1741 13.8784 10.7799 13.8784 10.4683 14.08L9.60661 14.6484C9.00161 15.0517 8.19495 14.6117 8.19495 13.8875V11C8.19495 10.4959 8.60745 10.0834 9.11161 10.0834H12.8058Z" fill="black" />
                                                </svg>

                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className='dark'>
                                                    <path d="M18.3334 10.9999V16.4999C18.3334 18.5258 16.6925 20.1666 14.6667 20.1666H7.33335C5.30752 20.1666 3.66669 18.5258 3.66669 16.4999V10.9999C3.66669 10.4958 4.07919 10.0833 4.58335 10.0833H6.38919C6.89335 10.0833 7.30585 10.4958 7.30585 10.9999V13.8783C7.30585 14.5566 7.68169 15.1799 8.27752 15.5008C8.54335 15.6474 8.83669 15.7208 9.13919 15.7208C9.48752 15.7208 9.83585 15.6199 10.1384 15.4183L11.0092 14.8499L11.8159 15.3908C12.375 15.7666 13.09 15.8124 13.6859 15.4916C14.2909 15.1708 14.6667 14.5566 14.6667 13.8691V10.9999C14.6667 10.4958 15.0792 10.0833 15.5834 10.0833H17.4167C17.9209 10.0833 18.3334 10.4958 18.3334 10.9999Z" fill="white" />
                                                    <path d="M19.7084 6.41659V7.33325C19.7084 8.34159 19.2225 9.16658 17.875 9.16658H4.12502C2.72252 9.16658 2.29169 8.34159 2.29169 7.33325V6.41659C2.29169 5.40825 2.72252 4.58325 4.12502 4.58325H17.875C19.2225 4.58325 19.7084 5.40825 19.7084 6.41659Z" fill="white" />
                                                    <path d="M10.67 4.5834H5.61001C5.29834 4.24424 5.30751 3.72174 5.63751 3.39174L6.93917 2.09007C7.27834 1.7509 7.83751 1.7509 8.17667 2.09007L10.67 4.5834Z" fill="white" />
                                                    <path d="M16.3809 4.5834H11.3209L13.8142 2.09007C14.1534 1.7509 14.7125 1.7509 15.0517 2.09007L16.3534 3.39174C16.6834 3.72174 16.6925 4.24424 16.3809 4.5834Z" fill="white" />
                                                    <path d="M12.8058 10.0833C13.31 10.0833 13.7225 10.4958 13.7225 10.9999V13.8691C13.7225 14.6024 12.9067 15.0424 12.3017 14.6299L11.4767 14.0799C11.1742 13.8783 10.78 13.8783 10.4683 14.0799L9.60667 14.6483C9.00167 15.0516 8.19501 14.6116 8.19501 13.8874V10.9999C8.19501 10.4958 8.60751 10.0833 9.11167 10.0833H12.8058Z" fill="white" />
                                                </svg>
                                                {t('Sidebar-maintext')}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <div className='tpldashboard-nav'>
                                            <Nav.Item>
                                                <div class="accordion" id="accordionExample">
                                                    <div class="accordion-item">
                                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className='iconns'>
                                                                <path d="M13.6118 3.62057V7.10391H12.2368V3.62057C12.2368 3.37307 12.0168 3.25391 11.8702 3.25391C11.8243 3.25391 11.7785 3.26307 11.7327 3.28141L4.46352 6.02224C3.97768 6.20557 3.66602 6.66391 3.66602 7.18641V7.80057C2.83185 8.42391 2.29102 9.42307 2.29102 10.5506V7.18641C2.29102 6.09557 2.96018 5.12391 3.97768 4.73891L11.256 1.98891C11.4577 1.91557 11.6685 1.87891 11.8702 1.87891C12.7868 1.87891 13.6118 2.62141 13.6118 3.62057Z" fill="#252525" />
                                                                <path d="M19.7093 13.2913V14.208C19.7093 14.4555 19.5168 14.6572 19.2601 14.6663H17.9218C17.4359 14.6663 16.9959 14.3088 16.9593 13.8322C16.9318 13.548 17.0418 13.2822 17.2251 13.0988C17.3901 12.9247 17.6193 12.833 17.8668 12.833H19.2509C19.5168 12.8422 19.7093 13.0438 19.7093 13.2913Z" fill="#252525" />
                                                                <path d="M17.856 11.8712H18.791C19.2952 11.8712 19.7077 11.4587 19.7077 10.9545V10.5512C19.7077 8.65366 18.1585 7.10449 16.261 7.10449H5.73768C4.95852 7.10449 4.24352 7.36116 3.66602 7.80116C2.83185 8.42449 2.29102 9.42366 2.29102 10.5512V16.7203C2.29102 18.6178 3.84018 20.167 5.73768 20.167H16.261C18.1585 20.167 19.7077 18.6178 19.7077 16.7203V16.5462C19.7077 16.042 19.2952 15.6295 18.791 15.6295H17.9935C17.1135 15.6295 16.2702 15.0887 16.041 14.2362C15.8485 13.5395 16.0777 12.8703 16.536 12.4212C16.8752 12.0728 17.3427 11.8712 17.856 11.8712ZM12.8327 11.6878H6.41602C6.04018 11.6878 5.72852 11.3762 5.72852 11.0003C5.72852 10.6245 6.04018 10.3128 6.41602 10.3128H12.8327C13.2085 10.3128 13.5202 10.6245 13.5202 11.0003C13.5202 11.3762 13.2085 11.6878 12.8327 11.6878Z" fill="#252525" />
                                                            </svg>

                                                            {t('Sidebar-Account')}
                                                        </button>
                                                        <div id="collapseOne" class="accordion-collapse collapse  forsetting" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div class="accordion-body">
                                                                <Nav.Link eventKey="privateassets"
                                                                    onClick={() => {
                                                                        setDefaultt('privateassets')
                                                                    }}
                                                                >
                                                                    {t('Sidebar-Account-H1')}
                                                                </Nav.Link>
                                                                <Link className='nav-link' to='/secretrecoveryphrase' >
                                                                    {t('Sidebar-Account-H2')}
                                                                </Link>
                                                                <Link className='nav-link' to='/import'>
                                                                    {t('Sidebar-Account-H3')}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <div class="accordion" id="accordionExample">
                                                    <div class="accordion-item">
                                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={account ? "#collapsetwo" : ''} aria-expanded="true" aria-controls="collapseOne" onClick={handlerrr}>
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className='iconns'>
                                                                <path d="M14.8415 1.83301H7.15982C3.82315 1.83301 1.83398 3.82217 1.83398 7.15884V14.8313C1.83398 18.1772 3.82315 20.1663 7.15982 20.1663H14.8323C18.169 20.1663 20.1582 18.1772 20.1582 14.8405V7.15884C20.1673 3.82217 18.1782 1.83301 14.8415 1.83301ZM16.3632 12.9247C16.3265 13.0072 16.2807 13.0805 16.2165 13.1447L13.4298 15.9313C13.2923 16.0688 13.1182 16.133 12.944 16.133C12.7698 16.133 12.5957 16.0688 12.4582 15.9313C12.1923 15.6655 12.1923 15.2255 12.4582 14.9597L14.0715 13.3463H6.27982C5.90398 13.3463 5.59232 13.0347 5.59232 12.6588C5.59232 12.283 5.90398 11.9713 6.27982 11.9713H15.7307C15.8223 11.9713 15.9048 11.9897 15.9965 12.0263C16.1615 12.0997 16.299 12.228 16.3723 12.4022C16.4273 12.5672 16.4273 12.7597 16.3632 12.9247ZM15.7215 10.0188H6.27982C6.18815 10.0188 6.10565 10.0005 6.01398 9.96384C5.84898 9.89051 5.71148 9.76217 5.63815 9.58801C5.56482 9.42301 5.56482 9.23051 5.63815 9.06551C5.67482 8.98301 5.72065 8.90967 5.78482 8.84551L8.57149 6.05884C8.83732 5.79301 9.27732 5.79301 9.54315 6.05884C9.80898 6.32467 9.80898 6.76467 9.54315 7.03051L7.93898 8.64384H15.7307C16.1065 8.64384 16.4182 8.95551 16.4182 9.33134C16.4182 9.70717 16.1065 10.0188 15.7215 10.0188Z" fill="#252525" />
                                                            </svg>

                                                            {t('Sidebar-Transaction')}
                                                        </button>
                                                        <div id="collapsetwo" class="accordion-collapse collapse forsetting" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div class="accordion-body">
                                                                <Nav.Link eventKey="deposit" onClick={() => {
                                                                    setDefaultt('deposit')
                                                                }}>
                                                                    {t('Sidebar-Transaction-H1')}
                                                                </Nav.Link>
                                                                <Nav.Link eventKey="transfer" onClick={() => {
                                                                    setDefaultt('transfer')
                                                                }}>
                                                                    {t('Sidebar-Transaction-H2')}
                                                                </Nav.Link>
                                                                <Nav.Link eventKey="withdraw"
                                                                    onClick={() => {
                                                                        setDefaultt('withdraw')
                                                                    }}>
                                                                    {t('Sidebar-Transaction-H3')}
                                                                </Nav.Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <div class="accordion" id="accordionExample">
                                                    <div class="accordion-item">
                                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethree" aria-expanded="true" aria-controls="collapseOne">
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className='iconns'>
                                                                <path d="M12.375 1.375C7.53573 1.375 3.53066 4.96893 2.85986 9.625H0L4.125 13.75L8.25 9.625H5.63897C6.27788 6.49096 9.05516 4.125 12.375 4.125C16.1657 4.125 19.25 7.20934 19.25 11C19.25 14.7907 16.1657 17.875 12.375 17.875C10.1695 17.875 8.08215 16.8048 6.79174 15.0136L4.56005 16.6195C6.36609 19.1278 9.28729 20.625 12.375 20.625C17.6823 20.625 22 16.3067 22 11C22 5.69337 17.6823 1.375 12.375 1.375Z" fill="#252525" />
                                                                <path d="M11 6.875V12.4663L15.1049 14.929L16.5201 12.5711L13.75 10.9087V6.87503L11 6.875Z" fill="#252525" />
                                                            </svg>
                                                            {t('Sidebar-History')}
                                                        </button>
                                                        <div id="collapsethree" class="accordion-collapse collapse forsetting" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div class="accordion-body">
                                                                <Nav.Link eventKey="deposithistory"
                                                                    onClick={() => {
                                                                        setDefaultt('deposithistory')
                                                                    }}>
                                                                    {t('Sidebar-History-H1')}
                                                                </Nav.Link>
                                                                <Nav.Link eventKey="transferhistory" onClick={() => {
                                                                    setDefaultt('transferhistory')
                                                                }}>
                                                                    {t('Sidebar-History-H2')}
                                                                </Nav.Link>
                                                                <Nav.Link eventKey="withdrawhistory" onClick={() => {
                                                                    setDefaultt('withdrawhistory')
                                                                }}>
                                                                    {t('Sidebar-History-H3')}
                                                                </Nav.Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <div class="accordion" id="accordionExample">
                                                    <div class="accordion-item">
                                                        {/* <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="true" aria-controls="collapseOne">
                                                   <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className='iconns'>
                                                       <path d="M18.4257 8.45165C16.7665 8.45165 16.0882 7.27832 16.9132 5.83915C17.3898 5.00498 17.1057 3.94165 16.2715 3.46498L14.6857 2.55748C13.9615 2.12665 13.0265 2.38332 12.5957 3.10748L12.4948 3.28165C11.6698 4.72082 10.3132 4.72082 9.47898 3.28165L9.37815 3.10748C8.96565 2.38332 8.03065 2.12665 7.30648 2.55748L5.72065 3.46498C4.88648 3.94165 4.60232 5.01415 5.07898 5.84832C5.91315 7.27832 5.23482 8.45165 3.57565 8.45165C2.62232 8.45165 1.83398 9.23081 1.83398 10.1933V11.8066C1.83398 12.76 2.61315 13.5483 3.57565 13.5483C5.23482 13.5483 5.91315 14.7216 5.07898 16.1608C4.60232 16.995 4.88648 18.0583 5.72065 18.535L7.30648 19.4425C8.03065 19.8733 8.96565 19.6166 9.39648 18.8925L9.49732 18.7183C10.3223 17.2791 11.679 17.2791 12.5132 18.7183L12.614 18.8925C13.0448 19.6166 13.9798 19.8733 14.704 19.4425L16.2898 18.535C17.124 18.0583 17.4082 16.9858 16.9315 16.1608C16.0973 14.7216 16.7757 13.5483 18.4348 13.5483C19.3882 13.5483 20.1765 12.7691 20.1765 11.8066V10.1933C20.1673 9.23998 19.3882 8.45165 18.4257 8.45165ZM11.0007 13.9791C9.35982 13.9791 8.02148 12.6408 8.02148 11C8.02148 9.35915 9.35982 8.02082 11.0007 8.02082C12.6415 8.02082 13.9798 9.35915 13.9798 11C13.9798 12.6408 12.6415 13.9791 11.0007 13.9791Z" fill="#252525" />
                                                   </svg>
                                                   Preferences
                                               </button> */}
                                                        <div id="collapsefour" class="accordion-collapse collapse forsetting" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div class="accordion-body">
                                                                <Nav.Link eventKey="general">
                                                                    General
                                                                </Nav.Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link className='newclaimlink' eventKey="claim" onClick={() => {

                                                    setDefaultt('claim')
                                                }}>
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className='white'>
                                                        <path d="M15.1237 0.687724C14.6664 0.687711 14.6631 1.36075 15.1263 1.37588C17.7975 1.37588 20.0775 3.29242 20.5414 5.92316C21.0052 8.55391 19.5182 11.1344 17.0081 12.0481C14.5452 12.9445 11.8002 11.975 10.4362 9.75333L11.0405 9.91512C11.4884 10.0423 11.67 9.36411 11.2184 9.25047L9.80985 8.87249C9.59065 8.82234 9.43167 8.9598 9.38892 9.1162L9.01095 10.5241C8.88377 10.9721 9.56196 11.1536 9.67558 10.702L9.83872 10.0917C11.3651 12.607 14.4652 13.7025 17.2417 12.6919C20.061 11.6657 21.7391 8.75981 21.2181 5.805C20.6871 2.7388 17.9762 0.662711 15.1237 0.687724ZM8.22547 13.0631C7.69228 13.0631 6.74297 13.4226 5.9892 13.7834C5.84171 13.854 5.75517 13.9022 5.62735 13.9661C5.76077 14.2104 5.84352 14.4863 5.84352 14.7831V19.4169C6.28127 19.3406 6.72532 19.2706 7.21979 19.2517C7.64472 19.2517 8.439 19.854 8.95522 20.2185C9.69084 20.7378 10.4832 21.3122 11.3425 21.3122C11.6012 21.3122 11.861 21.2271 12.2032 21.0994C14.0837 20.3963 18.0502 18.1523 20.3608 16.8006C20.9454 16.4772 21.1375 15.7285 20.8072 15.1564C20.4903 14.6075 19.847 14.4738 19.2215 14.6871L14.9054 16.1389C14.9363 16.4198 14.886 16.7109 14.7349 16.9728C14.357 17.6273 13.5116 17.8962 12.8571 17.5183L10.7712 16.3152C10.34 16.0951 10.7117 15.4558 11.1163 15.7217L13.1995 16.9241C13.5345 17.1175 13.9453 17.008 14.1387 16.673C14.3321 16.338 14.222 15.9279 13.8869 15.7345C12.353 14.8488 9.62983 13.0631 8.22547 13.0631ZM1.72071 13.7506C1.14208 13.7506 0.6875 14.2184 0.6875 14.7838V19.5942C0.6875 20.1572 1.14239 20.6267 1.71399 20.6267H4.12621C4.75385 20.6267 5.15606 20.0934 5.15606 19.5935V14.7831C5.15606 14.2055 4.6888 13.7506 4.12688 13.7506H1.72071ZM2.4075 18.5636C2.59733 18.5636 2.75123 18.7175 2.75123 18.9073C2.75123 19.0972 2.59733 19.2511 2.4075 19.2511C2.21766 19.2511 2.06376 19.0972 2.06376 18.9073C2.06376 18.7175 2.21766 18.5636 2.4075 18.5636Z" fill="black" />
                                                        <g clip-path="url(#clip0_1178_8828)">
                                                            <path d="M12.739 4.95988L12.0745 4.29541C11.4259 4.99561 11.0555 5.8906 11.0189 6.84372H11.9571C11.9938 6.12497 12.2834 5.46425 12.739 4.95988Z" fill="black" />
                                                            <path d="M12.2954 9.92558C12.9956 10.5741 13.8906 10.9446 14.8437 10.9811V10.043C14.125 10.0063 13.4643 9.71662 12.9599 9.26111L12.2954 9.92558Z" fill="black" />
                                                            <path d="M18.043 6.84374H18.9811C18.9446 5.89061 18.5741 4.99563 17.9256 4.29541L17.2611 4.95989C17.7166 5.46427 18.0063 6.12499 18.043 6.84374Z" fill="black" />
                                                            <path d="M17.7046 4.07447C17.0044 3.42594 16.1094 3.05547 15.1562 3.01892V3.95709C15.875 3.99376 16.5357 4.28342 17.0401 4.73894L17.7046 4.07447Z" fill="black" />
                                                            <path d="M14.8437 3.95709V3.01892C13.8906 3.05547 12.9956 3.42594 12.2954 4.07447L12.9599 4.73895C13.4643 4.28344 14.125 3.99378 14.8437 3.95709Z" fill="black" />
                                                            <path d="M11.9571 7.15625H11.0189C11.0555 8.10938 11.4259 9.00436 12.0745 9.70458L12.739 9.04009C12.2834 8.53572 11.9938 7.875 11.9571 7.15625Z" fill="black" />
                                                            <path d="M17.2611 9.04009L17.9256 9.70458C18.5741 9.00436 18.9446 8.10938 18.9811 7.15625H18.043C18.0063 7.875 17.7166 8.53572 17.2611 9.04009Z" fill="black" />
                                                            <path d="M15.1562 10.043V10.9811C16.1094 10.9446 17.0044 10.5741 17.7046 9.92559L17.0401 9.26111C16.5357 9.71664 15.875 10.0063 15.1562 10.043Z" fill="black" />
                                                            <path d="M17.7344 7C17.7344 5.49227 16.5077 4.26562 15 4.26562C13.4923 4.26562 12.2656 5.49227 12.2656 7C12.2656 8.50773 13.4923 9.73438 15 9.73438C16.5077 9.73438 17.7344 8.50773 17.7344 7ZM15.5469 8.48438H14.3415C14.2925 8.48438 14.2463 8.46136 14.2168 8.4222C14.1872 8.38306 14.1778 8.33233 14.1913 8.28519L14.495 7.22209L14.1663 7.27688C14.1576 7.27833 14.149 7.27903 14.1404 7.27903C14.0654 7.27903 13.9992 7.22486 13.9865 7.14844C13.9723 7.06333 14.0298 6.98281 14.1149 6.96864L14.5901 6.88945L14.9949 5.47269C15.0186 5.3897 15.1051 5.34166 15.188 5.36538C15.271 5.38908 15.319 5.47556 15.2953 5.55853L14.9313 6.83256L15.3203 6.76773C15.4053 6.75352 15.4859 6.81103 15.5001 6.89617C15.5143 6.9813 15.4568 7.0618 15.3717 7.07598L14.8363 7.16522L14.5487 8.17188H15.5469C15.6332 8.17188 15.7031 8.24184 15.7031 8.32812C15.7031 8.41441 15.6332 8.48438 15.5469 8.48438Z" fill="black" />
                                                            <circle cx="15" cy="7" r="2.73438" fill="black" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_1178_8828">
                                                                <rect width="8" height="8" fill="white" transform="translate(11 3)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className='dark'>
                                                        <path d="M15.1237 0.687724C14.6664 0.687711 14.6631 1.36075 15.1263 1.37588C17.7975 1.37588 20.0775 3.29242 20.5414 5.92316C21.0052 8.55391 19.5182 11.1344 17.0081 12.0481C14.5452 12.9445 11.8002 11.975 10.4362 9.75333L11.0405 9.91512C11.4884 10.0423 11.67 9.36411 11.2184 9.25047L9.80985 8.87249C9.59065 8.82234 9.43167 8.9598 9.38892 9.1162L9.01095 10.5241C8.88377 10.9721 9.56196 11.1536 9.67558 10.702L9.83872 10.0917C11.3651 12.607 14.4652 13.7025 17.2417 12.6919C20.061 11.6657 21.7391 8.75981 21.2181 5.805C20.6871 2.7388 17.9762 0.662711 15.1237 0.687724ZM8.22547 13.0631C7.69228 13.0631 6.74297 13.4226 5.9892 13.7834C5.84171 13.854 5.75517 13.9022 5.62735 13.9661C5.76077 14.2104 5.84352 14.4863 5.84352 14.7831V19.4169C6.28127 19.3406 6.72532 19.2706 7.21979 19.2517C7.64472 19.2517 8.439 19.854 8.95522 20.2185C9.69084 20.7378 10.4832 21.3122 11.3425 21.3122C11.6012 21.3122 11.861 21.2271 12.2032 21.0994C14.0837 20.3963 18.0502 18.1523 20.3608 16.8006C20.9454 16.4772 21.1375 15.7285 20.8072 15.1564C20.4903 14.6075 19.847 14.4738 19.2215 14.6871L14.9054 16.1389C14.9363 16.4198 14.886 16.7109 14.7349 16.9728C14.357 17.6273 13.5116 17.8962 12.8571 17.5183L10.7712 16.3152C10.34 16.0951 10.7117 15.4558 11.1163 15.7217L13.1995 16.9241C13.5345 17.1175 13.9453 17.008 14.1387 16.673C14.3321 16.338 14.222 15.9279 13.8869 15.7345C12.353 14.8488 9.62983 13.0631 8.22547 13.0631ZM1.72071 13.7506C1.14208 13.7506 0.6875 14.2184 0.6875 14.7838V19.5942C0.6875 20.1572 1.14239 20.6267 1.71399 20.6267H4.12621C4.75385 20.6267 5.15606 20.0934 5.15606 19.5935V14.7831C5.15606 14.2055 4.6888 13.7506 4.12688 13.7506H1.72071ZM2.4075 18.5636C2.59733 18.5636 2.75123 18.7175 2.75123 18.9073C2.75123 19.0972 2.59733 19.2511 2.4075 19.2511C2.21766 19.2511 2.06376 19.0972 2.06376 18.9073C2.06376 18.7175 2.21766 18.5636 2.4075 18.5636Z" fill="white" />
                                                        <g clip-path="url(#clip0_1032_773)">
                                                            <path d="M12.7396 4.95988L12.0751 4.29541C11.4265 4.99561 11.0561 5.8906 11.0195 6.84372H11.9577C11.9944 6.12497 12.284 5.46425 12.7396 4.95988Z" fill="white" />
                                                            <path d="M12.2969 9.9257C12.9971 10.5742 13.8921 10.9447 14.8452 10.9812V10.0431C14.1265 10.0064 13.4657 9.71675 12.9614 9.26123L12.2969 9.9257Z" fill="white" />
                                                            <path d="M18.0436 6.84374H18.9818C18.9452 5.89061 18.5747 4.99563 17.9262 4.29541L17.2617 4.95989C17.7172 5.46427 18.0069 6.12499 18.0436 6.84374Z" fill="white" />
                                                            <path d="M17.7046 4.07459C17.0044 3.42606 16.1094 3.05559 15.1562 3.01904V3.95721C15.875 3.99389 16.5357 4.28354 17.0401 4.73906L17.7046 4.07459Z" fill="white" />
                                                            <path d="M14.8452 3.95721V3.01904C13.8921 3.05559 12.9971 3.42606 12.2969 4.07459L12.9614 4.73907C13.4657 4.28356 14.1264 3.9939 14.8452 3.95721Z" fill="white" />
                                                            <path d="M11.9577 7.15625H11.0195C11.0561 8.10938 11.4265 9.00436 12.0751 9.70458L12.7396 9.04009C12.284 8.53572 11.9944 7.875 11.9577 7.15625Z" fill="white" />
                                                            <path d="M17.2617 9.04009L17.9262 9.70458C18.5747 9.00436 18.9452 8.10938 18.9818 7.15625H18.0436C18.0069 7.875 17.7172 8.53572 17.2617 9.04009Z" fill="white" />
                                                            <path d="M15.1562 10.0431V10.9813C16.1094 10.9447 17.0044 10.5742 17.7046 9.92572L17.0401 9.26123C16.5357 9.71676 15.875 10.0064 15.1562 10.0431Z" fill="white" />
                                                            <path d="M17.7344 7C17.7344 5.49227 16.5077 4.26562 15 4.26562C13.4923 4.26562 12.2656 5.49227 12.2656 7C12.2656 8.50773 13.4923 9.73438 15 9.73438C16.5077 9.73438 17.7344 8.50773 17.7344 7ZM15.5469 8.48438H14.3415C14.2925 8.48438 14.2463 8.46136 14.2168 8.4222C14.1872 8.38306 14.1778 8.33233 14.1913 8.28519L14.495 7.22209L14.1663 7.27688C14.1576 7.27833 14.149 7.27903 14.1404 7.27903C14.0654 7.27903 13.9992 7.22486 13.9865 7.14844C13.9723 7.06333 14.0298 6.98281 14.1149 6.96864L14.5901 6.88945L14.9949 5.47269C15.0186 5.3897 15.1051 5.34166 15.188 5.36538C15.271 5.38908 15.319 5.47556 15.2953 5.55853L14.9313 6.83256L15.3203 6.76773C15.4053 6.75352 15.4859 6.81103 15.5001 6.89617C15.5143 6.9813 15.4568 7.0618 15.3717 7.07598L14.8363 7.16522L14.5487 8.17188H15.5469C15.6332 8.17188 15.7031 8.24184 15.7031 8.32812C15.7031 8.41441 15.6332 8.48438 15.5469 8.48438Z" fill="white" />
                                                            <circle cx="15" cy="7" r="2.73438" fill="white" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_1032_773">
                                                                <rect width="8" height="8" fill="white" transform="translate(11 3)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    {t('Sidebar-Claim')}
                                                </Nav.Link>
                                            </Nav.Item>
                                        </div>
                                        <Nav.Item>
                                            <div class="accordion forneedhelp" id="accordionExample">
                                                <div class="accordion-item">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseneed" aria-expanded="true" aria-controls="collapseneed">
                                                        <svg className='iconns' xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                            <g clip-path="url(#clip0_4036_10679)">
                                                                <path d="M11 0C4.92031 0 0 4.91975 0 11C0 17.0796 4.91975 22 11 22C17.0797 22 22 17.0802 22 11C22 4.92031 17.0802 0 11 0ZM10.6745 16.1485C10.0519 16.1485 9.57408 15.6272 9.57408 15.0335C9.57408 14.4254 10.0664 13.9187 10.6745 13.9187C11.2827 13.9187 11.7894 14.4254 11.7894 15.0336C11.7894 15.6272 11.2971 16.1485 10.6745 16.1485ZM12.3251 10.516C11.5288 11.1386 11.5142 11.573 11.5142 12.3259C11.5142 12.6011 11.3694 12.9196 10.66 12.9196C10.0663 12.9196 9.86365 12.7024 9.86365 11.9495C9.86365 10.7042 10.4139 10.1106 10.8338 9.74862C11.3116 9.34317 12.1224 8.89436 12.1224 8.1125C12.1224 7.4464 11.5433 7.12787 10.8193 7.12787C9.34239 7.12787 9.66096 8.24282 8.87902 8.24282C8.48809 8.24282 8.01028 7.98213 8.01028 7.41748C8.01028 6.63562 8.90798 5.47723 10.8627 5.47723C12.716 5.47723 13.9468 6.5053 13.9468 7.86633C13.9468 9.22737 12.716 10.212 12.3251 10.516Z" fill="white" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_4036_10679">
                                                                    <rect width="22" height="22" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>

                                                        {t('needhelp')}
                                                    </button>
                                                    <div id="collapseneed" class="accordion-collapse collapse  forsetting" aria-labelledby="headingneed" data-bs-parent="#accordionExample">
                                                        <div class="accordion-body">
                                                            <a href="https://medium.com/dop-org/how-to-use-the-dop-testnet-864cdaf423f5" target='_blank'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='iconns'>
                                                                <path d="M6.76884 5.15625C10.5072 5.15625 13.5374 8.20768 13.5374 11.9715C13.5374 15.7354 10.5069 18.7866 6.76884 18.7866C3.03073 18.7866 0 15.7354 0 11.9715C0 8.20768 3.0305 5.15625 6.76884 5.15625ZM17.5782 5.55547C19.4474 5.55547 20.9626 8.42775 20.9626 11.9715H20.9628C20.9628 15.5144 19.4476 18.3876 17.5784 18.3876C15.7092 18.3876 14.194 15.5144 14.194 11.9715C14.194 8.42867 15.709 5.55547 17.5782 5.55547ZM22.8097 6.22375C23.4669 6.22375 24 8.79708 24 11.9715C24 15.1451 23.4672 17.7193 22.8097 17.7193C22.1522 17.7193 21.6196 15.1458 21.6196 11.9715C21.6196 8.79731 22.1524 6.22375 22.8097 6.22375Z" fill="white" />
                                                            </svg> {t('wela1')}</a>
                                                            <a href="https://www.youtube.com/watch?v=6hnXYIWye4w" target='_blank'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <rect width="20" height="20" rx="10" fill="white" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8908 5.89173C15.4262 6.03588 15.8484 6.45804 15.9925 6.99346C16.2602 7.97162 16.2499 10.0103 16.2499 10.0103C16.2499 10.0103 16.2499 12.0387 15.9925 13.0169C15.8484 13.5523 15.4262 13.9745 14.8908 14.1186C13.9126 14.376 9.99997 14.376 9.99997 14.376C9.99997 14.376 6.0976 14.376 5.10914 14.1083C4.57372 13.9642 4.15156 13.542 4.00741 13.0066C3.75 12.0387 3.75 10 3.75 10C3.75 10 3.75 7.97162 4.00741 6.99346C4.15156 6.45804 4.58402 6.02559 5.10914 5.88144C6.0873 5.62402 9.99997 5.62402 9.99997 5.62402C9.99997 5.62402 13.9126 5.62402 14.8908 5.89173ZM12.0078 10L8.75407 11.874V8.12608L12.0078 10Z" fill="black" />
                                                            </svg> {t('wela2')}</a>
                                                            <a href="https://t.me/Dop_org" target='_blank'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='iconns'>
                                                                <path d="M3.80615 10.749C8.90641 8.20876 12.3074 6.5341 14.0091 5.72499C18.8677 3.41482 19.8773 3.01352 20.5354 3.00013C20.6801 2.99736 21.0037 3.03836 21.2133 3.23279C21.3903 3.39697 21.439 3.61876 21.4623 3.77442C21.4856 3.93008 21.5146 4.28467 21.4916 4.56174C21.2283 7.72419 20.089 15.3986 19.5094 18.9406C19.2642 20.4394 18.7813 20.9419 18.3138 20.9911C17.2978 21.098 16.5263 20.2235 15.5422 19.4861C14.0024 18.3323 13.1325 17.614 11.6378 16.488C9.9105 15.1868 11.0303 14.4716 12.0147 13.3028C12.2723 12.9969 16.7488 8.34235 16.8354 7.92011C16.8463 7.8673 16.8563 7.67045 16.754 7.56651C16.6517 7.46257 16.5007 7.49812 16.3918 7.52638C16.2373 7.56645 13.7775 9.42509 9.01212 13.1023C8.31389 13.6504 7.68145 13.9174 7.11481 13.9035C6.49014 13.888 5.28851 13.4997 4.39523 13.1678C3.29958 12.7606 2.42878 12.5454 2.5046 11.8539C2.5441 11.4938 2.97795 11.1255 3.80615 10.749Z" fill="white" />
                                                            </svg> {t('wela3')}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col xl={9} lg={12} className="pe-0 padd-sm">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="deposithistory">
                                            <Deposithistory itemsAssets={itemsAssets} userBalance={userBalance} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="transferhistory">
                                            <Transferhistory itemsAssets={itemsAssets} userBalance={userBalance} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="withdrawhistory">
                                            <Withdrawhistory itemsAssets={itemsAssets} userBalance={userBalance} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="privateassets">
                                            <PrivateAssets itemsAssets={itemsAssets} userBalance={userBalance} setInternalAount={setInternalAount} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="createaccount">
                                            <CreateAccount />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="importaccount">
                                            <ImportAccount />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="deposit">
                                            <Transaction itemsAssets={itemsAssets} userBalance={userBalance} internalAount={internalAount} tab={'deposit'} stateusCheck={stateusCheck} setStateusCheck={setStateusCheck} setDefaultt={setDefaultt} defalttt={defalttt} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="transfer">
                                            <Transaction itemsAssets={itemsAssets} userBalance={userBalance} internalAount={internalAount} tab={'transfer'} stateusCheck={stateusCheck} setStateusCheck={setStateusCheck} setDefaultt={setDefaultt} defalttt={defalttt} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="withdraw">
                                            <Transaction itemsAssets={itemsAssets} userBalance={userBalance} internalAount={internalAount} tab={'withdraw'} stateusCheck={stateusCheck} setStateusCheck={setStateusCheck} setDefaultt={setDefaultt} defalttt={defalttt} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="general">
                                            <GeneralSetting />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="claim">
                                            <Claim setSelectedItemAsset={setSelectedItemAsset} selectedItemAsset={selectedItemAsset} itemsAssets={itemsAssets} userBalance={userBalance} />
                                        </Tab.Pane>
                                        {/* {cliamCheck === 'deposit' ?
                                        <>
                                             <Tab.Pane eventKey="deposit">
                                             <Transaction itemsAssets={itemsAssets} userBalance={userBalance} internalAount={internalAount} tab={'deposit'} stateusCheck={stateusCheck} setStateusCheck={setStateusCheck} />
                                         </Tab.Pane>
                                         <Tab.Pane eventKey="transfer">
                                             <Transaction itemsAssets={itemsAssets} userBalance={userBalance} internalAount={internalAount} tab={'transfer'} stateusCheck={stateusCheck} setStateusCheck={setStateusCheck} />
                                         </Tab.Pane>
                                         <Tab.Pane eventKey="withdraw">
                                             <Transaction itemsAssets={itemsAssets} userBalance={userBalance} internalAount={internalAount} tab={'withdraw'} stateusCheck={stateusCheck} setStateusCheck={setStateusCheck} />
                                         </Tab.Pane>
                                            <Tab.Pane eventKey="deposit">
                                                <Transaction itemsAssets={itemsAssets} userBalance={userBalance} internalAount={internalAount} tab={'deposit'} stateusCheck={stateusCheck} setStateusCheck={setStateusCheck} />
                                            </Tab.Pane>
                                            </>
                                            : */}
                                        <Tab.Pane eventKey="earn">
                                            <Earnrewards internalAount={internalAount} setStateusCheck={setStateusCheck} stateusCheck={stateusCheck} defalttt={defalttt} setDefaultt={setDefaultt} refralCode={refralCode} />
                                        </Tab.Pane>
                                        {/* } */}

                                    </Tab.Content>

                                </Col>
                            </Row>
                        </Tab.Container>

                    }

                </div>
            </section>


            <Modal className={languageselected == "ar" ? "important-note direction-for-arabic" : "important-note"} show={show2} onHide={handleClose2} backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>
                        {t('route-dashboard-T1')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="importantparaimg">
                        <img src="\Assets\tpldashboard\earnrewards\lightimg.svg" alt="claimimg" className="claimimg dark" />
                        <img src="\Assets\tpldashboard\earnrewards\darkimg.svg" alt="claimimg" className="claimimg white" />
                        {/* <p className="claimpara">Explore the DOP Testnet and earn rewards.</p> */}
                    </div>
                    <button id="speicalAZ226"  className='continuebutton' onClick={() => {
                        localStorage.setItem("model2", true)
                        handleClose2();
                    }}>{t('route-dashboard-B1')}</button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Tpldashboard
