import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { mintAllContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";
// import { mintAllContract } from "lodash";

const MintAll = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.mintAll;
  const contract = mintAllContract(tokenAddress, web3);


  const mintAll = useCallback(
    async () => {
      let gasPrice = await web3.eth.getGasPrice()
      gasPrice = parseInt(gasPrice) + 4000000000
      let gas=''
      let details=''

        // console.log(tokenAddress,account,'noor');
        // console.log(addresss,indux,nd,'addresss,indux,amount');
      try {
        gas = await contract.methods.mintTokens(account).estimateGas({
            from:account
          });
         details = await contract.methods.mintTokens(account).send({
          from: account,
          gas,
          gasPrice
        });
        // console.log('sdsdss' ,  buy)
        return details;
      } catch (error) {
        throw error;
      }
    },
    [ account,web3]
  );
  return { mintAll:mintAll };
};
export default MintAll;