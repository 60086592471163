import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { ptlContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const TransferTpl = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.tpl;
  const contract = ptlContract(tokenAddress, web3);



  const transferTpl = useCallback(
    async (amount,selectedItem,now,num,v,r,s,re,selectedIndex) => {
      let gasPrice = await web3.eth.getGasPrice()
      gasPrice = parseInt(gasPrice) + 4000000000
      let nd
      if (selectedIndex==21||selectedIndex==22){
      nd = web3.utils.toWei(amount.toString(), "mwei");
      }else{
        nd = web3.utils.toWei(amount.toString(), "ether");
      }
        // console.log(a,b,c,d,e,r,'a,b,c,d,e');
    //   amount = amount.toString()
    // console.log(interAccount,random.toString(),time.toString(),v,r,s,'interAccount,random.toString(),time.toString(),v,r,s');
      try {
        // console.log(nd,selectedItem,num.toString(),now.toString(),v,r,s,'amount,selectedItem,now,num,v,r,s');

        const gas = await contract.methods.transferEncrypted(selectedItem,re,nd,num.toString(),now.toString(),selectedIndex,v,r,s).estimateGas({
            from:account });
        const details = await contract.methods.transferEncrypted(selectedItem,re,nd,num.toString(),now.toString(),selectedIndex,v,r,s).send({
          from: account,
          gas,
          gasPrice
        });
        return details;
        // console.log('sdsdss' ,  buy)
        // return details;
      } catch (error) {
        throw error;
      }
    },
    [contract, account,web3]
  );
  return { transferTpl: transferTpl };
};
export default TransferTpl;