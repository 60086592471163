import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { usdtContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const ApproveTpl = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  // const tokenAddress = Environment.busd;
  const tokenAddress1 = Environment.tpl;



  const approveTpl = useCallback(
    async (amount,connectedAccount,selectedAccount,slectedIndex) => {
      let gasPrice = await web3.eth.getGasPrice()
      gasPrice = parseInt(gasPrice) + 3000000000
      try {
        // console.log(selectedAccount,slectedIndex,tokenAddress1);
        const contract = usdtContract(selectedAccount, web3);
        let nd=''
        if(slectedIndex==21||slectedIndex==22){
           nd = web3.utils.toWei(amount.toString(), "mwei");

        }else{
           nd = web3.utils.toWei(amount.toString(), "ether");
        }
        // let gasPrice = await web3.eth.getGasPrice();
        // gasPrice = parseInt(gasPrice) + 5000000000;
        // console.log(nd,slectedIndex);

        const gas = await contract.methods
          .approve(tokenAddress1, nd)
          .estimateGas({ from: account });
        // let gasPrice = await web3.eth.getGasPrice();
        const details = await contract.methods
          .approve(tokenAddress1, nd)
          .send({
            from: account,
            gas: gas,
            gasPrice,
          });
        return details;
        // console.log('sdsdss' ,  buy)
        // return details;
      } catch (error) {
        throw error;
      }
    },
    [account,web3]
  );
  return {approveTpl: approveTpl };
};
export default ApproveTpl;