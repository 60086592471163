import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { ptlContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const UserBalanceTpl = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.tpl;
  const contract = ptlContract(tokenAddress, web3);


  const userBalanceTpl = useCallback(
    async (interAccount,random,v,r,s,time,indux) => {
    
      // console.log(interAccount,random,v,r,s,time,indux,'interAccount,random,v,r,s,time,indux');
      // console.log(interAccount,random,v,r,s,time,'interAccount,random,v,r,s,time');
        // console.log(a,b,c,d,e,r,'a,b,c,d,e');
    //   amount = amount.toString()
    // console.log(interAccount,random.toString(),time.toString(),v,r,s,'interAccount,random.toString(),time.toString(),v,r,s');
      try {
        // console.log(account,interAccount,random,time,v,r,s,);
        const buy = await contract.methods.balanceOfEncrypted(account.toLowerCase(),interAccount.toLowerCase(),random.toString(),time.toString(),indux,v,r,s).call({from:account})
        // console.log('interAccount,random,v,r,s,time,indux' ,  buy)
        return buy;
      } catch (error) {
        // console.log('interAccount,random,v,r,s,time,indux',interAccount,random,v,r,s,time,indux);
        throw error;
      }
    },
    [contract, account,web3]
  );
  return { userBalanceTpl: userBalanceTpl };
};
export default UserBalanceTpl;