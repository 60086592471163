import React from 'react';
import './footer.scss';
import { useTranslation } from 'react-i18next'
const Footer = () => {
    const { t } = useTranslation()
    var languageselected = localStorage.getItem('codelanguage')
    return (
        <>
            <section className={languageselected == "ar" ? "main-footer direction-for-arabic" : "main-footer"}>
                <div class="theme-container">
                    <div className="innerfooter">
                        <div className="middle">
                            <a href="\termofuse.pdf" target='_blank'>{t('Footer-T1')}</a>
                            <a href="https://dop.org/" target='_blank'>dop.org</a>
                            <a href="https://dop.org/tldr" target='_blank'>TL;DR</a>
                        </div>
                        <div className="leftfoot">
                            <p className="footline">© {t('Footer-T2')}</p>
                        </div>

                        <div className="rightfoot">

                            {/* Dark theme icon  */}

                            <a href="https://bit.ly/45R5kei" target='_blank'> <img src="\Assets\footer-assets\twiitter.svg" alt="socialicon" className="socialicon white" /> </a>
                            <a href="https://bit.ly/45PQmW8" target='_blank'> <img src="\Assets\footer-assets\telegramnew.svg" alt="socialicon" className="socialicon white" /></a>
                            <a href="https://medium.com/dop-org" target='_blank'> <img src="\Assets\footer-assets\mediumnew.svg" alt="socialicon" className="socialicon white " /></a>

                            {/* Light theme icon  */}
                            <a href="https://bit.ly/45R5kei" target='_blank'><img src="\Assets\social-icons\twitter.svg" alt="img" className='img-fluid dark' /></a>
                            <a href="https://bit.ly/45PQmW8" target='_blank'><img src="\Assets\social-icons\telegram.svg" alt="img" className='img-fluid dark' /></a>
                            <a href="https://medium.com/dop-org" target='_blank'><img src="\Assets\medium.svg" alt="img" className='img-fluid dark' /></a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer;
